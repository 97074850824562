import React, { useEffect } from "react";
import {useSelector} from "react-redux";
import "./App.css";
import {selectToken, selectFirstSetupDone} from "../store/selectors";
import Tracker from "./tracker";
import Start from "./start";
import Login from "./login";
import { useLocation, useNavigate } from "react-router-dom";

function App(): JSX.Element {
  const token = useSelector(selectToken);
  const firstSetupDone = useSelector(selectFirstSetupDone);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === "/" && token && firstSetupDone) {
      navigate("/tracker/home");
    }
  }, [location, token, navigate, firstSetupDone]);
  if (!firstSetupDone && token) {
    return <Start />;
  }
  if (!token) {
    return <Login />;
  }
  return <Tracker />;
}

export default App;
