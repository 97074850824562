import { environment as env_dev } from "./environment.dev";
import { Environment } from "./environment.interface";
import { environment as env_prod } from "./environment.prod";

function getEnv(): Environment {
  if (!process) {
    return new env_prod;
  }
  if (process.env.NODE_ENV === "production") {
    return new env_prod;
  }
  if (process.env.NODE_ENV === "development") {
    return new env_dev;
  }
  return new env_prod;
}

export const environment = getEnv();
