import React, {useCallback} from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Badge, { BadgeProps } from "@mui/material/Badge";
import useTheme from "@mui/material/styles/useTheme";
import Links from "./links";
import Logo from "./logo";
import { useSelector, useDispatch } from "react-redux";
import { selectSubscriptionActive, selectSubscriptionStatus, selectIsTrialAvailable } from "../../store/selectors";
import { setShowStartTrialModal } from "../../store/slices/login.slice";
import useWindowDimensions from "./useWindowDimensions";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ReactComponent as SettingsIcon } from "./icons/settings-icon.svg";
import { ReactComponent as PremiumIcon } from "./icons/premium-icon.svg";
import ReduxTrialPopup from "./redux-trial-popup";
import {useTranslation} from "react-i18next";
import { keyframes } from "@mui/system";

interface DrawerContentProps {
  open: boolean;
}

function DrawerContent(props: DrawerContentProps): JSX.Element {
  const { open } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  // const subscriptionActive = useSelector(selectSubscriptionActive);
  const subscriptionStatus = useSelector(selectSubscriptionStatus);
  const isTrialAvailabale = useSelector(selectIsTrialAvailable);
  
  const dimensions = useWindowDimensions();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const height = dimensions.height;
  const premiumTextNormal = subscriptionStatus === "active" || subscriptionStatus === "trialing" ? t("active") : subscriptionStatus === "signup_trial" ? t("trial") : t("expired");
  // const premiumText = subscriptionActive ? premiumTextNormal : t("expired");
  const premiumColorNormal = subscriptionStatus === "active" || subscriptionStatus === "trialing" ? "#88CBAA" : subscriptionStatus === "signup_trial" ? "#FFBD2E" : "#FC7D69";
  // const premiumColor = subscriptionActive ? premiumColorNormal : "#FC7D69";
  const tryPremium = isTrialAvailabale && subscriptionStatus === "free_tier";

  const TrialPopupComponent = ({children}: any): JSX.Element => {
    if (open) {
      return <ReduxTrialPopup>{children}</ReduxTrialPopup>;
    } else {
      return <>{children}</>;
    }
  };

  const clickHandler = useCallback(() => {
    if(tryPremium){
      dispatch(setShowStartTrialModal(true));
    }else{
      navigate("/tracker/settings");
    }
  }, [tryPremium]);

  const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  60% {
    transform: scale(1.3);
    opacity: 0.4;
  }
  100% {
    transform: scale(1.4);
    opacity: 0;
  }
  `;

  return <Grid container direction="row" alignContent="space-between" alignItems="space-between" justifyItems="space-between" justifyContent="space-between" height={height}>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Grid container direction="row" alignContent="center" alignItems="center" justifyItems="center" justifyContent="center">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Logo opened={open}/>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <List sx={{ marginTop: open ? 8 : "20px" }}>
            <Links opened={open}/>
          </List>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Grid container direction="row" alignContent="center" alignItems="center" justifyItems="center" justifyContent="center" rowSpacing={1}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{textAlign: "center"}}>
          <TrialPopupComponent>
            <Badge 
              badgeContent={tryPremium ? 1 : 0}
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "#F00933",
                  color: "#fff",
                  fontWeight: 600,
                  fontSize: "14px",
                  "&:before": {
                    content: "''",
                    display: "block",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: "50%",
                    border: "3px double #fff",
                    animation: `${pulse} 1s ease infinite`
                  }
                  
                }
              }}>
              <Button
                variant="contained"
                onClick={clickHandler}
                sx={{
                  fontSize: "16px",
                  background: tryPremium ? "#88CBAA" : premiumColorNormal,
                  width: open ? "200px" : "40px",
                  height: "40px",
                  minWidth: "40px",
                  borderRadius: "9px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  padding: 0,
                  "&:hover": {
                    background: tryPremium ? "#88CBAA" : premiumColorNormal,
                  },
                }}
              >
                { !open ? <PremiumIcon /> : tryPremium ? t("free_premium") : `${t("premium")}: ${premiumTextNormal}`}
              </Button>
            </Badge>
            {/* {open && <Button
              variant="contained"
              onClick={clickHandler}
              sx={{
                fontSize: "16px",
                background: tryPremium ? "#88CBAA" : premiumColorNormal,
                height: "40px",
                minWidth: "40px",
                width: "200px",
                borderRadius: "9px",
                fontWeight: 700,
                lineHeight: "24px",
                "&:hover": {
                  background: tryPremium ? "#88CBAA" : premiumColorNormal,
                },
              }}
            >{tryPremium ? t("free_premium") : `${t("premium")}: ${premiumTextNormal}`}</Button>} */}
            {/* {!open && <Button
              color="primary"
              variant="contained"
              onClick={clickHandler}
              sx={{
                fontSize: "16px",
                background: tryPremium ? "#88CBAA" : premiumColorNormal,
                width: "40px",
                height: "40px",
                minWidth: "40px",
                borderRadius: "9px",
                padding: 0,
                "&:hover": {
                  background: tryPremium ? "#88CBAA" : premiumColorNormal,
                },
              }}
            >
              <PremiumIcon />
            </Button>} */}
          </TrialPopupComponent>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{
          marginTop: 2,
          backgroundColor: theme.palette.secondary.dark,
          borderRadius: "0 0 15px 0",
          paddingTop: "2px !important"
        }}>
          <List sx={{paddingTop: 0, paddingBottom: "2px"}}>
            <ListItem sx={{
              color: theme.palette.text.secondary,
              textAlign: "center",
              paddingLeft: 0,
              justifyItems: open ? "center" : "flex-start",
              justifyContent: open ? "center" : "flex-start",
            }}>
              <ListItemButton component={Link} to="/tracker/settings" selected={location.pathname === "/tracker/settings"} sx={{
                "& .MuiListItemIcon-root": {
                  color: theme.palette.text.secondary,
                  fill: "rgba(249,250,252, 0.3)",
                  fillOpacity: 0.7
                },
                "& .MuiListItemText-primary": {
                  color: theme.palette.text.secondary,
                  textShadow: "0px 1px 4px rgba(252, 125, 105, 0.45)",
                  fontWeight: 600,
                  marginTop: "5px"
                },
                "&:hover": {
                  backgroundColor: "transparent",
                  "& .MuiListItemIcon-root": {
                    color: theme.palette.text.secondary,
                    fill: "#FC7D69",
                    fillOpacity: 1
                  },
                },
                "&.Mui-selected": {
                  backgroundColor: "transparent",
                  "& .MuiListItemIcon-root": {
                    color: theme.palette.text.secondary,
                    fill: "#5173F0",
                    fillOpacity: 1
                  },
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "transparent",
                  "& .MuiListItemIcon-root": {
                    color: theme.palette.text.secondary,
                    fill: "#5173F0",
                    fillOpacity: 1
                  },
                },
              }}>
                <ListItemIcon sx={{
                  marginLeft: "8px",
                }}>
                  <SettingsIcon fill="inherit"/>
                </ListItemIcon>
                {open && <ListItemText primary={t("settings_page_title")} />}
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Grid>
  </Grid>;
}

export default React.memo(DrawerContent);
