import React from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import useTheme from "@mui/material/styles/useTheme";
import {Typography, Box, IconButton} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface TrialPopupProps {
  open: boolean;
  onExtend: () => void;
  children?: React.ReactNode;
  trialRemaining: number;
}

const Text = ({children}: any): JSX.Element => <Typography sx={{
  color: "#000000",
  fontSize: "9px",
  lineHeight: "12px",
  fontWeight: 400,
  fontFamily: "Poppins",
  whiteSpace: "pre-wrap",
  textAlign: "start",
  mb: "8px",
  padding: "0 18px",
}}>{children}</Typography>;

export default function TrialPopup(props: TrialPopupProps): JSX.Element {
  const { open, onExtend, children, trialRemaining } = props;
  const [show, setShow] = React.useState(open);
  const theme = useTheme();

  return <Tooltip
    disableTouchListener
    disableFocusListener
    disableHoverListener
    componentsProps={{
      tooltip: {
        sx: {
          width: "185px",
          // height: "85px",
          backgroundColor: "#ffffff",
          color: "#000000",
          paddingBottom: "12px",
          borderRadius: "8px"
        },
      },
      arrow: {
        sx: {
          ":before": {
            backgroundColor: "#ffffff",
            color: "#000000",
          }
        },
      },
    }}
    title={
      <Box>
        <Box sx={{textAlign: "end !important", mb: "2px"}}>
          <IconButton sx={{p: 0}} onClick={() => setShow(false)}>
            <CloseRoundedIcon sx={{p: 0, fontSize: "16px"}}/>
          </IconButton>
        </Box>
        <Box>
          <Text>Your Premium Trial is expiring in<Box component="span" sx={{
            color: theme.palette.primary.light,
            fontWeight: 700,
          }}> {trialRemaining} days.</Box> Subscribe now to retain access to premium features.</Text>
          {/* <Text>Subscribe now to retain access to premium features.</Text> */}
          {/* <Text><div style={{
            display: "inline-block",
            fontWeight: 600,
          }}>7 days</div> by clicking the button below</Text> */}
        </Box>
        <Box>
          <Button
            sx={{
              width: "max-content",
              height: "100%",
              backgroundColor: "#88CBAA",
              color: "#ffffff",
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "18px",
              fontFamily: "Poppins",
              textTransform: "capitalize",
              borderRadius: "6px",
              ":hover": {
                backgroundColor: "#88CBAA",
                color: "#ffffff",
              },
            }}
            onClick={onExtend}
          >
            Subscribe Now 
          </Button>
        </Box>
      </Box>
    }
    placement="top"
    open={show}
    arrow
    PopperProps={{
      disablePortal: true,
    }}
  >
    <div>
      {children}
    </div>
  </Tooltip>;
}
