import React  from "react";
import { Modal, Box, Typography, IconButton, Button } from "@mui/material";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import { selectShowStartTrialModal, selectStartTrialLoading, selectIsTrialAvailable, selectSubscriptionActiveUntil, selectSubscriptionStatus, selectNickname } from "../../store/selectors";
import { setShowStartTrialModal } from "../../store/slices/login.slice";
import { startTrial, endTrial } from "../../store/reducers/login.reducer";
import moment from "moment";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { createStripeSession } from "../../store/reducers/tracker.reducer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function EndTrial(): JSX.Element {
  const dispatch = useDispatch();
  const loading = useSelector(selectStartTrialLoading);
  const isTrialAvailabale = useSelector(selectIsTrialAvailable);
  const validUntil = useSelector(selectSubscriptionActiveUntil);
  const validUntilDate = moment.utc(validUntil).format("MMMM Do");
  const subscriptionStatus = useSelector(selectSubscriptionStatus);
  const nickname = useSelector(selectNickname);
  const navigate = useNavigate();
  const { t } = useTranslation(); 

  const show = subscriptionStatus === "signup_trial" && validUntil < Date.now();

  const endTrialHandler = () => {
    dispatch(endTrial());
    navigate("/tracker/home");
  };

  const handleSubscribe = () => {
    dispatch(createStripeSession("premium_yearly"));
  };

  return (
    <Modal
      open={show}
      //onClose={endTrialHandler}
      // hideBackdrop
    >
      <Box sx={style}>
        {/* <Box sx={{
          position: "absolute",
          top: 0,
          right: 0
        }}>
          <IconButton onClick={endTrialHandler}>
            <CloseRoundedIcon/>
          </IconButton>
        </Box> */}

        <Typography component="h6" sx={{color: "#3A48A0", fontSize: "20px", fontWeight: 700, textAlign: "center", mb: 2}}>
          {t("trial_expired")}
        </Typography>
        <Box sx={{minHeight: "66px", maxWidth: "400px", m: "auto"}}>  
          <Typography sx={{ fontSize: "12px", color: "#000", textAlign: "center" }}>
            {t("congratulations")} <Box component="span" sx={{fontWeight: 700}}>{nickname}</Box> {t("discount_desc")} 
          </Typography>
        </Box>
        <Box sx={{textAlign: "center", mt: 1.3}}>
          <LoadingButton
            onClick={handleSubscribe}
            loading={loading}
            sx={{
              color: "#fff",
              fontSize: "16px",
              fontWeight: 600,
              backgroundColor: "#88CBAA",
              borderRadius: "8px",
              padding: "5px 22px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#88CBAA",
              },
              "&.MuiLoadingButton-loading": {
                backgroundColor: "#88cbaa54",
                "& .MuiLoadingButton-loadingIndicator": {
                  color: "#88CBAA"
                }
              }
            }}>
            {t("claim_discount")}
          </LoadingButton>
          <Box sx={{mt: "7px"}}>
            <Button onClick={endTrialHandler} sx={{
              color: "#000",
              fontSize: "12px",
              lineHeight: 1,
              fontWeight: 600,
              textTransform: "initial",
              textDecoration: "underline",
              textUnderlineOffset: "3px",
              "&:hover": {
                textDecoration: "underline",
                textUnderlineOffset: "3px",
              }
            }}>
              {t("no_thanks")} <EastRoundedIcon sx={{fontSize: "18px", ml: "2px"}}/>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #3A48A0",
  borderRadius: "8px",
  boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.1)",
  p: 4,
  outline: 0
};
