import React from "react";  // eslint-disable-line no-unused-vars
import Setup from "./setup";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../store/reducers/login.reducer";
import { selectUserProfileLoading } from "../../store/selectors";

export default function Start(): JSX.Element {
  const dispatch = useDispatch();
  const loading = useSelector(selectUserProfileLoading);
  React.useEffect(() => {
    dispatch(getProfile());
    document.title = "Signup";
  }, [dispatch]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return <Setup />;
}
