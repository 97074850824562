import { useSelector } from "react-redux";
import { selectSubscriptionStatus } from "../store/selectors";
import { FREE_TIER } from "../helpers/constants";

export default function useIsPremium(isPremium?:any):any{
  const subscriptionStatus = useSelector(selectSubscriptionStatus);
  const isPremiumActive = subscriptionStatus === FREE_TIER && isPremium;

  return isPremiumActive;
}
