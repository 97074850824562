import React, { useEffect } from "react";  // eslint-disable-line no-unused-vars
import Box from "@mui/material/Box";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import DesktopDrawer from "./desktop-drawer";
import MobileDrawer from "./mobile-drawer";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFirstSetupDone,
  selectTitle,
  selectToken,
  selectSubscriptionActive,
  selectTrackerView,
  selectUserLanguage
} from "../../store/selectors";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import { getProfile } from "../../store/reducers/login.reducer";
import TrackerHeader from "../home/tracker-header";
import RefreshComponent from "./refresh-component";
import { setTaxExportInProgress } from "../../store/slices/tracker.slice";
import StickyHeader from "./sticky-header";
import { ErrorBoundary } from "react-error-boundary";
import {updateSelectedLanguageAsync} from "../../store/reducers/tracker.reducer";
import i18next from "i18next";
import cookies from "js-cookie";
import StartTrial from "./start-trial";
import EndTrial from "./end-trial";

function Tracker(): JSX.Element {
  const token = useSelector(selectToken);
  const trackerView = useSelector(selectTrackerView);
  const firstSetupDone = useSelector(selectFirstSetupDone);
  const title = useSelector(selectTitle);
  const subscriptionActive = useSelector(selectSubscriptionActive);
  // const selectSubscription = useSelector(selectSubscriptionStatus);
  // const isPremiumActive = selectSubscription !== FREE_TIER;
  const currentLanguageCode = cookies.get("i18next") || "en";
  const userLanguage = useSelector(selectUserLanguage);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const containerRef = React.useRef<HTMLDivElement>(null);
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const DrawerComponent = matches ? DesktopDrawer : MobileDrawer;

  const [startRefreshJob, setStartRefreshJob] = React.useState("start");
  useEffect(() => {
    if (!token || !firstSetupDone || (token && token.length === 0)) {
      console.log("Redirecting to login");
      navigate("/");
    }
    // if (!subscriptionActive && location.pathname !== "/" && location.pathname !== "/tracker/settings" && location.pathname !== "/tracker/home") {
    //   navigate("/tracker/home");
    // }
  }, [token, firstSetupDone, navigate, subscriptionActive, location.pathname]);


  React.useEffect(() => {
    if(token && userLanguage){
      currentLanguageCode !== userLanguage ? i18next.changeLanguage(userLanguage) : null;
    }else{
      dispatch(updateSelectedLanguageAsync(currentLanguageCode));
    }
  },[token,userLanguage]);

  React.useEffect(() => {
    if (!token) {
      dispatch(getProfile());
    }
    dispatch(setTaxExportInProgress(false));
  }, [dispatch, token]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Box display="flex" height="100%" ref={containerRef} flexDirection="row">
        <DrawerComponent />
        <Box component="main" style={{
          flexGrow: 1,
          minHeight: "100vh",
          maxHeight: "100%",
          overflow: "auto",
        }}>
          <div style={{
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            paddingTop: matches ? "0px" : "70px",
            // minHeight: "100px",TIMEFRAME
          }}>
            <TrackerHeader title={title} />
          </div>
          {(location?.pathname?.includes("/home") && trackerView === "charts") ? <StickyHeader /> : ""}
          <Box sx={{
            flexGrow: 1,
            flexDirection: "column",
            padding: "20px",
            backgroundColor: theme.palette.action.disabledBackground,
            minHeight: "calc(100vg - 100px - 40px)",
            overflow: "auto",
          }}>
            <Outlet />
          </Box>
        </Box>
        <RefreshComponent startRefreshJob={startRefreshJob} setStartRefreshJob={setStartRefreshJob} />
      </Box>
      <StartTrial/>
      <EndTrial/>
    </ErrorBoundary>
  );
}

export default React.memo(Tracker);

function ErrorFallback({error, resetErrorBoundary}:any) {
  return (
    <div>
      <h1>Something went Wrong !!</h1>
      <p>An error occurred: {error}</p>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}
