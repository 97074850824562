import { ThemeOptions, createTheme } from "@mui/material/styles";

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#3A48A0",
      light: "#FC7D69",
      dark: "#3A48A0",  // FC7D69
      contrastText: "#fff"
    },
    secondary: {
      main: "#3A48A0",
      light: "#7f7f7f",
      dark: "#2C3C79",
    },
    action: {
      active: "#5173F0",
      hover: "#7f7f7f",
      hoverOpacity: 0.08,
      selected: "#839CF4",
      selectedOpacity: 0.4,
      disabled: "#DCE3FC",
      disabledOpacity: 0.08,
      disabledBackground: "#F9FAFC",
      focus: "#3f40a0",
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    background: {
      default: "#3A48A0",
      paper: "#ffffff",
    },
    info: {
      main: "#757575",
      light: "#ffff00",
      dark: "#ffff00",
      contrastText: "#ffff00",
    },
    error: {
      main: "#f50057",
    },
    warning: {
      main: "#ffd034",
    },
    success: {
      main: "#5173F0",
    },
    text: {
      primary: "#000",
      secondary: "#ffffff",
      disabled: "#eee",
    },
  },
  typography: {
    fontFamily: [
      "Poppins",
    ].join(","),
  }
};

export default createTheme(themeOptions);
