import React from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MuiDrawer from "@mui/material/Drawer";
import { CSSObject, Theme, useTheme } from "@mui/material/styles";
import styled from "@mui/material/styles/styled";
import DrawerContent from "./drawer-content";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import useWindowDimensions from "./useWindowDimensions";
import { ReactComponent as DrawerOpener } from "./icons/drawer-opener-icon.svg";
import { ReactComponent as DrawerCloser } from "./icons/drawer-closer-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerOpening, setIsDrawerOpened } from "../../store/slices/tracker.slice";
import { selectIsDrawerOpened, selectTrackerView } from "../../store/selectors";

const drawerWidth = 260;


const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  height: window.innerHeight,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  borderRight: "none",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: "78px",
  },
  borderRight: "none",
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop: any) => prop !== "open" })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 999,
    top: 0,
    left: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }),
);

function DesktopDrawer(): JSX.Element {
  const open = useSelector(selectIsDrawerOpened);
  const view = useSelector(selectTrackerView);
  
  const location = useLocation();
  const [btnPosition, setBtnPosition] = React.useState("251px");
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const drawerRef = React.useRef<HTMLDivElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const dimensions = useWindowDimensions();
  const height = dimensions.height;
  const handleDrawerOpen = () => {
    dispatch(setDrawerOpening(true));
    dispatch(setIsDrawerOpened(true));
    setTimeout(() => {
      dispatch(setDrawerOpening(false));
    }, 300);
  };
  const handleDrawerClose = () => {
    dispatch(setDrawerOpening(true));
    dispatch(setIsDrawerOpened(false));
    setTimeout(() => {
      dispatch(setDrawerOpening(false));
    }, 300);
  };

  const handleScroll = React.useCallback(() => {
    if (window.scrollY > 220 && view === "charts") {
      dispatch(setIsDrawerOpened(false));
    }
  }, []);

  React.useEffect(() => {
    if (location.pathname.includes("/home")){
      window.addEventListener("scroll", handleScroll, true);
    }else{
      window.removeEventListener("scroll", handleScroll, true);
      //dispatch(setIsDrawerOpened(true));
    }
  }, [handleScroll, location]);

  React.useEffect(() => {
    if(!open){
      setBtnPosition("70px");
    }else{
      setBtnPosition("251px");
    }
  },[open]);

  return (<Drawer ref={drawerRef}
    variant="permanent"
    open={open}
    sx={{
      position: matches ? "static" : "fixed",
      height: height + "px",
      width: open ? drawerWidth : "50px",
      "& .MuiPaper-root": {
        height: height + "px",
      },
    }} PaperProps={{ square: true }}>
    <Grid ref={containerRef} container direction="row" alignItems="stretch" alignContent="stretch" justifyItems="stretch" justifyContent="stretch" columnSpacing={0} sx={{
      height: height + "px",
      backgroundColor: theme.palette.background.default,
      borderRadius: "0 12px 12px 0",
    }}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <DrawerContent open={open}/>
      </Grid>
      <Grid item xl={open ? 2 : 12} lg={open ? 2 : 12} md={open ? 2 : 12} sm={open ? 2 : 12} xs={open ? 2 : 12}>
        <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen} color="info" sx={{
          position: "fixed",
          top: "50%",
          left: btnPosition
        }}>
          {open ? <DrawerCloser /> : <DrawerOpener />}
        </IconButton>
      </Grid>
    </Grid>
  </Drawer>);
}

export default React.memo(DesktopDrawer);
