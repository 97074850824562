import React from "react";  // eslint-disable-line no-unused-vars
import { useSelector, useDispatch } from "react-redux";
import { selectSelectedCustomTimeframe, selectSelectedStartDate, selectSelectedEndDate, selectUserCreatedAt } from "../../store/selectors";
import { setSelectedCustomTimeframe, setSelectedStartDate, setSelectedEndDate } from "../../store/slices/tracker.slice";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { ToggleButton, ToggleButtonGroup, Stack, Button, Menu, MenuItem, Box, TextField, Tooltip, Typography } from "@mui/material";
import { StaticDatePicker, LocalizationProvider } from "@mui/lab";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import moment from "moment";
import useIsPremium from "../../hooks/useIsPremium";
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function CustomTimesframePicker(): JSX.Element {
  const [dateType, setDateType] = React.useState("start");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedCustomTimeframe = useSelector(selectSelectedCustomTimeframe);
  const userCreatedAt = useSelector(selectUserCreatedAt);
  const startDate = useSelector(selectSelectedStartDate);
  const endDate = useSelector(selectSelectedEndDate);
  const isPremiumActive = useIsPremium(true);
  
  const handleChange = (event: React.MouseEvent<HTMLElement>, value: any): void => {
    if (value) {
      dispatch(setSelectedCustomTimeframe(value));
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(setSelectedCustomTimeframe("custom"));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (date: Date | null): void => {
    if (date) {
      switch(dateType) {
      case "start":
        dispatch(setSelectedStartDate(date));
        break;
      case "end":
        dispatch(setSelectedEndDate(date));
        break;
      }
    }
  };

  const timeframes = [
    {
      title: "1M",
      value: "7",
      isPremium: false
    },
    {
      title: "3M",
      value: "1",
      isPremium: isPremiumActive
    },
    {
      title: "6M",
      value: "2",
      isPremium: false
    },
    {
      title: "YTD",
      value: "4",
      isPremium: false
    },
    {
      title: "1Y",
      value: "3",
      isPremium: isPremiumActive
    },
    {
      title: "2Y",
      value: "8",
      isPremium: isPremiumActive
    },
    {
      title: "Max",
      value: "6",
      isPremium: isPremiumActive
    },
  ];

  React.useEffect(() => {
    
    if(!selectedCustomTimeframe){
      if(isPremiumActive){
        dispatch(setSelectedCustomTimeframe("4"));
      }else{
        dispatch(setSelectedCustomTimeframe("6"));
      }
    }
  }, [selectedCustomTimeframe]);

  return (
    <Stack direction="row" spacing={0} alignItems="center">
      <ToggleButtonGroup value={selectedCustomTimeframe} exclusive onChange={handleChange} color="primary" fullWidth sx={btnStyle}>
        {timeframes?.map((item: any, index: any) => {
          return (
            <ToggleButton 
              key={index} 
              value={item.value}
              disabled={item.isPremium}
              sx={{ 
                width: "max-content",
              }}>
              {item.isPremium ? (
                <Tooltip title={<TooltipTitle/>} arrow placement="top"
                  PopperProps={{
                    disablePortal: true,
                    sx: {
                      color: "red",
                      "& .MuiTooltip-tooltip": {
                        color: "#fff",
                        backgroundColor: "#FFBD2E",
                        fontSize: "13px",
                        fontWeight: 600,
                        borderRadius: "8px",
                        padding: "1px 13px 0",
                        marginBottom: "8px !important",
                        "& .MuiTooltip-arrow": {
                          color: "#FFBD2E",
                        }
                      }
                    }
                  }}
                >
                  <span>
                    <span>{item.title}</span>
                  </span>

                </Tooltip>
              ) : item.title}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>

      <Button
        id="custom-button"
        onClick={handleClick}
        disabled={isPremiumActive}
        sx={{
          backgroundColor: selectedCustomTimeframe === "custom" ? "#5173F0" : "transparent",
          textTransform: "capitalize",
          color: selectedCustomTimeframe === "custom" ? "#fff" : "#757575",
          fontSize: "13px",
          fontWeight: 600,
          padding: "0 18px",
          height: "23px",
          lineHeight: 1,
          "&:hover": {
            color: selectedCustomTimeframe === "custom" ? "#fff" : "#757575",
            backgroundColor: selectedCustomTimeframe === "custom" ? "#5173F0" : "#839cf417",
          },
          "&.Mui-disabled": {
            color: "#FFBD2E",
            backgroundColor: "transparent",
            pointerEvents: "auto",
            cursor: "pointer",
          }
        }}>
        <Tooltip title={isPremiumActive ? <TooltipTitle/> : ""} arrow placement="top"
          PopperProps={{
            disablePortal: true,
            sx: {
              color: "red",
              "& .MuiTooltip-tooltip": {
                color: "#fff",
                backgroundColor: "#FFBD2E",
                fontSize: "13px",
                fontWeight: 600,
                borderRadius: "8px",
                padding: "1px 13px 0",
                marginBottom: "8px !important",
                "& .MuiTooltip-arrow": {
                  color: "#FFBD2E",
                }
              }
            }
          }}
        >
          <span>{t("custom")}</span>
        </Tooltip>
      </Button>

      <Menu
        id="custom-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiMenuItem-root:hover": {
            backgroundColor: "transparent"
          }
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}>
        <MenuItem disableRipple sx={{padding: "0"}}>
          <Box sx={{width: "298px"}}>
            <Box sx={{display: "flex", justifyContent: "space-between", padding: "3px 12px 0px", alignItems: "center"}}>
              <Box> <DateButton value={startDate} onClick={() => setDateType("start")}/> </Box>
              <Typography sx={{fontWeight: 500, color: "#c4c4c4a6", }}>To</Typography>
              <Box> <DateButton value={endDate} onClick={() => setDateType("end")}/> </Box>
            </Box>
            <Box sx={{
              ...datePickerStyle,
              "& .MuiPickerStaticWrapper-root": {
                minWidth: "274px"
              }
            }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  minDate={new Date(Date.parse(userCreatedAt)) || new Date("2018-01-01")}
                  maxDate={new Date()}
                  openTo="month"
                  views={["year", "month"]}
                  value={dateType === "start" ? startDate : endDate}
                  onChange={handleDateChange}
                  inputFormat="MMM yyyy"
                  disableFuture
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </MenuItem>
      </Menu>
    </Stack>
  );
}

function DateButton(props: any): JSX.Element {
  const date = props.value ? moment(props.value)?.format("MMM yyyy") : moment.utc(new Date())?.format("MMM yyyy");
  return (
    <Button
      variant="contained" endIcon={<ArrowDropDownIcon />}
      onClick={props.onClick}
      sx={{
        backgroundColor: "#3A48A0",
        color: "#fff",
        fontWeight: 500,
        boxShadow: "none",
        fontSize: "12px",
        padding: "5px 12px",
        lineHeight: 1.2,
        borderRadius: "6px",
        textTransform: "capitalize"
      }}>
      {date}
    </Button>
  );
}

function TooltipTitle(): JSX.Element{
  const { t } = useTranslation();
  return(
    <Box sx={{display: "flex", flexDirection: "column", p: "6px 3px"}}>
      <Typography sx={{fontSize: "14px", fontWeight: 600, lineHeight: 1}}>{t("premium")}</Typography>
      <Button
        component={Link}
        to="/tracker/settings"
        variant="contained"
        sx={{
          color: "#fff",
          fontSize: "12px",
          background: "transparent",
          fontWeight: 500,
          boxShadow: "none",
          padding: 0,
          height: "max-contrnt",
          textTransform: "capitalize",
          textDecoration: "underline",
          "&:hover": {
            background: "transparent",
            boxShadow: "none"
          },
        }}
      >{t("subscribe")}</Button>
    </Box>
  );
}

const datePickerStyle = {
  backgroundColor: "#fff",
  padding: "4px 12px 12px",
  width: "274px",
  maxWidth: "274px",
  borderRadius: "8px",
  // boxShadow: "0px 4px 26px rgba(0, 0, 0, 0.1)",
  marginTop: "6px",

  "& .MuiCalendarPicker-root": {
    width: "100%",
    maxWidth: "274px",
    margin: 0,
  },

  "& .MuiCalendarPicker-root > div:first-of-type": {
    margin: "0 0 9px",
    padding: "0 4px 4px",
    borderBottom: "1px solid #dce3fc61",
    "& .MuiIconButton-root": {
      padding: 0,
    }
  },

  "& .MuiMonthPicker-root": {
    width: "100%",
    margin: 0,
    gap: "10px",

    "& .PrivatePickersMonth-root": {
      borderRadius: "8px",
      fontSize: "12px",
      color: "#000000",
      fontWeight: 500,
      margin: 0,
      backgroundColor: "#fff",
      flex: "1 0 calc(33.33% - 10px)",
      "&:hover": {
        color: "#5173F0",
        backgroundColor: "#EEF2F9CC",
      },
      "&:disabled": {
        color: "#00000026"
      },
      "&.Mui-selected": {
        color: "#fff",
        backgroundColor: "#5173F0"
      }
    }
  },

  "& .MuiYearPicker-root": {
    width: "100%",
    margin: 0,
    gap: "10px",

    "& .PrivatePickersYear-root": {
      flex: "1 0 calc(33.33% - 10px)",

      "& .PrivatePickersYear-yearButton": {
        borderRadius: "8px",
        fontSize: "12px",
        color: "#000000",
        fontWeight: 500,
        margin: 0,
        backgroundColor: "#fff",
        width: "100%",
        "&:hover": {
          color: "#fff",
          backgroundColor: "#839CF4",
        },
        "&:disabled": {
          color: "#00000026"
        },
        "&.Mui-selected": {
          color: "#fff",
          backgroundColor: "#5173F0"
        }
      }
    }
  }
};

const btnStyle = {
  rowGap: "0px",
  ".MuiToggleButtonGroup-grouped": {
    border: "none",
    height: "24px",
    borderRadius: "5px !important",
    padding: "0px 10px",
    color: "#757575",
    fontWeight: 600,
    backgroundColor: "transparent",
    textTransform: "capitalize",
    fontSize: "14px",
    marginRight: "4px",
    "&:hover": {
      backgroundColor: "#839cf417",
    },
    "&:last-of-type": {
      marginRight: "0px",
    },
    "&.Mui-selected": {
      border: "none",
      color: "#fff",
      backgroundColor: "#5173F0",
      fontWeight: 600,
      "&:hover": {
        backgroundColor: "#5173F0",
        color: "#fff",
      },
      "&.Mui-disabled": {
        backgroundColor: "transparent",
        color: "#FFBD2E",
      }
    },
    "&.Mui-disabled": {
      border: "none",
      // backgroundColor: "#DCE3FC",
      color: "#FFBD2E",
      backgroundColor: "transparent",
      pointerEvents: "auto",
      cursor: "pointer",
    }
  },
};
