export default function CurrencySign(currency: string): string {
  switch(currency) {
  case "USD":
    return "$";
  case "EUR":
    return "€";
  case "GBP":
    return "£";
  case "JPY":
    return "¥";
  case "CAD":
    return "$";
  case "AUD":
    return "$";
  case "NZD":
    return "$";
  case "CHF":
    return "Fr";
  case "SGD":
    return "$";
  case "SEK":
    return "kr";
  case "DKK":
    return "kr";
  case "NOK":
    return "kr";
  case "HUF":
    return "Ft";
  case "CZK":
    return "Kč";
  case "PLN":
    return "zł";
  case "MXN":
    return "$";
  case "BRL":
    return "R$";
  case "MYR":
    return "RM";
  case "PHP":
    return "₱";
  case "THB":
    return "฿";
  case "IDR":
    return "Rp";
  case "ILS":
    return "₪";
  case "INR":
    return "₹";
  case "KRW":
    return "₩";
  case "TWD":
    return "NT$";
  case "CNY":
    return "¥";
  case "RUB":
    return "₽";
  case "TRY":
    return "₺";
  case "UAH":
    return "₴";
  case "VND":
    return "₫";
  case "EGP":
    return "£";
  case "ZAR":
    return "R";
  case "AED":
    return "د.إ";
  case "ARS":
    return "$";
  case "BHD":
    return "ب.د";
  case "BND":
    return "$";
  case "BSD":
    return "$";
  case "CLP":
    return "$";
  case "COP":
    return "$";
  case "DOP":
    return "$";
  case "GTQ":
    return "Q";
  case "HKD":
    return "$";
  case "JMD":
    return "$";
  case "KWD":
    return "د.ك";
  case "LKR":
    return "Rs";
  case "MUR":
    return "Rs";
  case "NAD":
    return "$";
  case "PEN":
    return "S/.";
  case "PYG":
    return "Gs";
  case "SAR":
    return "ر.س";
  case "SBD":
    return "$";
  case "SRD":
    return "$";
  case "TTD":
    return "$";
  case "UYU":
    return "$";
  case "VEF":
    return "Bs";
  case "VUV":
    return "VT";
  case "XCD":
    return "$";
  case "ZMW":
    return "K";
  default:
    return "";
  }
}