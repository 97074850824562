import React from "react";  // eslint-disable-line no-unused-vars
import { useDispatch, useSelector } from "react-redux";
import { selectShowTrialMessage, selectSubscriptionActiveUntil } from "../../store/selectors";
import { createStripeSession } from "../../store/reducers/tracker.reducer";
import TrialPopup from "./trial-popup";

interface ReduxTrialPopupProps {
  children: React.ReactNode;
}

function getDayDiff(startDate: Date, endDate: Date): number {
  const msInDay = 24 * 60 * 60 * 1000;

  if (startDate.getTime() <= endDate.getTime()) {
    return Math.round(Math.abs(Number(endDate) - Number(startDate)) / msInDay);
  } else {
    return 0;
  }
}

export default function ReduxTrialPopup(props: ReduxTrialPopupProps): JSX.Element {
  const dispatch = useDispatch();
  const showTrialMessage = useSelector(selectShowTrialMessage);
  const activeUntil = useSelector(selectSubscriptionActiveUntil);
  const now = new Date();
  const activeUntilDate = new Date(activeUntil);
  const trialRemainingDays = getDayDiff(now, activeUntilDate);
  const handleClose = () => {
    dispatch(createStripeSession("premium_monthly"));
  };
  return <TrialPopup open={showTrialMessage} onExtend={handleClose} trialRemaining={trialRemainingDays}>
    {props.children}
  </TrialPopup>;
}
