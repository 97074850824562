import React from "react";  // eslint-disable-line no-unused-vars
import { useDispatch, useSelector } from "react-redux";
import { selectCurrency, selectCurrentCurrency } from "../../store/selectors";
import CurrencySign from "../login/currency";
import EthFiatSwitcher from "./eth-fiat-switcher";
import { setCurrentCurrency } from "../../store/slices/tracker.slice";

function ReduxEthFiatSwitcher(): JSX.Element {
  const dispatch = useDispatch();
  const currentCurrency = useSelector(selectCurrentCurrency);
  const selectedCurrency = useSelector(selectCurrency);
  const handleEthFiatChange = (
    event: React.MouseEvent<HTMLElement>,
    newEthFiat: string | null,
  ): void => {
    if (newEthFiat) {
      dispatch(setCurrentCurrency(newEthFiat));
    }
  };
  const currencySign = CurrencySign(selectedCurrency);
  return <EthFiatSwitcher value={currentCurrency} onChange={handleEthFiatChange} currencySymbol={currencySign}/>;
}

export default React.memo(ReduxEthFiatSwitcher);
