import React from "react";  // eslint-disable-line no-unused-vars
import Button from "@mui/material/Button";
import { updateSelectedCurrencyAsync } from "../../store/reducers/tracker.reducer";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrency } from "../../store/selectors";
import { setCurrency, setCurrencySelected } from "../../store/slices/login.slice";

interface UpdateCurrencyButtonProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

export default function UpdateCurrencyButton(props: UpdateCurrencyButtonProps): JSX.Element {
  const { children, style } = props;
  const dispatch = useDispatch();
  const currency = useSelector(selectCurrency);
  const handleCurrencySubmit = () => {
    dispatch(updateSelectedCurrencyAsync(currency));
    dispatch(setCurrency(currency));
    dispatch(setCurrencySelected(true));
  };
  return <Button
    variant="contained"
    color="primary"
    onClick={handleCurrencySubmit}
    sx={{
      textTransform: "capitalize",
      borderRadius: "8px",
    }}
    style={style}
  >{children}</Button>;
}
