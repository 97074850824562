import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { Provider } from "react-redux";
import theme from "./assets/css/theme";
import store from "./store/store";
import "./configs/i18next/i18next.config";
import Callback from "./pages/login/callback";
import {
  LoginPage,
  EmailPage,
  CurrencyPage,
  Start,
  TwitterPage,
  AdminPage,
  Tracker,
  HomePage,
  CurrentSquadPage,
  TransactionsPage,
  RewardAnalyzerPage,
  WinnigLineupPage,
  DataExportPage,
  GoogleSheetsPage,
  TaxReportsPage,
  SettingsPage,
  SearchPage,
  CardDetailPage,
  MarketData
} from "./routes";


// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker.ready.then(registration => {
//     registration.unregister();

//     if (caches) {
//       // Service worker cache should be cleared with caches.delete()
//       caches.keys().then(async (names) => {
//         await Promise.all(names.map(name => caches.delete(name)));
//       });
//     }
//   });
// }

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<div>Loading...</div>}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<App />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/email" element={<EmailPage />} />
              <Route path="/currency" element={<CurrencyPage />} />
              <Route path="/setup" element={<Start />} />
              <Route path="/twitter" element={<TwitterPage />} />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/tracker" element={<Tracker />}>
                <Route path="/tracker/home" element={ <HomePage />} />
                <Route path="/tracker/current-squad" element={<Suspense fallback={<>Loading...</>}><CurrentSquadPage /></Suspense>} />
                <Route path="/tracker/transactions" element={<Suspense fallback={<>Loading...</>}><TransactionsPage /></Suspense>} />
                <Route path="/tracker/reward-analyzer" element={<Suspense fallback={<>Loading...</>}><RewardAnalyzerPage /></Suspense>} />
                <Route path="/tracker/reward-analyzer/:id" element={<Suspense fallback={<>Loading...</>}><WinnigLineupPage /></Suspense>} />
                <Route path="/tracker/data-export" element={<Suspense fallback={<>Loading...</>}><DataExportPage /></Suspense>} />
                <Route path="/tracker/google-sheets" element={<Suspense fallback={<>Loading...</>}><GoogleSheetsPage /></Suspense>} />
                <Route path="/tracker/tax-reports" element={<Suspense fallback={<>Loading...</>}><TaxReportsPage /></Suspense>} />
                <Route path="/tracker/settings" element={<Suspense fallback={<>Loading...</>}><SettingsPage /></Suspense>} />
                <Route path="/tracker/search" element={<Suspense fallback={<>Loading...</>}><SearchPage /></Suspense>} />
                <Route path="/tracker/market-data" element={<Suspense fallback={<>Loading...</>}><MarketData /></Suspense>} />
                <Route path="/tracker/card/:slug" element={<Suspense fallback={<>Loading...</>}><CardDetailPage /></Suspense>} />
              </Route>
              <Route path="/login-callback" element={<Callback />} />
              <Route path="*" element={<App />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </ThemeProvider>
    </Provider>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
    />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
