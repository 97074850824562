/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThunkAction } from "@reduxjs/toolkit";
import { agreeToTerms, endFreeTrial, getUserProfile, signin, signinDirect, skipTwitter, startFreeTrial } from "../api";
import { RootState } from "../store";
import { setShowTrialMessage, setUserCreatedAt } from "../slices/tracker.slice";
import { setToken, setUserProfile, setUserProfileLoading, setStartTrialLoading } from "../slices/login.slice";

// const clearCacheData = () => {
//   caches.keys().then((names) => {
//     names.forEach((name) => {
//       caches.delete(name);
//     });
//   });
// };

export function signInAsync(
  code: string
): ThunkAction<Promise<boolean>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, _getState) => {
    try {
      const response = await signin(code);
      dispatch(setToken(response.token));
      return true;
    } catch (error) {
      dispatch(setToken(""));
      return false;
    }
  };
}

export function signInDirectAsync(
  code: string
): ThunkAction<Promise<boolean>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, _getState) => {
    try {
      const response = await signinDirect(code);
      dispatch(setToken(response.token));
      return true;
    } catch (error) {
      dispatch(setToken(""));
      return false;
    }
  };
}

export const signOutAsync = (): ThunkAction<
  Promise<boolean>,
  RootState,
  undefined,
  any
> => {
  return (dispatch) => {
    console.log("signOutAsync");
    dispatch(setToken(""));
    // clearCacheData();
    dispatch({type: "signin/logout"});
    return new Promise<boolean>(() => true);
  };
};

export function getProfile(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, getState) => {
    try {
      dispatch(setUserProfileLoading(true));
      const result = await getUserProfile();
      dispatch(setUserProfile(result));
      switch(result.subscription_status) {
      case "signup_trial":
        if (result.valid_until) {
          const validUntil = new Date(result.valid_until);
          const now = new Date();
          const trialTime = new Date(validUntil.getFullYear(), validUntil.getMonth(), validUntil.getDate() - 3);
          const inTrialPeriod = now.getTime() >= trialTime.getTime();
          console.log("inTrialPeriod", inTrialPeriod);
          dispatch(setShowTrialMessage(inTrialPeriod));
        }
        break;
      case "active":
        dispatch(setShowTrialMessage(false));
        break;
      case "trial_expired":
      case "cancelled":
        dispatch(setShowTrialMessage(false));
        break;
      default:
        dispatch(setShowTrialMessage(false));
        break;
      }
      dispatch(setUserCreatedAt(result.user_created_at));
      dispatch(setUserProfileLoading(false));
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function startTrial(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, getState) => {
    try {
      dispatch(setStartTrialLoading(true));
      const result = await startFreeTrial();
      const user = result.user;
      
      setTimeout(() => {
        dispatch(setUserProfile(user));
        dispatch(setStartTrialLoading(false));
      }, 3000);
      
    } catch (error) {
      dispatch(setStartTrialLoading(false));
      console.log("error", error);
    }
  };
}

export function endTrial(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, getState) => {
    try {
      const result = await endFreeTrial();
      const user = result.user;
      dispatch(setUserProfile(user));
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function updateAgreeToTerms(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, getState) => {
    try {
      await agreeToTerms();
      const result = await getUserProfile();
      dispatch(setUserProfile(result));
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function updateSkipTwitter(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch, getState) => {
    try {
      await skipTwitter();
      const result = await getUserProfile();
      dispatch(setUserProfile(result));
    } catch (error) {
      console.log("error", error);
    }
  };
}
