import React from "react";  // eslint-disable-line no-unused-vars
import Grid from "@mui/material/Grid";
import { AccountAccessHint } from "./account-access-hint";
import LoginButton from "./login-button";
import Template from "../start/template";
import { ReactComponent as LogoWithText } from "./icons/logo-with-text.svg";

export default function Login(): JSX.Element {
  return (<Template hideLogo fullHeight>
    <Grid
      container
      direction="row"
      alignContent="center"
      alignItems="flex-end"
      justifyContent="center"
      justifyItems="center"
      sx={{
        position: "relative",
        height: "100%",
      }}
    >
      <Grid container direction="row" alignItems="flex-end" alignContent="flex-end" justifyItems="center" justifyContent="center" sx={{
        height: "50%",
      }} rowSpacing="64px">
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid
            container
            direction="row"
            justifyItems="center"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xl={1.2} lg={1.75} md={2.45} sm={3.28} xs={5.9295}>
              <LogoWithText />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <LoginButton />
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="flex-end" alignContent="flex-end" justifyItems="center" justifyContent="center" sx={{
        height: "50%",
      }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <AccountAccessHint/>
        </Grid>
      </Grid>
    </Grid>
  </Template>);
}
