import styled from "@mui/material/styles/styled";
import OptionUnstyled, { optionUnstyledClasses } from "@mui/base/OptionUnstyled";

export default styled(OptionUnstyled)(
  () => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;
  z-index: 1;

  font-size: 12px;
  line-height: 15px;
  color: #757575;
  max-width: 100px;
  border-radius: 6px;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: rgba(81, 115, 240, 0.2);
    color: #3A48A0;
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: rgba(81, 115, 240, 0.2);
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: rgba(81, 115, 240, 0.2);
  }

  &.${optionUnstyledClasses.disabled} {
    color: #757575;
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: rgba(81, 115, 240, 0.2);
    color: #757575;
  }
  `,
);
