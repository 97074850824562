import React from "react";  // eslint-disable-line no-unused-vars
import styled from "@mui/material/styles/styled";
import InputUnstyled, { InputUnstyledProps } from "@mui/base/InputUnstyled";

const StyledInputElement = styled("input")(
  ({ theme }) => `
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: ${theme.palette.text.primary};
  background: ${theme.palette.action.disabled};
  border: 0px;
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;

  &:hover {
    background: ${theme.palette.action.disabled};
    border-color: ${theme.palette.text.primary};
  }

  &:focus {
    outline: 0px solid ${theme.palette.text.primary};
  }
`,
);

export default React.forwardRef(function CustomInput(
  props: InputUnstyledProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <InputUnstyled components={{ Input: StyledInputElement }} {...props} ref={ref} />
  );
});