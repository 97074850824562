/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  addLoans,
  checkGSheets,
  checkTransactions,
  createStripeCheckoutSession,
  getChartData,
  getCustomerPortalUrl,
  getTableData,
  // getTransactions,
  refreshTransactions,
  updateAllowSendingEmails,
  updateEmail,
  // updateGSheets,
  updateSelectedCurrency,
  setLoanTransaction,
  getTaxReport,
  getGSheetsReport,
  checkTaxReportJob,
  checkGSheetReportJob,
  lastTaxReport,
  getCurrencies,
  getWinningLineups,
  getCardByTitle,
  getPlayerValuation,
  getPlayerCardSales,
  getCardSummary,
  getCardHistory,
  getPerformanceCardRewardSummary,
  getPerformanceCardRewardWon,
  getPlayerCombinedSaleValuation,
  getMarketCompositionData,
  getReport,
  updateSelectedLanguage,
  getMarketIndexData,
  getSalesIndexData
} from "../api";

import {
  setCurrentCurrencyError,
  setCurrentCurrencyLoading,
  setCurrentCurrencySuccess,
  setCurrentSquadTableData,
  setCurrentSquadTableDataError,
  setCurrentSquadTableDataLoading,
  setCurrentSquadTableDataSuccess,
  setImpactPlayersTableData,
  setImpactPlayersTableDataError,
  setImpactPlayersTableDataLoading,
  setImpactPlayersTableDataSuccess,
  setProfitsByMonthData,
  setProfitsByMonthDataError,
  setProfitsByMonthDataLoading,
  setProfitsByMonthDataSuccess,
  setRefreshDataJobId,
  setRefreshError,
  setRefreshInProgress,
  setRefreshSuccess,
  setROIData,
  setROIDataError,
  setROIDataLoading,
  setROIDataSuccess,
  setTransactionTableData,
  setTransactionTableDataError,
  setTransactionTableDataLoading,
  setTransactionTableDataSuccess,
  setLoansData,
  setRewardsDetailsChartData,
  setRewardsMonthlyChartData,
  setRewardsCumulativeChartData,
  setExportSheetExists,
  setExportSheetLink,
  setExportLoading,
  setExportSuccess,
  setExportError,
  setRefreshErrorDisplay,
  setCardsSoldChartDataLoading,
  setCardsSoldChartData,
  setCardsSoldChartDataSuccess,
  setPortfolioChartData,
  setRewardAnalyzerData,
  setSummaryStatsData,
  setRefreshStart,
  setTradingRoiByCohortChartData,
  setWithdrawalsDepositsData,
  setSquadCompositionData,
  setTradingRoiCumulativeData,
  setTaxExportInProgress,
  setTaxExportError,
  setTaxExportSuccess,
  setTaxExportDisplayMessage,
  setTaxExportResult,
  setAvailableCurrencies,
  setWinningLineupsData,
  setPastTaxExportResult,
  setCardSearchData,
  setPlayerCardSalesData,
  setPlayerCardValuationsData,
  // setCardDetailsSummary,
  setPersonalCardHistory,
  setCardRewardSummary,
  setCardRewardsWon,
  // setCombinedSalesValuationData,
  setPortfolioValueRarity,
  setCardsAcquiredData,
  setMarketCompositionDataLoading,
  setMarketCompositionData,
  setReportsSummaryData,
  setReportsRewardsData,
  setReportsTop4RewardsData,
  setReportsTopFlopsData,
  setReportTopPlayers,
  setCurrentSquadLoadInitialData,
  settransactionLoadInitialData,
  setRefreshSuccessDisplay,
  setChartsLoadInitialData,
  setPortfolioRoiData,
  setSquadCompositionByLeague,
  setMarketIndexDataLoading,
  setMarketIndexData,
  setAllSalesDataLoading,
  setAllSalesIndexData,
} from "../slices/tracker.slice";
import {
  CurrentSquadResponse,
  CurrentSquadTableRow,
  ImpactPlayersResponse,
  ImpactPlayersTableRow,
  ProfitsByMonthData,
  ProfitsByMonthResponse,
  ROIData,
  ROIResponse,
  TransactionTableRow,
  RewardsDetailsData,
  RewardsMonthlyData,
  RewardsCumulativeData,
  TransactionsResponse,
  CardsSoldData,
  PortfolioData,
  SummaryStatsData,
  RetrieveTaxReportsResponse,
  RewardAnalyzerData,
  WinnigLineup,
  // LastTaxReportResponse,
  CardSearchResponse,
  // PlayerCardSalesResponse,
  // CardDetailsSummaryResponse,
  PersonalCardHistoryResponse,
  CardPerformanceRewardWon,
  // PlayerCardValuationsResponse,
  ReportsSummaryData, ReportsTop4RewardsData, ReportsTopFlopsData, ReportsRewardsData, ReportTopPlayers
} from "../../models";
import {getProfile} from "./login.reducer";
import { batch, useSelector } from "react-redux";
import { selectMarketDataTypeQuery } from "../selectors";

function finishRefresh(dispatch: any): void {
  batch(() => {
    dispatch(setRefreshStart("finish"));
    dispatch(setRefreshInProgress(false));
    dispatch(setRefreshSuccess(true));
    dispatch(setRefreshSuccessDisplay(true));
    dispatch(setRefreshError(""));
    dispatch(setRefreshErrorDisplay(false));
  });
  // console.log("finishRefresh -->");
  //window.location.reload();
}

function finishRefreshError(dispatch: any): void {
  console.log("finishRefreshError");
  dispatch(setRefreshStart("finish"));
  dispatch(setRefreshInProgress(false));
  dispatch(setRefreshSuccess(false));
  dispatch(setRefreshError("Job failed"));
  dispatch(setRefreshErrorDisplay(true));
}

function loadInitialData(dispatch:any): void {
  dispatch(setCurrentSquadLoadInitialData(true));
  dispatch(settransactionLoadInitialData(true));
  dispatch(setChartsLoadInitialData(true));
}

// async function checkAllTransactions(dispatch: any) {
//   const allTransactions = await getTransactions(); //Getting All transaction data from api
//   for (const transaction of allTransactions) {
//     if (transaction.status === "in progress" || transaction.status === "pending") {
//       console.log("waitForRefreshJob", "checkAllTransactions");
//       await waitForRefreshJob(transaction.id, checkTransactions); //Starting refresh job with this job_id
//       finishRefresh(dispatch);
//       return true;
//     }
//   }
//   return false;
// }

// export function refreshDataAsync(): ThunkAction<Promise<void>, RootState, undefined, any> {
//   console.log("refreshDataAsync");
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   return async (dispatch: any, getState: any) => {
//     try {
//       dispatch(setRefreshInProgress(true));
//       const jobId: number = getState().tracker.refreshDataJobId;
//       const refreshInProgress: boolean = getState().tracker.refreshInProgress;
//       console.log(refreshInProgress);
//       if (jobId) {
//         // job id is saved in state
//         await handleExistingJobId(jobId, dispatch, checkTransactions, finishRefreshError, finishRefresh, handleExistingReadyJob);
//       } else {
//         // no job id is saved in state
//         await handleNewJob(dispatch);
//       }
//     } catch (error) {
//       console.dir(error);
//       finishRefreshError(dispatch);
//     }
//   };
// }

export function refreshDataAsync(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // console.log("refreshDataAsync -->");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(setRefreshInProgress(true));
      await handleRefreshJob(dispatch);
      finishRefresh(dispatch);
    } catch (error) {
      console.dir(error);
      finishRefreshError(dispatch);
    }
  };
}

async function handleRefreshJob(dispatch: any): Promise<void> {
  try{
    const refreshResult = await refreshTransactions(); //Trigger the transactions job refresh
    if (refreshResult?.status === "success") {
      await waitForRefreshJob(refreshResult.job_id, checkTransactions);  //Starting Refresh job
      loadInitialData(dispatch);
      dispatch(setRefreshDataJobId(refreshResult.job_id));
    }
  } catch (error){
    console.dir(error);
    finishRefreshError(dispatch);
  }
}




// async function handleNewJob(dispatch: any): Promise<void> {
//   //console.log("checkAllTransactions2");
//   console.log("Handle new JOB_ID:");
//   if (!await checkAllTransactions(dispatch)) {
//     // no job is in progress, start a new job
//     // create job if not yet stored
//     const refreshResult = await refreshTransactions(); //Trigger the transactions job refresh
//     if (refreshResult.status === "success") {
//       dispatch(setRefreshDataJobId(refreshResult.job_id));
//       console.log("waitForRefreshJob", "refreshDataAsync3");
//       await waitForRefreshJob(refreshResult.job_id, checkTransactions);  //Starting Refresh job
//       finishRefresh(dispatch);
//     }
//   }
// }

// async function handleExistingJobId(
//   jobId: number,
//   dispatch: any,
//   checkCallback: (jobId: number) => any,
//   finishCallbackError: (dispatch: any) => any,
//   finishCallbackReady: (dispatch: any) => any,
//   finishExistingReadyCallback: (dispatch: any) => Promise<any>
// ): Promise<void> {
//   console.log(jobId);
//   // Check if the saved job state is still in progress?
//   const result = await checkCallback(jobId); //Starting refresh job with this job_id
//   switch (result.status) {
//   case "in progress":
//   case "pending":
//     // existing job is still in progress
//     // wait for it to complete
//     console.log("waitForRefreshJob", "refreshDataAsync2");
//     await waitForRefreshJob(jobId, checkCallback);
//     finishCallbackReady(dispatch);
//     break;
//   case "ready":
//     // existing job is complete
//     // If the saved job state is ready start new job.
//     await finishExistingReadyCallback(dispatch);
//     break;
//   case "failed":
//     // existing job has failed
//     finishCallbackError(dispatch);
//     break;
//   default:
//     break;
//   }
// }

// async function handleExistingReadyJob(dispatch: any): Promise<void> {
//   console.log("checkAllTransactions1");
//   // check, if a job has been started somewhere else, if not, start a new job
//   if (!await checkAllTransactions(dispatch)) {
//     console.log("trigger refreshTransactions1");
//     // refresh data if the current refresh job has completed sucessfully
//     const refreshResult = await refreshTransactions();
//     if (refreshResult.status === "success") {
//       dispatch(setRefreshDataJobId(refreshResult.job_id));
//       console.log("waitForRefreshJob", "refreshDataAsync1");
//       await waitForRefreshJob(refreshResult.job_id, checkTransactions);
//       finishRefresh(dispatch);
//     } else {
//       finishRefreshError(dispatch);
//     }
//   }
// }

function waitForRefreshJob(jobId: number, callback: (jobId: number) => any): Promise<void> {
  // console.log("waiting for job to complete", jobId);
  return new Promise<void>((resolve, reject) => {
    const interval = setInterval(async () => {
      try {
        // check if job is still in progress
        const result = await callback(jobId);
        switch(result.status) {
        case "ready":
          clearInterval(interval);
          resolve();
          break;
        case "failed":
          clearInterval(interval);
          reject();
          break;
        }
      } catch (error) {
        clearInterval(interval);
        reject(error);
      }
    }, 5000);  // check every 5 seconds
  });
}

export function updateSelectedCurrencyAsync(currency: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(setCurrentCurrencyLoading(true));
      await updateSelectedCurrency(currency);
      dispatch(setCurrentCurrencyLoading(false));
      dispatch(setCurrentCurrencySuccess(true));
      // window.location.reload();
    } catch (error) {
      dispatch(setCurrentCurrencyLoading(false));
      console.dir(error);
      dispatch(setCurrentCurrencySuccess(false));
      dispatch(setCurrentCurrencyError((error as any).message));
    }
  };
}

export function updateSelectedLanguageAsync(language: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  return async (dispatch: any) => {
    try {
      await updateSelectedLanguage(language);
      dispatch(getProfile());
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getProfitByMonthChartData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(setProfitsByMonthDataLoading(true));
      const result = await getChartData("profit_by_month");
      const resultROI: ProfitsByMonthResponse[] = result;
      const profitsByMonthList: ProfitsByMonthData[] = [];
      for (const item of resultROI) {
        const month = item.month;
        const monthLabel = month.toLocaleString("default", { month: "long", year: "numeric" });
        profitsByMonthList.push({
          month: month,
          label: monthLabel,
          profitEth: item.profit_eth,
          profitFiat: item.profit_fiat,
          profitType: item.profit_category,
        });
      }
      dispatch(setProfitsByMonthData(profitsByMonthList));
      dispatch(setProfitsByMonthDataLoading(false));
      dispatch(setProfitsByMonthDataSuccess(true));
    } catch (error) {
      dispatch(setProfitsByMonthDataLoading(false));
      dispatch(setProfitsByMonthDataSuccess(false));
      dispatch(setProfitsByMonthDataError((error as any).message));
      console.dir(error);
    }
  };
}

export function getSquadCompositionData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    const result = await getChartData("squad_composition_by_rarity");
    const data = result.map((item: any) => {
      const date = new Date(item.date).getTime()/1000;
      item.date = date;
      return item;
    });
    dispatch(setSquadCompositionData(data));
  };
}

export function getSquadCompositionByLeagueData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    const result = await getChartData("squad_composition_by_league");
    const data = result.map((item: any) => {
      const date = new Date(item.date).getTime()/1000;
      item.date = date;
      return item;
    });
    dispatch(setSquadCompositionByLeague(data));
  };
}

// export function getPortfolioValueByRarity(): ThunkAction<Promise<void>, RootState, undefined, any> {
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   return async (dispatch: any, getState: any) => {
//     const result = await getChartData("portfolio_value_by_rarity");
//     const data = result.map((item: any) => {
//       const date = new Date(item.date).getTime()/1000;
//       item.date = date;
//       return item;
//     });
//     dispatch(setPortfolioValueRarity(data));
//   };
// }

export function getCardsAcquiredData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    const result = await getChartData("cards_acquired");
    const data = result.map((item: any) => {
      const date = new Date(item.transaction_timestamp).getTime();
      item.date = date;
      return item;
    });
    dispatch(setCardsAcquiredData(data));
  };
}

export function getTradingRoiCumulativeData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    const result = await getChartData("trading_roi_cumulative");
    const data = result.map((item: any) => {
      const date = new Date(item.month).getTime()/1000;
      return {
        ...item,
        date
      };
    });
    dispatch(setTradingRoiCumulativeData(data));
  };
}

export function getPortfolioRoiData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    const result = await getChartData("portfolio_roi");
    const data = result.map((item: any) => {
      const date = new Date(item.date).getTime()/1000;
      return {
        ...item,
        date
      };
    });
    dispatch(setPortfolioRoiData(data));
  };
}

export function getWithdrawalsDepositsData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result = await getChartData("withdrawals_deposits");
      const data = result.map((item: any) => {
        const date = new Date(item.transaction_timestamp).getTime();
        return {
          ...item,
          date: date
        };
      });
      dispatch(setWithdrawalsDepositsData(data));
    } catch (error) {
      console.dir(error);
    }
  };
}

export function getRewardsDetailsChartData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: RewardsDetailsData[] = await getChartData("rewards_detailed");
      dispatch(setRewardsDetailsChartData(result));
    } catch(error) {
      console.dir(error);
    }
  };
}

export function getRewardsMonthlyChartData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: RewardsMonthlyData[] = await getChartData("rewards_monthly");
      dispatch(setRewardsMonthlyChartData(result));
    } catch(error) {
      console.dir(error);
    }
  };
}

export function getTradingRoiByCohortChartData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result = await getChartData("trading_roi_by_cohort");
      dispatch(setTradingRoiByCohortChartData(result));
    } catch(error) {
      console.dir(error);
    }
  };
}

export function getRewardsCumulativeChartData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: RewardsCumulativeData[] = await getChartData("rewards_cumulative");
      const res = result.map((item: any) => {
        const time = new Date(item.date).getTime()/1000;
        item.date = time;
        item.cumulative_eth_rewards_eth = item.cumulative_eth_rewards_eth ? item.cumulative_eth_rewards_eth : 0;
        item.cumulative_eth_rewards_fiat = item.cumulative_eth_rewards_fiat ? item.cumulative_eth_rewards_fiat : 0;
        item.cumulative_sold_rewards_eth = item.cumulative_sold_rewards_eth ? item.cumulative_sold_rewards_eth : 0;
        item.cumulative_sold_rewards_fiat = item.cumulative_sold_rewards_fiat ? item.cumulative_sold_rewards_fiat : 0;
        item.kept_rewards_valuation_eth = item.kept_rewards_valuation_eth ? item.kept_rewards_valuation_eth : 0;
        item.kept_rewards_valuation_fiat = item.kept_rewards_valuation_fiat ? item.kept_rewards_valuation_fiat : 0;
        return item;
      });
      dispatch(setRewardsCumulativeChartData(res));
    } catch(error) {
      console.dir(error);
    }
  };
}

export function getCardsSoldChartData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(setCardsSoldChartDataLoading(true));
      const result: CardsSoldData[] = await getChartData("cards_sold");
      const res = result.map((item: any) => {
        const time = new Date(item.date_sold).getTime();
        item.date_sold = time;
        return item;
      });
      dispatch(setCardsSoldChartData(res));
      dispatch(setCardsSoldChartDataLoading(false));
      dispatch(setCardsSoldChartDataSuccess(true));
    } catch (error) {
      dispatch(setCardsSoldChartDataLoading(false));
      dispatch(setCardsSoldChartDataSuccess(false));
      // dispatch(setProfitsByMonthDataError((error as any).message));
      console.dir(error);
    }
  };
}

export function getPortfolioChartData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: PortfolioData[] = await getChartData("portfolio_value");
      const res = result.map((item: any) => {
        const time = new Date(item.date).getTime()/1000;
        item.date = time;
        return item;
      });
      dispatch(setPortfolioChartData(res));
    } catch(error) {
      console.dir(error);
    }
  };
}

export function getRewardAnalyzerData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: RewardAnalyzerData[] = await getTableData("rewards_analyzer");
      const res = result.map((item: any) => {
        const time = new Date(item.gameweek_date).getTime()/1000;
        item.gameweek_date = time;
        return item;
      });
      dispatch(setRewardAnalyzerData(res));
    } catch(error) {
      console.dir(error);
    }
  };
}

export function getWinningLineupsData(sorareApiId: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: WinnigLineup[] = await getWinningLineups(sorareApiId);
      // const res = result.map((item: any) => {
      //   const time = new Date(item.date_sold).getTime()/1000;
      //   item.date_sold = time;
      //   return item;
      // });
      dispatch(setWinningLineupsData(result));
    } catch(error) {
      console.dir(error);
    }
  };
}

export function getSummaryStatsData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: SummaryStatsData[] = await getChartData("summary_stats");
      const res = result.map((item: any) => {
        const time = new Date(item.month).getTime()/1000;
        item.month = time;
        return item;
      });
      dispatch(setSummaryStatsData(res));
    } catch(error) {
      console.dir(error);
    }
  };
}


export function getROIChartData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(setROIDataLoading(true));
      const result = await getChartData("roi_deposits_profits");
      const resultROI: ROIResponse[] = result;
      const roiList: ROIData[] = [];
      for (const item of resultROI) {
        const month = new Date(item.month.toString());
        const monthLabel = month.toLocaleString("default", { month: "long", year: "numeric" });
        roiList.push({
          month: month,
          label: monthLabel,
          profitEth: item.profit_eth,
          profitFiat: item.profit_fiat,
          depositsEth: item.deposits_eth,
          depositsFiat: item.deposits_fiat,
          cumulativeProfitEth: item.cumulative_profit_eth,
          cumulativeProfitFiat: item.cumulative_profit_fiat,
          cumulativeDepositsEth: item.cumulative_deposits_eth,
          cumulativeDepositsFiat: item.cumulative_deposits_fiat,
          realizedRoiEth: item.realized_roi_eth,
          realizedRoiFiat: item.realized_roi_fiat,
        });
      }
      dispatch(setROIData(roiList));
      dispatch(setROIDataLoading(false));
      dispatch(setROIDataSuccess(true));
      dispatch(setROIDataError(""));
    } catch (error) {
      dispatch(setROIDataLoading(false));
      dispatch(setROIDataSuccess(false));
      dispatch(setROIDataError((error as any).message));
      console.dir(error);
    }
  };
}

export function getTransactionsDataAsync(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(setTransactionTableDataLoading(true));
      const result = await getTableData("transaction_editor");
      const resultTransactions: TransactionsResponse[] = result;
      const list: TransactionTableRow[] = [];
      resultTransactions.map((item: any, index:number)=> {
        list.push({
          id: index+1,
          card: item.card,
          direction: item.direction,
          date: item.transaction_timestamp,
          rarity: item.rarity,
          priceEth: item.price_eth,
          priceFiat: item.price_fiat,
          valuationEth: item.valuation_eth,
          loan: item.is_loan,
          cardLink: item.card_link,
          imageUrl: item.card_picture_url,
          tokenId: item.token_id,
          sorareApiId: item.sorare_api_id,
          potentialMissingLoan: item.potential_loan,
          missingLoan: item.missing_loan,
          transferType: item.transfer_type,
          counterPartyLink: item.counter_party_link,
          counterPartyName: item.counter_party_name,
          cardSlug: item.card_slug,
          sport:item.collection_name
        });
      });
      dispatch(setTransactionTableData(list));
      dispatch(setTransactionTableDataLoading(false));
      dispatch(setTransactionTableDataSuccess(true));
      dispatch(setTransactionTableDataError(""));
    } catch (error) {
      dispatch(setTransactionTableDataLoading(false));
      dispatch(setTransactionTableDataSuccess(false));
      dispatch(setTransactionTableDataError((error as any).message));
      console.dir(error);
    }
  };
}

export function getLoansDataAsync(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    const result = await getTableData("loan_editor_indicator");
    dispatch(setLoansData(result[0]));
  };
}

export function setTransactionLoan(row: any, checked: boolean): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async(dispatch: any, getState: any) => {
    try {
      await setLoanTransaction(row, checked);
    } catch (error) {
      console.dir(error);
    }
  };
}

export function getCurrentSquadTableData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(setCurrentSquadTableDataLoading(true));
      const result = await getTableData("current_squad");
      const resultCurrentSquad: CurrentSquadResponse[] = result;
      const list: CurrentSquadTableRow[] = [];
      for (const item of resultCurrentSquad) {
        list.push({
          id: item.card_slug,
          cardTitle: item.card_title,
          cardSlug: item.card_slug,
          cardLink: item.card_link,
          pictureUrl: item.card_picture_url,
          position: item.card_position,
          club: item.current_club,
          cardSerialNumber: item.card_serial_number,
          birthDate: item.birth_date,
          playerAge: item.player_age,
          player: item.player,
          rarity: item.rarity,
          clubPictureUrl: item.current_club_picture_url,
          cardSource: item.card_source,
          dateAcquired: new Date(item.date_acquired).getTime(),
          acquisitionPriceEth: item.acquisition_price_eth,
          acquisitionPriceFiat: item.acquisition_price_fiat,
          valuationsL30D: JSON.parse(item.valuations_l_30_d),
          allTimePrice: 0,
          l7dPrice: 0,
          l30dPrice: 0,
          sport:item.collection_name
        });
      }
      dispatch(setCurrentSquadTableData(list));
      dispatch(setCurrentSquadTableDataLoading(false));
      dispatch(setCurrentSquadTableDataSuccess(true));
      dispatch(setCurrentSquadTableDataError(""));
    } catch (error) {
      dispatch(setCurrentSquadTableDataLoading(false));
      dispatch(setCurrentSquadTableDataSuccess(false));
      dispatch(setCurrentSquadTableDataError((error as any).message));
      console.dir(error);
    }
  };
}

export function getImpactPlayersTableData(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(setImpactPlayersTableDataLoading(true));
      const result = await getTableData("impact_players");
      const resultImpactPlayers: ImpactPlayersResponse[] = result;
      const list: ImpactPlayersTableRow[] = [];
      for (const item of resultImpactPlayers) {
        list.push({
          id: item.lineup_card_slug + "#" + item.thresholds_won + "#" + new Date(item.date_acquired).getTime().toString() + item.total_rewards_won,
          card: item.card_picture_url,
          lineupCardSlug: item.lineup_card_slug,
          cardRewardsWon: item.card_rewards_won,
          cashRewardsWon: item.cash_rewards_won,
          thresholdsWon: item.thresholds_won,
          totalRewardsWon: item.total_rewards_won,
          weightedRewardContribution: item.weighted_reward_contribution,
          avgRewardContribution: item.avg_reward_contribution,
          cardRewardsValuationEth: item.card_rewards_valuation_eth,
          cardRewardsValuationFiat: item.card_rewards_valuation_fiat,
          cashRewardsWonEth: item.cash_rewards_won_eth,
          cashRewardsWonFiat: item.cash_rewards_won_fiat,
          acquisitionPriceEth: item.acquisition_price_eth,
          acquisitionPriceFiat: item.acquisition_price_fiat,
          totalRewardsWonEth: item.total_rewards_won_eth,
          totalRewardsWonFiat: item.total_rewards_won_fiat,
          rewardsRoiEth: item.rewards_roi_eth,
          rewardsRoiFiat: item.rewards_roi_fiat,
          dateAcquired: item.date_acquired,
        });
      }
      dispatch(setImpactPlayersTableData(list));
      dispatch(setImpactPlayersTableDataLoading(false));
      dispatch(setImpactPlayersTableDataSuccess(true));
      dispatch(setImpactPlayersTableDataError(""));
    } catch (error) {
      dispatch(setImpactPlayersTableDataLoading(false));
      dispatch(setImpactPlayersTableDataSuccess(false));
      dispatch(setImpactPlayersTableDataError((error as any).message));
      console.dir(error);
    }
  };
}

export function createStripeSession(type:string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result = await createStripeCheckoutSession(type);
      console.log(result.url);
      location.href = result.url;
    } catch(error) {
      console.log("error", error);
    }
  };
}

export function getCustomerPortal(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result = await getCustomerPortalUrl();
      console.log(result.url);
      location.href = result.url;
    } catch(error) {
      console.log("error", error);
    }
  };
}

export function updateEmailAddress(email: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result = await updateEmail(email);
      console.log("result", result);
      //window.location.reload();
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function updateAllowSendingEmailsAsync(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result = await updateAllowSendingEmails();
      console.log("result", result);
      // window.location.reload();
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function syncLoansState(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const transactionTableData: TransactionTableRow[] = getState().tracker.transactionTableData;
      const transactionTableDataFiltered: TransactionTableRow[] = getState().tracker.transactionTableDataFiltered;
      const transactionTableDataCpy = transactionTableData.map(row => {
        const rowIdFiltered = transactionTableDataFiltered.findIndex(rowFiltered => rowFiltered.id === row.id);
        const filteredRow = transactionTableDataFiltered[rowIdFiltered];
        if (filteredRow) {
          return {
            ...filteredRow,
          };
        }
        return row;
      });
      dispatch(setTransactionTableData(transactionTableDataCpy));
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function addLoansAsync(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const rows: TransactionTableRow[] = getState().tracker.transactionTableData;
      const payload = rows.map((row) => {
        return {
          token_id: row.tokenId,
          sorare_api_id: row.sorareApiId,
          card_slug: row.cardSlug,
          is_loan: row.loan,
        };
      });
      const result = await addLoans(payload);
      console.log("result", result);
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function checkGSHeetsAsync(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result = await checkGSheets();
      for (const row of result.sheets) {
        dispatch(setExportSheetExists({sheetName: row.sheet_type, exists: true}));
        dispatch(setExportSheetLink({sheetName: row.sheet_type, link: row.sheet_link}));
        dispatch(setExportSuccess({sheetName: row.sheet_type, success: true}));
        dispatch(setExportError({sheetName: row.sheet_type, error: ""}));
        dispatch(setExportLoading({sheetName: row.sheet_type, loading: false}));
      }
    } catch (error) {
      console.log("error", error);
    }
  };
}

function openTaxReportResult(taxReportFileType: string, result: RetrieveTaxReportsResponse) {
  switch (taxReportFileType) {
  case "pdf":
    if (result.links && result.links.pdf) {
      console.log("pdf", result.links.pdf);
    }
    break;
  case "gsheets":
    if (result.links && result.links.gsheets) {
      console.log("gsheets", result.links.gsheets);
    }
    break;
  case "xlsx":
    if (result.links && result.links.xlsx) {
      console.log("xlsx", result.links.xlsx);
    }
    break;
  }
}

function finishTaxReportError(dispatch: any) {
  dispatch(setTaxExportInProgress(false));
  dispatch(setTaxExportError("Error retrieving tax report"));
  dispatch(setTaxExportSuccess(false));
  dispatch(setTaxExportDisplayMessage(true));
}

function finishTaxReport(dispatch: any) {
  dispatch(setTaxExportInProgress(false));
  dispatch(setTaxExportSuccess(true));
  dispatch(setTaxExportDisplayMessage(true));
  dispatch(setTaxExportError(""));
}

async function handleNewTaxExportJob(dispatch: any, taxReportType: string, year: number, taxReportFileType: string): Promise<void> {
  console.log("handleNewTaxExportJob");
  const result = await getTaxReport(taxReportType, year);
  if (result.status === "pending" || result.status === "in progress" || result.status === "success") {
    await waitForRefreshJob(result.job_id, checkTaxReportJob);
    const finishJobResult = await checkTaxReportJob(result.job_id);
    finishTaxReport(dispatch);
    openTaxReportResult(taxReportFileType, finishJobResult as RetrieveTaxReportsResponse);
  } else {
    finishRefreshError(dispatch);
  }
}

export function retrieveTaxReportsAsync(taxReportType: string, year: number, taxReportFileType: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const newTab = window.open("", "_blank");
  // newTab?.document.write("<h1>Loading...</h1>");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(setTaxExportInProgress(true));
      // no job id is saved in state
      await handleNewTaxExportJob(dispatch, taxReportType, year, taxReportFileType);
    } catch (error) {
      console.log("error", error);
      finishTaxReportError(dispatch);
    }
  };
}

export function getLastTaxExportAsync(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result:any = await lastTaxReport();
      const data = result.map((item:any) => {
        return {
          status: item.status,
          created_at: item.created_at,
          report_type: item.report_type,
          year: item.year,
          links: {
            gsheets: item.links.gsheets,
            pdf: item.links.pdf,
            xlsx: item.links.xlsx,
          }
        };
      });
      dispatch(setPastTaxExportResult(data));
    } catch (error) {
      console.log("error", error);
      setTaxExportResult(null);
    }
  };
}

export function updateGSHeetsAsync(gsheet: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(setExportLoading({sheetName: gsheet, loading: true}));
      // await updateGSheets(gsheet);
      await handleGSheetJob(dispatch, gsheet);
      // dispatch(setExportSuccess({sheetName: gsheet, success: true}));
      // dispatch(setExportError({sheetName: gsheet, error: ""}));
    } catch (error) {
      console.log("error", error);
      dispatch(setExportSuccess({sheetName: gsheet, success: false}));
      dispatch(setExportError({sheetName: gsheet, error: (error as any).message}));
      dispatch(setExportLoading({sheetName: gsheet, loading: false}));
    }
  };
}

function finishGSheetReport(dispatch: any, sheetName: string) {
  dispatch(setExportSuccess({sheetName: sheetName, success: true}));
  dispatch(setExportError({sheetName: sheetName, error: ""}));
  dispatch(setExportLoading({sheetName: sheetName, loading: false}));
}

async function handleGSheetJob(dispatch: any, sheetName: string): Promise<void> {
  console.log("handleGSheetsJob", sheetName);
  const result = await getGSheetsReport(sheetName);
  if (result.status === "pending" || result.status === "in progress" || result.status === "success") {
    await waitForRefreshJob(result.job_id, checkGSheetReportJob);
    // const finishJobResult = await checkGSheetReportJob(result.job_id);
    finishGSheetReport(dispatch, sheetName);
    // openTaxReportResult(taxReportFileType, finishJobResult as RetrieveTaxReportsResponse);
  } else {
    finishRefreshError(dispatch);
  }
}

export function getCurrenciesAsync(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result = await getCurrencies();
      // console.log("result", result);
      if (result.currencies) {
        dispatch(setAvailableCurrencies(result.currencies));
      }
    } catch (error) {
      console.log("error", error);
      dispatch(setAvailableCurrencies([]));
    }
  };
}

export function getCardSearchResults(title: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: CardSearchResponse[] = await getCardByTitle(title);
      dispatch(setCardSearchData(result));
    } catch (error) {
      console.log("error", error);
    }
  };
}


export function getPlayerCardSalesResults(player: string, rarity: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result = await getPlayerCardSales(player, rarity);
      const data = result.map((item: any) => {
        const date = new Date(item.transaction_timestamp).getTime()/1000;
        return {
          date: date,
          cardPictureUrl: item.card_picture_url,
          salesPriceEth: item.sales_price_eth,
          salesPriceFiat: item.sales_price_fiat,
          cardTransactionType: item.card_transaction_type,
          cardEdition: item.card_edition
        };
      });
      dispatch(setPlayerCardSalesData(data));
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getCombinedSalesValuationData(slug: string, resolve:any): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result = await getPlayerCombinedSaleValuation(slug);
      const data = result.map((item: any) => {
        const date = new Date(item.date).getTime()/1000;
        return {
          playerSlug: item.player_slug,
          date: date,
          salesTimestamp: item.sales_timestamp,
          valuationEth: item.valuation_eth,
          valuationFiat: item.valuation_fiat,
          salesPriceEth: item.sales_price_eth,
          salesPriceFiat: item.sales_price_fiat,
          cardTransactionType: item.card_transaction_type,
          cardPictureUrl: item.card_picture_url,
          transferType: item.transfer_type
        };
      });
      resolve(data);
      //dispatch(setCombinedSalesValuationData(data));
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getPlayerCardValuationsResults(player: string, rarity: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result = await getPlayerValuation(player, rarity);
      const data = result.map((item: any) => {
        const date = new Date(item.transaction_timestamp).getTime()/1000;
        return {
          date: date,
          valuationEth: item.valuation_eth,
          valuationFiat: item.valuation_fiat
        };
      });
      dispatch(setPlayerCardValuationsData(data));
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getCardDetailsSummaryData(slug: string, resolve:any): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result = await getCardSummary(slug);
      resolve(result);
      //dispatch(setCardDetailsSummary(result));
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getPersonalCardHistoryData(slug: string, resolve:any): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: PersonalCardHistoryResponse[] = await getCardHistory(slug);
      const list = result.map((item, index) => {
        return {
          ...item,
          id: index+1,
        };
      });
      dispatch(setPersonalCardHistory(list));
      resolve(list);
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getCardRewardSummary(slug: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result = await getPerformanceCardRewardSummary(slug);
      dispatch(setCardRewardSummary(result));
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getCardRewardsWon(slug: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: CardPerformanceRewardWon[] = await getPerformanceCardRewardWon(slug);
      const list = result.map((item, index) => {
        return {
          ...item,
          id: index+1,
        };
      });
      dispatch(setCardRewardsWon(list));
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getMarketDataComposition(): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(setMarketCompositionDataLoading(true));
      const result = await getMarketCompositionData("01-10-2022", "rare");
      dispatch(setMarketCompositionData(result));
      dispatch(setMarketCompositionDataLoading(false));
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getMarketDataIndex(data_type:string,sport:string,market:string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(setMarketIndexDataLoading(true));
      const result = await getMarketIndexData(data_type,sport,market);
      const res = result.map((item: any) => {
        const time = new Date(item.date).getTime()/1000;
        item.date = time;
        return item;
      });
      dispatch(setMarketIndexData(res));
      dispatch(setMarketIndexDataLoading(false));
    } catch (error) {
      console.log("error", error);
    }
  };
}
export function getAllSalesData(currency_type:string,sport:string,transfer_type:string,rarityValue:string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      dispatch(setAllSalesDataLoading(true));
      const result = await getSalesIndexData(currency_type,sport,transfer_type,rarityValue);
      const res = result.map((item: any) => {
        const time = new Date(item.date).getTime()/1000;
        item.date = time;
        return item;
      });
      dispatch(setAllSalesIndexData(res));
      dispatch(setAllSalesDataLoading(false));
    } catch (error) {
      console.log("error", error);
    }
  };
}





export function getReportSummary(month?: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: ReportsSummaryData[] = await getReport("summary", month);
      const res = result.map((item: any) => {
        const month = new Date(item.month).getTime()/1000;
        item.month = month;
        return item;
      });
      dispatch(setReportsSummaryData(res));
    } catch (error) {
      console.log("error", error);
    }
  };
}


export function getReportRewards(month?: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: ReportsRewardsData[] = await getReport("reward_table", month);
      const res = result.map((item: any) => {
        const month = new Date(item.month_in).getTime()/1000;
        item.month_in = month;
        return item;
      });
      dispatch(setReportsRewardsData(res));
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getReportTop4Rewards(month?: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: ReportsTop4RewardsData[] = await getReport("top_4_rewards", month);
      const res = result.map((item: any) => {
        const month = new Date(item.month).getTime()/1000;
        item.month = month;
        return item;
      });
      dispatch(setReportsTop4RewardsData(res));
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getReportTopFlops(month: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: ReportsTopFlopsData[] = await getReport("tops_flops", month);
      const res = result.map((item: any) => {
        const json_agg = JSON.parse(item.json_agg);
        item.json_agg = json_agg;
        return item;
      });
      dispatch(setReportsTopFlopsData(res));
    } catch (error) {
      console.log("error", error);
    }
  };
}

export function getReportTopPlayers(month: string): ThunkAction<Promise<void>, RootState, undefined, any> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: any, getState: any) => {
    try {
      const result: ReportTopPlayers[] = await getReport("top_players", month);
      const res = result.map((item: any) => {
        const month = new Date(item.date_acquired).getTime()/1000;
        item.date_acquired = month;
        return item;
      });
      dispatch(setReportTopPlayers(res));
    } catch (error) {
      console.log("error", error);
    }
  };
}
