import React from "react";
import { Typography, IconButton, Box } from "@mui/material";
import ReduxEthFiatSwitcher from "./redux-eth-fiat-switcher";
import RefreshButton from "./refresh-button";
import Hidden from "@mui/material/Hidden";
import InfoIconRound from "./../../assets/image/chart-info.svg";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setGSheetDescOpen } from "../../store/slices/tracker.slice";

interface TrackerHeaderProps {
  title: string;
}

function TrackerHeader(props: TrackerHeaderProps): JSX.Element {
  const location = useLocation();
  const dispatch = useDispatch();
  
  const showHeadBtns = location.pathname.includes("google-sheets") || location.pathname.includes("tax-reports") || location.pathname.includes("tracker/search");
  const isSearchPage = location.pathname.includes("tracker/search");
  const isMarketDataPage = location.pathname.includes("market-data");

  const containerRef = React.useRef<HTMLDivElement>(null);
  const resizeObserver = new ResizeObserver(() =>  {
    const scrollbarIsVisible = document.documentElement.offsetHeight > window.innerHeight;
    
    if (containerRef.current) {
      containerRef.current.style.paddingRight = scrollbarIsVisible ? (isSearchPage ? "20px" : "6px") : "17px";
    }
  });
  resizeObserver.observe(document.body);

  const handleDesriptionOpen = () => {
    dispatch(setGSheetDescOpen(true));
  };

  return (
    <>
      <Box sx={{
        display: "flex",
        alignItems: "center",
        padding: {
          xs: "0 20px 10px",
          sm: "28px 20px"
        },
        flexWrap: "wrap",
      }}>
        <Box sx={{
          width: {
            xs: "50%",
            sm: "25%" 
          }
        }}>
          {(!showHeadBtns) ? !location.pathname.includes("market-data") ? <ReduxEthFiatSwitcher /> : "" : ""}
        </Box>
        
        <Hidden smDown>
          <Box sx={{
            width: {
              xs: "50%",
              sm: "50%"
            },
            display: "flex",
            justifyContent: "center"
          }}>
            <Typography variant="h3" sx={{
              fontFamily: "Poppins",
              fontWeight: "bold",
              fontSize: "26px",
              lineHeight: "39px",
              textAlign: "left",
              color: "#757575",
            }}>
              {props.title}
              {location.pathname.includes("google-sheets") ? (
                <IconButton sx={{ padding: 1 }}
                  onClick={handleDesriptionOpen}>
                  <img src={InfoIconRound} />
                </IconButton>
              ) : ""}
            </Typography>
          </Box>
        </Hidden>

        <Box sx={{
          width: {
            xs: "50%",
            sm: "25%" 
          },
          display: "flex",
          justifyContent: "end"
        }}>
          {!location.pathname.includes("tracker/search") &&
              <RefreshButton />
          }
        </Box>

        <Hidden smUp>
          <Box sx={{
            width: "100%"
          }}>
            <Typography variant="h3" sx={{
              fontWeight: 700,
              fontSize: "26px",
              lineHeight: "39px",
              textAlign: "center",
              color: "#757575",
            }}>
              {props.title}
            </Typography>
          </Box>
        </Hidden>
      </Box>
    </>
  );
}

export default React.memo(TrackerHeader);
