import React from "react";  // eslint-disable-line no-unused-vars
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { ReactComponent as TwitterIcon } from "./icons/twitter-icon.svg";
import TwitterSkip from "./twitter-skip";

export default function Twitter(): JSX.Element {
  const navigate = useNavigate();

  const handleFollow = () => {
    window.open("https://twitter.com/intent/follow?user_id=1384753419626369029", "_blank");
    navigate("/tracker/home");
  };

  return (<Grid container direction="row" justifyContent="center" justifyItems="center" alignItems="center" alignContent="center" rowSpacing={2} sx={{
    marginTop: "0px",
    paddingBottom: "10px",
    paddingTop: "20px",
  }}>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Grid container direction="row" justifyContent="center" justifyItems="center" alignItems="center" alignContent="center" rowSpacing={3}>
        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
          <TwitterIcon width="40px" height="30px" />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid container direction="column" justifyContent="center" justifyItems="center" alignItems="center" alignContent="center">
            <Grid item sx={{marginBottom: "48px"}}>
              <Button variant="contained" color="primary" onClick={handleFollow} sx={{
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: "21px",
              }}>Follow us on Twitter</Button>
            </Grid>
            <TwitterSkip/>
          </Grid>
        </Grid>
        
      </Grid>
    </Grid>
  </Grid>);
}
