import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { signInAsync } from "../../store/reducers/login.reducer";
import { useReduxDispatch } from "../../store/selectors";
import { useDispatch } from "react-redux";
import { refreshDataAsync } from "../../store/reducers/tracker.reducer";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Callback(): JSX.Element {
  const query = useQuery();
  const navigate = useNavigate();
  const reduxDispatch = useReduxDispatch();
  const dispatch = useDispatch();
  useEffect(() => {
    const code = query.get("code");
    if (!code) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reduxDispatch(signInAsync(code || "")).then((_value: boolean) => {
      //dispatch(setRefreshStart("start"));
      dispatch(refreshDataAsync());
      navigate("/");
    });
  }, [query, navigate, reduxDispatch]);
  return <h2>Redirecting...</h2>;
}
