import React  from "react";
import { Modal, Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import { selectShowStartTrialModal, selectStartTrialLoading, selectIsTrialAvailable, selectSubscriptionActiveUntil } from "../../store/selectors";
import { setShowStartTrialModal } from "../../store/slices/login.slice";
import { startTrial } from "../../store/reducers/login.reducer";
import moment from "moment";
import {useTranslation} from "react-i18next";

export default function StartTrial(): JSX.Element {
  const dispatch = useDispatch();
  const show = useSelector(selectShowStartTrialModal);
  const loading = useSelector(selectStartTrialLoading);
  const isTrialAvailabale = useSelector(selectIsTrialAvailable);
  const validUntil = useSelector(selectSubscriptionActiveUntil);
  const validUntilDate = moment.utc(validUntil).format("MMMM Do");
  const { t } = useTranslation();
  
  const handleClose = () => {
    dispatch(setShowStartTrialModal(false));
  };
  
  const startTrialHandler = () => {
    dispatch(startTrial());
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      // hideBackdrop
    >
      <Box sx={style}>
        <Typography component="h6" sx={{color: "#3A48A0", fontSize: "20px", fontWeight: 700, textAlign: "center", mb: 2}}>
          {t("premium_trial")}
        </Typography>
        <Box sx={{minHeight: "66px"}}>
          {isTrialAvailabale ? (
            <>
              <Typography sx={{ fontSize: "12px", color: "#000", textAlign: "center", mb: 1.4 }}>
                {t("try_premium_text")}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#000", textAlign: "center", mb: 2.4 }}>
                {t("try_premium_desc")}
              </Typography>
            </>
          ) : (
            <Typography sx={{ fontSize: "12px", color: "#000", textAlign: "center", mb: 2.4 }}>
              {t("trial_expires_on")} <br></br>{validUntilDate}
            </Typography>
          )}
        </Box>
        <Box sx={{textAlign: "center"}}>
          {(isTrialAvailabale) ? (
            <LoadingButton
              onClick={startTrialHandler}
              loading={loading}
              sx={{
                color: "#fff",
                fontSize: "16px",
                fontWeight: 600,
                backgroundColor: "#88CBAA",
                borderRadius: "8px",
                padding: "5px 22px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#88CBAA",
                },
                "&.MuiLoadingButton-loading": {
                  backgroundColor: "#88cbaa54",
                  "& .MuiLoadingButton-loadingIndicator": {
                    color: "#88CBAA"
                  }
                }
              }}>
              {t("start_trial")}
            </LoadingButton>
          ) : (
            <LoadingButton
              onClick={handleClose}
              loading={false}
              sx={{
                color: "#fff",
                fontSize: "16px",
                fontWeight: 600,
                backgroundColor: "#FC7D69",
                borderRadius: "8px",
                padding: "5px 22px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#FC7D69",
                },
                "&.MuiLoadingButton-loading": {
                  backgroundColor: "#FC7D6954",
                  "& .MuiLoadingButton-loadingIndicator": {
                    color: "#FC7D69"
                  }
                }
              }}>
              {t("lets_go")}
            </LoadingButton>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #3A48A0",
  borderRadius: "8px",
  boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.1)",
  p: 4,
  outline: 0
};
