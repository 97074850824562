import React from "react"; // eslint-disable-line no-unused-vars
import {
  Typography,
  Box,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { useDispatch, useSelector } from "react-redux";
import { selectDropDownMarketDataValue } from "../../store/selectors";
import { setDropDownMarketValue } from "../../store/slices/tracker.slice";

export default function SportSwitcher(): JSX.Element {
  const theme = useTheme();
  const getDpValue = useSelector(selectDropDownMarketDataValue);
  // dispatch(setMarketSwitcherValue(value));
  const dispatch = useDispatch();
  const handleChangeSport = (
    event: React.MouseEvent<HTMLElement>,
    value: any
  ): void => {
    if (value) {
      dispatch(setDropDownMarketValue(value));
    }
  };
  
  const sports = ["all", "football", "nba", "baseball"];

  return (
    <Box sx={{ display: "flex" }}>
      <Typography
        sx={{ fontSize: "14px", fontWeight: 600, marginRight: "1rem" }}
      >
        SPORTS
      </Typography>
      <ToggleButtonGroup
        value={getDpValue}
        exclusive
        onChange={handleChangeSport}
        color="primary"
        fullWidth
        sx={{
          // filter: "drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.16))",
          rowGap: "5px",
          ".MuiToggleButtonGroup-grouped": {
            border: "none",
            // boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.1)",
            height: "23px",
            borderRadius: "4px !important",
            padding: "0px 15px",
            color: "#757575",
            fontWeight: 600,
            backgroundColor: "transparent",
            textTransform: "capitalize",
            fontSize: "13px",
            lineHeight: 1,
            "&:hover": {
              backgroundColor: "#839cf417",
            },

            "&.Mui-selected": {
              border: "none",
              color: theme.palette.text.secondary,
              backgroundColor: "#5173F0",
              fontWeight: 600,
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.text.secondary,
              },
            },
            "&.Mui-disabled": {
              border: "none",
              backgroundColor: theme.palette.action.disabled,
            },
          },
        }}
      >
        {sports?.map((item: any, index: any) => (
          <ToggleButton
            key={index}
            value={item}
            style={{
              width: "max-content",
              marginRight: "7px",
              textTransform: item == "nba" ? "uppercase" : "capitalize",
            }}
          >
            {item}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
}
