import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as RefreshIcon } from "./icons/refresh-icon.svg";
import { ReactComponent as RefreshArrow } from "./icons/refresh-arrow.svg";
import { selectRefreshInProgress } from "../../store/selectors";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography, Button } from "@mui/material";
import { refreshDataAsync } from "../../store/reducers/tracker.reducer";
import { setRefreshInProgress } from "../../store/slices/tracker.slice";
import {useTranslation} from "react-i18next";

export default function RefreshButton(): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const refreshInProgress = useSelector(selectRefreshInProgress);
  
  const handleClick = (): void => {
    // dispatch(setRefreshStart("start"));
    dispatch(refreshDataAsync());
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const Refresh = (props: any) => <RefreshIcon style={{
    cursor: "pointer",
    border: "none",
  }} />;

  React.useEffect(() => {
    // console.log("-> Refresh btn");
    return () => {
      window.onbeforeunload = () => {
        console.log("---> Unloaded");
        dispatch(setRefreshInProgress(false));
      };
    };
  }, []);

  const RefreshButton = () => {
    return (
      <Button onClick={handleClick} sx={{
        backgroundColor: "#FC7D69",
        borderRadius: "50px",
        color: "#fff",
        fontSize: "14px",
        textTransform: "capitalize",
        fontWeight: 400,
        lineHeight: 1,
        width: "40px",
        minWidth: "40px",
        height: "40px",
        padding: "0 !important",
        whiteSpace: "nowrap",
        transition: "all ease 0.5s",
        overflow: "hidden",
        boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.16)",
        "&:hover": {
          minWidth: "140px",
          backgroundColor: "#FC7D69",
          borderRadius: "26px",
          "& span": {
            paddingLeft: "14px",
            // marginRight: "6px",
            marginLeft: "0",
          },
          "& span:nth-of-type(2)": {
            paddingLeft: "5px",
          }
        }
      }}>
        <Box component="span" sx={{
          display: "block",
          overflow: "hidden",
          transition: "all ease 0.5s",
          marginRight: "-100%",
          marginLeft: "100%",
        }}>{t("refresh_data")}</Box>
        <Box component="span" sx={{
          display: "block",
          padding: "9px 11px",
          marginLeft: "auto !important",
          marginRight: "0 !important",
          background: "inherit",
          alignSelf: "center",
          marginBottom: "-2px",
        }}>
          <RefreshArrow style={{
            minWidth: "17px",
            height: "20px",
            backgroundColor: "inherit",
          }} />
        </Box>
      </Button>
    );
  };

  return (refreshInProgress
    ? (
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}>
        <Typography sx={{
          fontSize: "14px",
          color: "#3A48A0",
          paddingRight: "10px"
        }}>{t("refreshing")}...</Typography>
        <CircularProgress size="28px" />
      </Box>
    )
    : <RefreshButton />
  );
}
