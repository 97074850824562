/* eslint-disable @typescript-eslint/no-explicit-any */
import { environment } from "../configs/environment/environment";
import { signOutAsync } from "./reducers/login.reducer";
import { store } from "./store";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Currencies, TokenResponse, UserProfileResponse, RefreshJobResponse, StripePortalResponse, TransactionResponse, AddLoansRequest, GSHeetResponse, RefreshJobCheckResponse, RetrieveTaxReportsResponse, RetrieveTaxReportsJobResponse, LastTaxReportResponse, RetrieveGSheetReportsJobResponse, RetrieveGSheetsResponse } from "../models";


const redirectCallback: { (): void } = () => {
  store.dispatch(signOutAsync());
};
const authHeader = (config: AxiosRequestConfig) => {
  const state = store.getState();
  const token = state.signin.token;
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  };
  return config;
};

const signOut = (config: AxiosResponse<any>) => {
  if (config.request.status === 403 || config.request.status === 401) {
    // console.log("Not authorized");
    redirectCallback();
  }
  return config;
};

const signOutOnError = (error: any) => {
  // console.dir(error);
  if (error.response && (error.response.status === 403 || error.response.status === 401)) {
    // console.log("Not authorized");
    redirectCallback();
  }
  return Promise.reject(error);
};


axios.interceptors.request.use(authHeader);
axios.interceptors.response.use(signOut, signOutOnError);
axios.defaults.baseURL = environment.apiEndpoint;
// axios.defaults.headers.head = {
//   "Cache-Control": "no-cache",
//   "Pragma": "no-cache",
//   "Expires": "0",
// };


export async function signin(code: string): Promise<TokenResponse> {
  return axios.post<TokenResponse>(
    `/api/signin?code=${code}`,
  ).then(response => response.data);
}

export async function getUserProfile(): Promise<UserProfileResponse> {
  return axios.get<UserProfileResponse>(
    "/api/user",
  ).then(response => response.data);
}

export async function startFreeTrial(): Promise<any> {
  return axios.post<UserProfileResponse>(
    "/api/user/trial/activate",
  ).then(response => response.data);
}

export async function endFreeTrial(): Promise<any> {
  return axios.post<UserProfileResponse>(
    "/api/user/trial/deactivate",
  ).then(response => response.data);
}

export async function getCurrencies(): Promise<Currencies> {
  return axios.get<Currencies>(
    "/api/data/currencies",
  ).then(response => response.data);
}

export async function updateSelectedCurrency(currency: string): Promise<string> {
  return axios.put<string>(
    `/api/user/currency/${currency}`
  ).then(response => response.data);
}

export async function updateSelectedLanguage(language: string | null): Promise<string> {
  return axios.put<string>(
    `/api/user/select_language?selected_language=${language}`
  ).then(response => response.data);
}

export async function signinDirect(code: string): Promise<TokenResponse> {
  return axios.post<TokenResponse>(
    `/api/signin/direct?token=${code}`,
  ).then(response => response.data);
}

export async function checkTransactions(job_id: number): Promise<RefreshJobCheckResponse> {
  return axios.get<any>(
    `/api/jobs/transactions/${job_id}`,
  ).then(response => response.data);
}

export async function refreshTransactions(): Promise<RefreshJobResponse> {
  return axios.post<RefreshJobResponse>(
    "/api/jobs/transactions/trigger",
  ).then(response => response.data);
}

export async function getTransactions(): Promise<TransactionResponse[]> {
  return axios.get<TransactionResponse[]>(
    "/api/jobs/transactions",
  ).then(response => response.data);
}

export async function getChartData(chart: string): Promise<any> {
  return axios.get<any>(
    `/api/data/charts?chart_name=${chart}`,
  ).then(response => response.data);
}

export async function getTableData(table: string): Promise<any> {
  return axios.get<any>(
    `/api/data/tables?table_name=${table}`,
  ).then(response => response.data);
}

export async function getWinningLineups(sorareApiId: string | null): Promise<any> {
  return axios.get<any>(
    `/api/data/reward_lineup?sorare_api_id=${sorareApiId}`,
  ).then(response => response.data);
}

export async function setLoanTransaction(row: {tokenId: string, sorareApiId: string, cardSlug: string}, checked:boolean ): Promise<any> {
  return axios.put<any>("api/data/loans", [
    {
      "token_id": row.tokenId,
      "sorare_api_id": row.sorareApiId,
      "is_loan": checked,
      "card_slug": row.cardSlug,
    }
  ]).then(response => response.data);
}

export async function createStripeCheckoutSession(subscriptionType: string): Promise<StripePortalResponse> {
  return axios.post<StripePortalResponse>(
    "/api/stripe/checkout",
    {
      subscription_type: subscriptionType,
    },
  ).then(response => response.data);
}

export async function updateEmail(email: string): Promise<any> {
  return axios.put<any>(
    `/api/user/email/${email}`,
  ).then(response => response.data);
}

export async function updateAllowSendingEmails(): Promise<any> {
  return axios.put<any>(
    "/api/user/agree_to_receive_emails",
  ).then(response => response.data);
}

export async function getCustomerPortalUrl(): Promise<StripePortalResponse> {
  return axios.post<StripePortalResponse>(
    "/api/stripe/portal",
  ).then(response => response.data);
}

export async function agreeToTerms(): Promise<void> {
  return axios.put<any>(
    "/api/user/agree_to_terms",
  ).then(response => response.data);
}

export async function skipTwitter(): Promise<void> {
  return axios.put<any>(
    "/api/user/skip_twitter",
  ).then(response => response.data);
}

export async function updateGSheets(gsheet: string): Promise<void> {
  return axios.get<any>(
    `/api/data/gsheets?report_type=${gsheet}`,
  ).then(response => response.data);
}

export async function checkGSheets(): Promise<GSHeetResponse> {
  return axios.get<any>(
    "/api/data/gsheets",
  ).then(response => response.data);
}

export async function addLoans(payload: AddLoansRequest[]): Promise<void> {
  return axios.put<any>(
    "/api/data/loans",
    payload,
  ).then(response => response.data);
}

export async function getTaxReport(taxReportType: string, year: number): Promise<RetrieveTaxReportsJobResponse> {
  return axios.post<RetrieveTaxReportsJobResponse>(
    `/api/data/gsheets?report_type=${taxReportType}&year=${year}`,
  ).then(response => response.data);
}

export async function getGSheetsReport(sheetName: string): Promise<RetrieveGSheetReportsJobResponse> {
  return axios.post<RetrieveGSheetReportsJobResponse>(
    `/api/data/gsheets?report_type=${sheetName}`,
  ).then(response => response.data);
}

export async function checkTaxReportJob(jobId: number): Promise<RetrieveTaxReportsResponse | RetrieveTaxReportsJobResponse> {
  return axios.get<RetrieveTaxReportsResponse | RetrieveTaxReportsJobResponse>(
    `/api/data/gsheets/status/${jobId}`,
  ).then(response => response.data);
}

export async function checkGSheetReportJob(jobId: number): Promise<RetrieveGSheetsResponse | RetrieveGSheetReportsJobResponse> {
  return axios.get<RetrieveGSheetsResponse | RetrieveGSheetReportsJobResponse>(
    `/api/data/gsheets/status/${jobId}`,
  ).then(response => response.data);
}

export async function lastTaxReport(): Promise<LastTaxReportResponse> {
  return axios.get<LastTaxReportResponse>(
    "/api/data/gsheets/status/",
  ).then(response => response.data);
}

export async function getCardByTitle(title: string): Promise<any> {
  return axios.get<any>(
    `api/cards/?card_title=${title}`,
  ).then(response => response.data);
}

export async function getPlayerValuation(player: string, rarity: string): Promise<any> {
  return axios.get<any>(
    `api/data/valuations?player_slug=${player}&rarity=${rarity}`,
  ).then(response => response.data);
}

export async function getPlayerCardSales(player: string, rarity: string): Promise<any> {
  return axios.get<any>(
    `api/data/sales?player_slug=${player}&rarity=${rarity}`,
  ).then(response => response.data);
}

export async function getPlayerCombinedSaleValuation(slug:string): Promise<any> {
  return axios.get<any>(
    `api/cards/${slug}/combined_data`,
  ).then(response => response.data);
}

export async function getCardSummary(slug: string): Promise<any> {
  return axios.get<any>(
    `api/cards/${slug}/summary`,
  ).then(response => response.data);
}

export async function getCardHistory(slug: string): Promise<any> {
  return axios.get<any>(
    `api/cards/${slug}/history`,
  ).then(response => response.data);
}

export async function getPerformanceCardRewardSummary(slug: string): Promise<any> {
  return axios.get<any>(
    `api/cards/${slug}/reward_summary`,
  ).then(response => response.data);
}

export async function getPerformanceCardRewardWon(slug: string): Promise<any> {
  return axios.get<any>(
    `api/cards/${slug}/rewards_won`,
  ).then(response => response.data);
}

export async function getMarketCompositionData(date: string, rarity: string): Promise<any> {
  return axios.get<any>(
    `/api/data/market_data/composition?date=${date}&rarity=${rarity}`,
  ).then(response => response.data);
}

export async function getMarketIndexData(data_type: string, sport: string, market:string): Promise<any> {
  return axios.get<any>(
    // `/api/data/market_data/index?data_type=${data_type}&sport=${sport}${(market == "global" || sport != "football")? "" : `&league=${market}`}`,
    "/api/data/market_data/index", {
      params: {
        data_type: data_type,
        sport: sport !== "all" ? sport : undefined,
        ...(sport === "football" ? {league: market == "global" ? undefined : market} : "")
      }
    }
  ).then(response => response.data);
}

export async function getSalesIndexData(currency_type: string, sport: string, transfer_type:string,rarityValue:string): Promise<any> {
  return axios.get<any>(
    "/api/data/market_data/sales_data", {
      params: {
        currency: currency_type,
        transfer_type:transfer_type !== "all" ? transfer_type : undefined,
        sport: sport !== "all" ? sport : undefined,
        rarity: rarityValue !== "all" ? rarityValue : undefined,
      }
    }
  ).then(response => response.data);
}

export async function getReport(report: string, month?: string): Promise<any> {
  return axios.get<any>(
    month ? `api/data/reports?report_name=${report}&month=${month}` : `api/data/reports?report_name=${report}`,
  ).then(response => response.data);
}

export async function getApiKey(): Promise<any> {
  return axios.post<any>(
    "api/data/api_key",
  ).then(response => response.data);
}