import React from "react";  // eslint-disable-line no-unused-vars
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";
import CurrencySelect from "./currency-select";
import UpdateCurrencyButton from "./update-currency-button";
import { useSelector } from "react-redux";
import { selectNickname } from "../../store/selectors";

export default function Currency(): JSX.Element {
  const nickname = useSelector(selectNickname);
  const theme = useTheme();
  return <Grid container direction="row" justifyContent="center" justifyItems="center" alignItems="center" alignContent="center" rowSpacing={2} sx={{
    marginTop: "0px",
    paddingBottom: "40px",
    paddingTop: "20px",
  }}>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography sx={{
            fontSize: "22px",
            fontWeight: 600,
            lineHeight: "33px",
            color: "#5173F0",
          }}>
            Welcome {nickname ? nickname : "to SorareTools"}
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h3" sx={{
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "33px",
            textAlign: "center",
            color: theme.palette.info.main,
          }}>What is your preferred currency ?</Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{textAlign: "center"}}>
      <FormControl>
        <InputLabel id="currency-label">Currency</InputLabel>
        <CurrencySelect 
          style={{
            minWidth: "112px",
          }}/>
      </FormControl>
    </Grid>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}></Grid>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{textAlign: "center", paddingTop: "10px !important"}}>
      <UpdateCurrencyButton style={{padding: "5px 30px"}}>
        Submit
      </UpdateCurrencyButton>
    </Grid>
  </Grid>;
}
