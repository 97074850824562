import React from "react";  // eslint-disable-line no-unused-vars
import { Button, Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { useDispatch } from "react-redux";
import { updateSkipTwitter } from "../../store/reducers/login.reducer";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
// import { setSkippedTwitterButton } from "../login/login.slice";
import { useNavigate } from "react-router-dom";

export default function TwitterSkip(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();
  const handleSkipButton = () => {
    // dispatch(setSkippedTwitterButton(true));
    dispatch(updateSkipTwitter());
    navigate("/tracker/home");
  };
  return (
    <Box sx={{textAlign: "center"}}>
      <Button variant="contained" color="primary" onClick={handleSkipButton} sx={{
        height: "22px",
        borderRadius: "4px",
        backgroundColor: "#ffffff",
        color: theme.palette.primary.main,
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: 1,
        boxShadow: "none",
        padding: "6px 10px 6px 16px",
        "&:hover": {
          backgroundColor: "#2a388f1a",
          color: theme.palette.primary.main,
          fontWeight: 400,
          lineHeight: 1,
          boxShadow: "none",
        },
      }}>Skip <ArrowForwardIosRoundedIcon sx={{fontSize: "11px", fontWeight: 700}}/></Button>
    </Box>
  );
}
