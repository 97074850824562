import Paper from "@mui/material/Paper";
import React from "react";
import Template from "./template";

interface StartTemplateProps {
  children: React.ReactNode;
}

export default function StartTemplate(props: StartTemplateProps): JSX.Element {
  return <Template>
    <Paper sx={{
      borderRadius: "8px",
    }}>
      {props.children}
    </Paper>
  </Template>;
}
