import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TransactionTableRow,
  ProfitsByMonthData,
  CurrentSquadTableRow,
  ROIData,
  CardsSoldData,
  PortfolioData,
  RewardAnalyzerData,
  RewardsDetailsData,
  RewardsMonthlyData,
  RewardsCumulativeData,
  LastTaxReportResponse,
  SummaryStatsData,
  ImpactPlayersTableRow,
  WinnigLineup,
  CardSearchResponse,
  PlayerCardSalesResponse,
  CardDetailsSummaryResponse,
  PersonalCardHistoryResponse,
  CardPerformanceRewardSummary,
  CardPerformanceRewardWon,
  PlayerCardValuationsResponse,
  PlayerCombinedSalesValuation,
  MacroDataResponse,
  ReportsSummaryData, ReportsTop4RewardsData, ReportsTopFlopsData, ReportsRewardsData, ReportTopPlayers
} from "../../models";

export interface TrackerState {

  title: string;
  drawerOpening: boolean;
  drawerOpened: boolean;
  showCashCards: boolean;
  rewardsInCurrentSquad: boolean;
  rewardTypes: string[];
  gsheetsDescription: boolean;
  userCreatedAt: number;
  showTrialMessage: boolean;
  availableCurrencies: string[];

  currentCurrency: string;
  currentCurrencyLoading: boolean;
  currentCurrencyError: string;
  currentCurrencySuccess: boolean;

  selectedStartDate: number;
  selectedStartDateSales: number;

  selectedEndDate: number;
  selectedEndDateSales: number;

  selectedTimespan: number;
  selectedCustomTimespan: string;
  selectedCustomTimeframe: string;
  selectedCustomSalesTimes:string;

  refreshInProgress: boolean;
  refreshError: string;
  refreshErrorDisplay: boolean;
  refreshStart: string;
  refreshSuccess: boolean;
  refreshSuccessDisplay: boolean;
  refreshDataJobId: number;

  currentChart: string;
  currentAnalyzer: string;
  premiumActive: boolean;

  profitsByMonthData: ProfitsByMonthData[];
  profitsByMonthDataLoading: boolean;
  profitsByMonthDataError: string;
  profitsByMonthDataSuccess: boolean;

  withdrawalsDepositsData: any[];
  squadCompositionData: any[],
  portfolioValueByRarityData: any[],
  cardsAcquiredData: any[],
  tradingRoiCumulativeData: any[],
  portfolioRoiData: any[],
  squadCompositionByLeague: any[],
  tradingRoiByCohortChartData: any[],
  roiData: ROIData[];
  roiDataLoading: boolean;
  roiDataError: string;
  roiDataSuccess: boolean;
  chartsLoadInitialData: boolean;

  loansData: any;

  transactionTableData: TransactionTableRow[];
  transactionTableDataFiltered: TransactionTableRow[];
  transactionTableDataLoading: boolean;
  transactionTableDataError: string;
  transactionTableDataSuccess: boolean;
  transactionTableAddLoans: boolean;
  transactionTableDataEdited: boolean;
  transactionTableDataFilteredEdited: boolean;
  transactionLoadInitialData: boolean;

  currentSquadTableData: CurrentSquadTableRow[];
  currentSquadTableDataLoading: boolean;
  currentSquadTableDataError: string;
  currentSquadTableDataSuccess: boolean;

  impactPlayersTableData: ImpactPlayersTableRow[];
  impactPlayersTableDataLoading: boolean;
  impactPlayersTableDataError: string;
  impactPlayersTableDataSuccess: boolean;

  selectedExportYear: number;
  selectedExportFormat: string;
  selectedExportFileFormat: string;
  subscriptionActiveUntil: string;

  cardsSoldChartData: CardsSoldData[];
  cardsSoldChartDataLoading: boolean;
  cardsSoldChartDataError: string;
  cardsSoldChartDataSuccess: boolean;
  currentSquadLoadInitialData: boolean;

  currentSquadPercentageView: boolean,

  portfolioCardData: PortfolioData[];
  rewardAnalyzerCardData: RewardAnalyzerData[],
  summaryStatsData: SummaryStatsData[];
  winningLineupsData: WinnigLineup[];

  rewardsDetailsChartData: RewardsDetailsData[];
  rewardsMonthlyChartData: RewardsMonthlyData[];
  rewardsCumulativeChartData: RewardsCumulativeData[];

  ptExportSheetExists: boolean;
  ptExportSheetLink: string;
  ptExportError: string;
  ptExportSuccess: boolean;
  ptExportLoading: boolean;

  gwExportSheetExists: boolean;
  gwExportSheetLink: string;
  gwExportError: string;
  gwExportSuccess: boolean;
  gwExportLoading: boolean;

  taxExportInProgress: boolean;
  taxExportError: string;
  taxExportSuccess: boolean;
  taxExportDisplayMessage: boolean;
  taxExportDate: number,
  taxExportType: string,
  taxExportYear: number;
  taxExportLinks: {
    xlsx: string,
    pdf: string,
    gsheets: string
  },
  pastTaxReports: LastTaxReportResponse[],

  exportSheetExists: { [key: string]: boolean };
  exportSheetLink: { [key: string]: string };
  exportError: { [key: string]: string };
  exportSuccess: { [key: string]: boolean };
  exportLoading: { [key: string]: boolean };

  rewardPageScrollOffset: number;
  cardSearchData: CardSearchResponse[];
  playerCardSalesData: PlayerCardSalesResponse[];
  playerCardValuationsData: PlayerCardValuationsResponse[];
  cardDetailsSummary: CardDetailsSummaryResponse;
  personalCardHistory: PersonalCardHistoryResponse[];
  cardRewardSummary: CardPerformanceRewardSummary,
  cardRewardsWon: CardPerformanceRewardWon[],
  playerCombinedSalesValuation: PlayerCombinedSalesValuation[],

  reportingBaseMonth: string;
  reportingPrevMonth: string;
  
  trackerPageView: string;
  reportsSummaryData: ReportsSummaryData[];
  reportsRewardsData: ReportsRewardsData[];
  reportsTop4RewardsData: ReportsTop4RewardsData[];
  reportsTopFlopsData: ReportsTopFlopsData[];
  reportTopPlayersData: ReportTopPlayers[];

  mdChartRarity: string;
  marketCompositionData: any[];
  marketCompositionDataLoading: boolean;
  marketIndexData: any[];
  marketIndexDataLoading: boolean;
  allSalesIndexData:any[];
  allSalesDataLoading:boolean;
  marketIndexDataTypeQuery: string;
  dropDownMarketDataValue: string;
  marketSwitcherValue:string;
}

const initialState: TrackerState = {
  title: "Tracker",
  drawerOpening: false,
  drawerOpened: true,
  showCashCards: false,
  rewardsInCurrentSquad: false,
  rewardTypes: [],
  gsheetsDescription: false,
  userCreatedAt: new Date(0, 0, 0, 0, 0, 0, 0).getTime(),
  showTrialMessage: false,
  availableCurrencies: [],

  currentCurrency: "ETH",
  currentCurrencyLoading: false,
  currentCurrencyError: "",
  currentCurrencySuccess: false,

  selectedStartDate: new Date(new Date().getFullYear() - 2, new Date().getMonth(), 1, 0, 0, 0, 0).getTime(),
  selectedStartDateSales: new Date(new Date().getFullYear() - 2, new Date().getMonth(), 1, 0, 0, 0, 0).getTime(),
  selectedEndDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 0, 0, 0, 0).getTime(),
  selectedEndDateSales: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 0, 0, 0, 0).getTime(),
  selectedTimespan: new Date().getTime() - 86400000,
  selectedCustomTimespan: "",
  selectedCustomTimeframe: "",
  selectedCustomSalesTimes:"",
  

  refreshInProgress: false,
  refreshError: "",
  refreshErrorDisplay: false,
  refreshStart: "",
  refreshSuccess: false,
  refreshSuccessDisplay: false,
  refreshDataJobId: 0,

  currentChart: "",
  currentAnalyzer: "REWARDS_WON",
  premiumActive: false,

  profitsByMonthData: [],
  profitsByMonthDataLoading: false,
  profitsByMonthDataError: "",
  profitsByMonthDataSuccess: false,

  roiData: [],
  roiDataLoading: false,
  roiDataError: "",
  roiDataSuccess: false,

  chartsLoadInitialData: true,

  loansData: {
    missing_loans: 0,
    potential_loans: 0
  },

  transactionTableData: [],
  transactionTableDataFiltered: [],
  transactionTableDataLoading: false,
  transactionTableDataError: "",
  transactionTableDataSuccess: false,
  transactionTableAddLoans: false,
  transactionTableDataEdited: false,
  transactionTableDataFilteredEdited: false,
  transactionLoadInitialData: true,

  currentSquadTableData: [],
  currentSquadTableDataLoading: false,
  currentSquadTableDataError: "",
  currentSquadTableDataSuccess: false,
  currentSquadLoadInitialData: true,

  currentSquadPercentageView: false,

  impactPlayersTableData: [],
  impactPlayersTableDataLoading: false,
  impactPlayersTableDataError: "",
  impactPlayersTableDataSuccess: false,

  selectedExportYear: new Date().getFullYear(),
  selectedExportFormat: "general",
  selectedExportFileFormat: "pdf",
  subscriptionActiveUntil: new Date().toString(),

  cardsSoldChartDataLoading: false,
  cardsSoldChartDataError: "",
  cardsSoldChartDataSuccess: false,
  cardsSoldChartData:[],
  portfolioCardData: [],
  rewardAnalyzerCardData: [],
  summaryStatsData: [],
  rewardsDetailsChartData: [],
  rewardsMonthlyChartData: [],
  rewardsCumulativeChartData: [],
  winningLineupsData: [],

  ptExportSheetExists: false,
  ptExportSheetLink: "",
  ptExportError: "",
  ptExportSuccess: false,
  ptExportLoading: false,

  gwExportSheetExists: false,
  gwExportSheetLink: "",
  gwExportError: "",
  gwExportSuccess: false,
  gwExportLoading: false,

  taxExportInProgress: false,
  taxExportError: "",
  taxExportSuccess: false,
  taxExportDisplayMessage: false,
  taxExportDate: 0,
  taxExportType: "",
  taxExportYear: 0,
  taxExportLinks: {
    xlsx: "",
    pdf: "",
    gsheets: ""
  },

  pastTaxReports: [],

  exportSheetExists: {},
  exportSheetLink: {},
  exportError: {},
  exportSuccess: {},
  exportLoading: {},
  withdrawalsDepositsData: [],
  squadCompositionData: [],
  portfolioValueByRarityData: [],
  cardsAcquiredData: [],
  tradingRoiCumulativeData:[],
  portfolioRoiData:[],
  squadCompositionByLeague:[],
  tradingRoiByCohortChartData:[],

  rewardPageScrollOffset: 0,
  cardSearchData: [],
  playerCardSalesData: [],
  playerCardValuationsData: [],
  cardDetailsSummary: {} as any,
  personalCardHistory: [],
  cardRewardSummary: {} as any,
  cardRewardsWon: [],

  playerCombinedSalesValuation: [],

  reportingBaseMonth: "",
  reportingPrevMonth: "",

  trackerPageView: "charts",
  reportsSummaryData: [],
  reportsRewardsData: [],
  reportsTop4RewardsData: [],
  reportsTopFlopsData: [],
  reportTopPlayersData: [],

  mdChartRarity: "rare",
  marketCompositionData: [],
  marketCompositionDataLoading: false,
  marketIndexData: [],
  allSalesIndexData:[],
  marketIndexDataLoading: false,
  allSalesDataLoading:false,
  marketIndexDataTypeQuery: "market_index",
  dropDownMarketDataValue:"all",
  marketSwitcherValue:"global"
};

const trackerState = createSlice({
  name: "tracker",
  initialState,
  reducers: {
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setDrawerOpening: (state, action: PayloadAction<boolean>) => {
      state.drawerOpening = action.payload;
    },
    setIsDrawerOpened: (state, action: PayloadAction<boolean>) => {
      state.drawerOpened = action.payload;
    },
    setShowCashCards: (state, action: PayloadAction<boolean>) => {
      state.showCashCards = action.payload;
    },
    setRewardsInCurrentSquad: (state, action: PayloadAction<boolean>) => {
      state.rewardsInCurrentSquad = action.payload;
    },
    setGSheetDescOpen: (state, action: PayloadAction<boolean>) => {
      state.gsheetsDescription = action.payload;
    },
    setUserCreatedAt: (state, action: PayloadAction<string>) => {
      state.userCreatedAt = new Date(action.payload).getTime();
      // state.selectedStartDate = new Date(action.payload).getTime();
      state.selectedEndDate = new Date().getTime();
    },
    setShowTrialMessage: (state, action: PayloadAction<boolean>) => {
      state.showTrialMessage = action.payload;
    },
    setAvailableCurrencies: (state, action: PayloadAction<string[]>) => {
      state.availableCurrencies = action.payload;
    },
    setRewardTypes: (state, action: PayloadAction<string[]>) => {
      state.rewardTypes = action.payload;
    },

    setCurrentCurrency: (state, action: PayloadAction<string>) => {
      state.currentCurrency = action.payload;
    },
    setCurrentCurrencyLoading: (state, action: PayloadAction<boolean>) => {
      state.currentCurrencyLoading = action.payload;
    },
    setCurrentCurrencyError: (state, action: PayloadAction<string>) => {
      state.currentCurrencyError = action.payload;
    },
    setCurrentCurrencySuccess: (state, action: PayloadAction<boolean>) => {
      state.currentCurrencySuccess = action.payload;
    },
    setSelectedStartDate: (state, action: PayloadAction<Date>) => {
      const startDate = action.payload;
      startDate.setTime(new Date(startDate.getFullYear(), startDate.getMonth(), 1, 0, 0, 0, 0).getTime());
      if (startDate.getTime() > state.selectedEndDate) {
        state.selectedEndDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0, 0, 0, 0, 0).getTime();
      }
      state.selectedStartDate = startDate.getTime();
      state.selectedCustomTimespan = "";
      state.selectedCustomSalesTimes = "";

    },
    setSelectedStartDateSales: (state, action: PayloadAction<Date>) => {
      const startDate = action.payload;
      startDate.setTime(new Date(startDate.getFullYear(), startDate.getMonth(), 1, 0, 0, 0, 0).getTime());
      if (startDate.getTime() > state.selectedEndDateSales) {
        state.selectedEndDateSales = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0, 0, 0, 0, 0).getTime();
      }
      state.selectedStartDateSales = startDate.getTime();
      state.selectedCustomTimespan = "";
    },
    

    
    setSelectedEndDate: (state, action: PayloadAction<Date>) => {
      const endDate = action.payload;
      endDate.setTime(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0, 0, 0, 0, -1).getTime());
      if (endDate.getTime() > new Date().getTime() || endDate.getTime() < state.selectedStartDate) {
        endDate.setTime(new Date().getTime());
      }
      state.selectedEndDate = endDate.getTime();
      state.selectedCustomTimespan = "";
      state.selectedCustomSalesTimes = "";
    },

    setSelectedEndDateSales: (state, action: PayloadAction<Date>) => {
      const endDate = action.payload;
      endDate.setTime(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0, 0, 0, 0, -1).getTime());
      if (endDate.getTime() > new Date().getTime() || endDate.getTime() < state.selectedStartDateSales) {
        endDate.setTime(new Date().getTime());
      }
      state.selectedEndDateSales = endDate.getTime();
      state.selectedCustomTimespan = "";
      state.selectedCustomSalesTimes = "";
    },
    setSelectedCustomTimespan: (state, action: PayloadAction<string>) => {
      switch(action.payload) {
      case "1":
        // last 3 months
        {
          const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDate = startDate;
          } else {
            state.selectedStartDate = state.userCreatedAt;
          }
          state.selectedEndDate = new Date().getTime();
        }
        break;
      case "2":
        // last 6 months
        {
          const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 5, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDate = startDate;
          } else {
            state.selectedStartDate = state.userCreatedAt;
          }
          state.selectedEndDate = new Date().getTime();
        }
        break;
      case "3":
        // last 12 months
        {
          const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 11, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDate = startDate;
          } else {
            state.selectedStartDate = state.userCreatedAt;
          }
          state.selectedEndDate = new Date().getTime();
        }
        break;
      case "4":
        // this year
        {
          const startDate = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDate = startDate;
          } else {
            state.selectedStartDate = state.userCreatedAt;
          }
          state.selectedStartDate = startDate;
          state.selectedEndDate = new Date().getTime();
        }
        break;
      case "5":
        // last year
        {
          const startDate = new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDate = startDate;
          } else {
            state.selectedStartDate = state.userCreatedAt;
          }
          state.selectedEndDate = new Date(new Date().getFullYear(), 0, 0, 0, 0, 0, 1).getTime();
        }
        break;
      case "6":
        // all time
        state.selectedStartDate = state.userCreatedAt;
        state.selectedEndDate = new Date().getTime();
      }
      // console.log(action.payload);
      state.selectedCustomTimespan = action.payload;
    },

    setSelectedCustomTimeframe: (state, action: PayloadAction<string>) => {
      switch(action.payload) {
      case "1":
        // last 3 months
        {
          const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDate = startDate;
          } else {
            state.selectedStartDate = state.userCreatedAt;
          }
          state.selectedEndDate = new Date().getTime();
        }
        break;
      case "2":
        // last 6 months
        {
          const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 5, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDate = startDate;
          } else {
            state.selectedStartDate = state.userCreatedAt;
          }
          state.selectedEndDate = new Date().getTime();
        }
        break;
      case "3":
        // last 12 months
        {
          const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 11, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDate = startDate;
          } else {
            state.selectedStartDate = state.userCreatedAt;
          }
          state.selectedEndDate = new Date().getTime();
        }
        break;
      case "4":
        // this year
        {
          const startDate = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDate = startDate;
          } else {
            state.selectedStartDate = state.userCreatedAt;
          }
          // state.selectedStartDate = startDate;
          state.selectedEndDate = new Date().getTime();
        }
        break;
      case "5":
        // last year
        {
          const startDate = new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDate = startDate;
          } else {
            state.selectedStartDate = state.userCreatedAt;
          }
          state.selectedEndDate = new Date(new Date().getFullYear(), 0, 0, 0, 0, 0, 1).getTime();
        }
        break;
      case "6":
        // all time
        {
          state.selectedStartDate = state.userCreatedAt;
          state.selectedEndDate = new Date().getTime();
        }
        break;
      case "7": 
        {
          const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 0, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDate = startDate;
          } else {
            state.selectedStartDate = state.userCreatedAt;
          }
          state.selectedEndDate = new Date().getTime();
        }
        break;
      case "8": 
      {
        const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 23, 1, 0, 0, 0, 0).getTime();
        if (startDate > state.userCreatedAt) {
          state.selectedStartDate = startDate;
        } else {
          state.selectedStartDate = state.userCreatedAt;
        }
        state.selectedEndDate = new Date().getTime();
      }
      }

      
      // console.log(action.payload);
      state.selectedCustomTimeframe = action.payload;
    },
    setSelectedCustomSalesTimes: (state, action: PayloadAction<string>) => {
      switch(action.payload) {
      case "1":
        // last 3 months
        {
          const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 2, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDateSales = startDate;
          } else {
            state.selectedStartDateSales = state.userCreatedAt;
          }
          state.selectedEndDateSales = new Date().getTime();
        }
        break;
      case "2":
        // last 6 months
        {
          const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 5, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDateSales = startDate;
          } else {
            state.selectedStartDateSales = state.userCreatedAt;
          }
          state.selectedEndDateSales = new Date().getTime();
        }
        break;
      case "3":
        // last 12 months
        {
          const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 11, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDateSales = startDate;
          } else {
            state.selectedStartDateSales = state.userCreatedAt;
          }
          state.selectedEndDateSales = new Date().getTime();
        }
        break;
      case "4":
        // this year
        {
          const startDate = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDateSales = startDate;
          } else {
            state.selectedStartDateSales = state.userCreatedAt;
          }
          // state.selectedStartDate = startDate;
          state.selectedEndDateSales = new Date().getTime();
        }
        break;
      case "5":
        // last year
        {
          const startDate = new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDateSales = startDate;
          } else {
            state.selectedStartDateSales = state.userCreatedAt;
          }
          state.selectedEndDateSales = new Date(new Date().getFullYear(), 0, 0, 0, 0, 0, 1).getTime();
        }
        break;
      case "6":
        // all time
        {
          state.selectedStartDateSales = state.userCreatedAt;
          state.selectedEndDateSales = new Date().getTime();
        }
        break;
      case "7": 
        {
          const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 0, 1, 0, 0, 0, 0).getTime();
          if (startDate > state.userCreatedAt) {
            state.selectedStartDateSales = startDate;
          } else {
            state.selectedStartDateSales = state.userCreatedAt;
          }
          state.selectedEndDateSales = new Date().getTime();
        }
        break;
      case "8": 
      {
        const startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 23, 1, 0, 0, 0, 0).getTime();
        if (startDate > state.userCreatedAt) {
          state.selectedStartDateSales = startDate;
        } else {
          state.selectedStartDateSales = state.userCreatedAt;
        }
        state.selectedEndDateSales = new Date().getTime();
      }
      }

      
      // console.log(action.payload);
      state.selectedCustomSalesTimes = action.payload;
    },

    setRefreshInProgress: (state, action: PayloadAction<boolean>) => {
      state.refreshInProgress = action.payload;
    },
    setRefreshError: (state, action: PayloadAction<string>) => {
      state.refreshError = action.payload;
      state.refreshErrorDisplay = true;
    },
    setRefreshErrorDisplay: (state, action: PayloadAction<boolean>) => {
      state.refreshErrorDisplay = action.payload;
    },
    setRefreshStart: (state, action: PayloadAction<string>) => {
      state.refreshStart = action.payload;
    },
    setRefreshSuccess: (state, action: PayloadAction<boolean>) => {
      state.refreshSuccess = action.payload;
    },
    setRefreshSuccessDisplay: (state, action: PayloadAction<boolean>) => {
      state.refreshSuccessDisplay = action.payload;
    },
    setRefreshDataJobId: (state, action: PayloadAction<number>) => {
      state.refreshDataJobId = action.payload;
    },

    setCurrentChart: (state, action: PayloadAction<string>) => {
      state.currentChart = action.payload;
    },

    setCurrentAnalyzer: (state, action: PayloadAction<string>) => {
      state.currentAnalyzer = action.payload;
    },

    setProfitsByMonthData: (state, action: PayloadAction<ProfitsByMonthData[]>) => {
      state.profitsByMonthData = action.payload;
    },
    setWithdrawalsDepositsData: (state, action:PayloadAction<any[]>) => {
      state.withdrawalsDepositsData = action.payload;
    },
    setSquadCompositionData: (state, action:PayloadAction<any[]>) => {
      state.squadCompositionData = action.payload;
    },
    setPortfolioValueRarity: (state, action:PayloadAction<any[]>) => {
      state.portfolioValueByRarityData = action.payload;
    },
    setCardsAcquiredData: (state, action:PayloadAction<any[]>) => {
      state.cardsAcquiredData = action.payload;
    },
    setTradingRoiCumulativeData: (state, action:PayloadAction<any[]>) => {
      state.tradingRoiCumulativeData = action.payload;
    },
    setPortfolioRoiData: (state, action:PayloadAction<any[]>) => {
      state.portfolioRoiData = action.payload;
    },
    setSquadCompositionByLeague: (state, action:PayloadAction<any[]>) => {
      state.squadCompositionByLeague = action.payload;
    },
    setTradingRoiByCohortChartData: (state, action:PayloadAction<any[]>) => {
      state.tradingRoiByCohortChartData = action.payload;
    },
    setProfitsByMonthDataLoading: (state, action: PayloadAction<boolean>) => {
      state.profitsByMonthDataLoading = action.payload;
    },
    setProfitsByMonthDataError: (state, action: PayloadAction<string>) => {
      state.profitsByMonthDataError = action.payload;
    },
    setProfitsByMonthDataSuccess: (state, action: PayloadAction<boolean>) => {
      state.profitsByMonthDataSuccess = action.payload;
    },

    setROIData: (state, action: PayloadAction<ROIData[]>) => {
      state.roiData = action.payload;
    },
    setROIDataLoading: (state, action: PayloadAction<boolean>) => {
      state.roiDataLoading = action.payload;
    },
    setROIDataError: (state, action: PayloadAction<string>) => {
      state.roiDataError = action.payload;
    },
    setROIDataSuccess: (state, action: PayloadAction<boolean>) => {
      state.roiDataSuccess = action.payload;
    },
    setChartsLoadInitialData: (state, action: PayloadAction<boolean>) => {
      state.chartsLoadInitialData = action.payload;
    },
    setLoansData: (state, action: PayloadAction<any>) => {
      state.loansData = action.payload;
    },
    setTransactionTableData: (state, action: PayloadAction<TransactionTableRow[]>) => {
      state.transactionTableData = action.payload;
      state.transactionTableDataEdited = false;
    },
    settransactionLoadInitialData: (state, action: PayloadAction<boolean>) => {
      state.transactionLoadInitialData = action.payload;
    },
    setTransactionTableDataFiltered: (state, action: PayloadAction<TransactionTableRow[]>) => {
      state.transactionTableDataFiltered = action.payload;
      state.transactionTableDataFilteredEdited = false;
    },
    setTransactionTableDataLoading: (state, action: PayloadAction<boolean>) => {
      state.transactionTableDataLoading = action.payload;
    },
    setTransactionTableDataError: (state, action: PayloadAction<string>) => {
      state.transactionTableDataError = action.payload;
    },
    setTransactionTableDataSuccess: (state, action: PayloadAction<boolean>) => {
      state.transactionTableDataSuccess = action.payload;
    },
    setTransactionTableDataLoan: (state, action: PayloadAction<{rowId: number, checked: boolean}>) => {
      state.transactionTableData[action.payload.rowId -1].loan = action.payload.checked;
    },
    setPersonalCardDataLoan: (state, action: PayloadAction<{rowId: number, checked: boolean}>) => {
      state.personalCardHistory[action.payload.rowId -1].is_loan = action.payload.checked;
    },
    setTransactionTableDataFilteredLoan: (state, action: PayloadAction<{rowId: number, checked: boolean}>) => {
      state.transactionTableDataFiltered[action.payload.rowId].loan = action.payload.checked;
    },
    setTransactionTableAddLoans: (state, action: PayloadAction<boolean>) => {
      state.transactionTableAddLoans = action.payload;
      state.transactionTableDataFiltered = state.transactionTableData.filter(row => {
        return row.potentialMissingLoan;
      });
      state.transactionTableDataFilteredEdited = false;
    },
    setTransactionTableDataEdited: (state, action: PayloadAction<boolean>) => {
      state.transactionTableDataEdited = action.payload;
    },
    setTransactionTableDataFilteredEdited: (state, action: PayloadAction<boolean>) => {
      state.transactionTableDataFilteredEdited = action.payload;
    },

    setCurrentSquadTableData: (state, action: PayloadAction<CurrentSquadTableRow[]>) => {
      state.currentSquadTableData = action.payload;
    },
    setCurrentSquadTableDataLoading: (state, action: PayloadAction<boolean>) => {
      state.currentSquadTableDataLoading = action.payload;
    },
    setCurrentSquadTableDataError: (state, action: PayloadAction<string>) => {
      state.currentSquadTableDataError = action.payload;
    },
    setCurrentSquadTableDataSuccess: (state, action: PayloadAction<boolean>) => {
      state.currentSquadTableDataSuccess = action.payload;
    },
    setCurrentSquadLoadInitialData: (state, action: PayloadAction<boolean>) => {
      state.currentSquadLoadInitialData = action.payload;
    },
    setCurrentSquadPCTView: (state, action: PayloadAction<boolean>) => {
      state.currentSquadPercentageView = action.payload;
    },

    setImpactPlayersTableData: (state, action: PayloadAction<ImpactPlayersTableRow[]>) => {
      state.impactPlayersTableData = action.payload;
    },
    setImpactPlayersTableDataLoading: (state, action: PayloadAction<boolean>) => {
      state.impactPlayersTableDataLoading = action.payload;
    },
    setImpactPlayersTableDataError: (state, action: PayloadAction<string>) => {
      state.impactPlayersTableDataError = action.payload;
    },
    setImpactPlayersTableDataSuccess: (state, action: PayloadAction<boolean>) => {
      state.impactPlayersTableDataSuccess = action.payload;
    },

    setSelectedExportYear: (state, action: PayloadAction<number>) => {
      state.selectedExportYear = action.payload;
    },
    setSelectedExportFormat: (state, action: PayloadAction<string>) => {
      state.selectedExportFormat = action.payload;
    },
    setSelectedExportFileFormat: (state, action: PayloadAction<string>) => {
      state.selectedExportFileFormat = action.payload;
    },
    setSubscriptionActiveUntil: (state, action: PayloadAction<Date>) => {
      state.subscriptionActiveUntil = action.payload.toString();
    },

    setCardsSoldChartDataLoading: (state, action: PayloadAction<boolean>) => {
      state.cardsSoldChartDataLoading = action.payload;
    },
    setCardsSoldChartData: (state, action:PayloadAction<CardsSoldData[]>) => {
      state.cardsSoldChartData = action.payload;
    },
    setCardsSoldChartDataSuccess: (state, action:PayloadAction<boolean>) => {
      state.cardsSoldChartDataSuccess = action.payload;
    },

    setPortfolioChartData: (state, action:PayloadAction<PortfolioData[]>) => {
      state.portfolioCardData = action.payload;
    },

    setRewardAnalyzerData: (state, action:PayloadAction<RewardAnalyzerData[]>) => {
      state.rewardAnalyzerCardData = action.payload;
    },

    setWinningLineupsData: (state, action:PayloadAction<WinnigLineup[]>) => {
      state.winningLineupsData = action.payload;
    },

    setSummaryStatsData: (state, action:PayloadAction<SummaryStatsData[]>) => {
      state.summaryStatsData = action.payload;
    },

    setRewardsDetailsChartData: (state, action:PayloadAction<RewardsDetailsData[]>) => {
      state.rewardsDetailsChartData = action.payload;
    },
    setRewardsMonthlyChartData: (state, action:PayloadAction<RewardsMonthlyData[]>) => {
      state.rewardsMonthlyChartData = action.payload;
    },
    setRewardsCumulativeChartData: (state, action:PayloadAction<RewardsCumulativeData[]>) => {
      state.rewardsCumulativeChartData = action.payload;
    },

    setPTExportSheetExists: (state, action:PayloadAction<boolean>) => {
      state.ptExportSheetExists = action.payload;
    },
    setPTExportSheetLink: (state, action:PayloadAction<string>) => {
      state.ptExportSheetLink = action.payload;
    },
    setPTExportError: (state, action:PayloadAction<string>) => {
      state.ptExportError = action.payload;
    },
    setPTExportSuccess: (state, action:PayloadAction<boolean>) => {
      state.ptExportSuccess = action.payload;
    },
    setPTExportLoading: (state, action:PayloadAction<boolean>) => {
      state.ptExportLoading = action.payload;
    },

    setGWExportSheetExists: (state, action:PayloadAction<boolean>) => {
      state.gwExportSheetExists = action.payload;
    },
    setGWExportSheetLink: (state, action:PayloadAction<string>) => {
      state.gwExportSheetLink = action.payload;
    },
    setGWExportError: (state, action:PayloadAction<string>) => {
      state.gwExportError = action.payload;
    },
    setGWExportSuccess: (state, action:PayloadAction<boolean>) => {
      state.gwExportSuccess = action.payload;
    },
    setGWExportLoading: (state, action:PayloadAction<boolean>) => {
      state.gwExportLoading = action.payload;
    },

    setTaxExportInProgress: (state, action:PayloadAction<boolean>) => {
      state.taxExportInProgress = action.payload;
    },
    setTaxExportError: (state, action:PayloadAction<string>) => {
      state.taxExportError = action.payload;
    },
    setTaxExportSuccess: (state, action:PayloadAction<boolean>) => {
      state.taxExportSuccess = action.payload;
    },
    setTaxExportDisplayMessage: (state, action:PayloadAction<boolean>) => {
      state.taxExportDisplayMessage = action.payload;
    },
    setTaxExportResult: (state, action:PayloadAction<LastTaxReportResponse | null>) => {
      if (action.payload) {
        state.taxExportDate = new Date(action.payload.created_at).getTime();
        state.taxExportType = action.payload.report_type;
        state.taxExportYear = action.payload.year;
        state.taxExportLinks = action.payload.links;
      }
    },

    setPastTaxExportResult: (state, action:PayloadAction<LastTaxReportResponse[]>) => {
      state.pastTaxReports = action.payload;
    },

    setExportSheetExists: (state, action:PayloadAction<{sheetName: string, exists: boolean}>) => {
      const sheetName = action.payload.sheetName;
      const exists = action.payload.exists;
      const exportSheetExists = {...state.exportSheetExists};
      exportSheetExists[sheetName] = exists;
      state.exportSheetExists = exportSheetExists;
    },
    setExportSheetLink: (state, action:PayloadAction<{sheetName: string, link: string}>) => {
      const sheetName = action.payload.sheetName;
      const link = action.payload.link;
      const exportSheetLink = {...state.exportSheetLink};
      exportSheetLink[sheetName] = link;
      state.exportSheetLink = exportSheetLink;
    },
    setExportError: (state, action:PayloadAction<{sheetName: string, error: string}>) => {
      const sheetName = action.payload.sheetName;
      const error = action.payload.error;
      const exportError = {...state.exportError};
      exportError[sheetName] = error;
      state.exportError = exportError;
    },
    setExportSuccess: (state, action:PayloadAction<{sheetName: string, success: boolean}>) => {
      const sheetName = action.payload.sheetName;
      const success = action.payload.success;
      const exportSuccess = {...state.exportSuccess};
      exportSuccess[sheetName] = success;
      state.exportSuccess = exportSuccess;
    },
    setExportLoading: (state, action:PayloadAction<{sheetName: string, loading: boolean}>) => {
      const sheetName = action.payload.sheetName;
      const loading = action.payload.loading;
      const exportLoading = {...state.exportLoading};
      exportLoading[sheetName] = loading;
      state.exportLoading = exportLoading;
    },
    setRewardPageScrollOffset: (state, action:PayloadAction<number>) => {
      state.rewardPageScrollOffset = action.payload;
    },
    setCardSearchData: (state, action:PayloadAction<CardSearchResponse[]>) => {
      state.cardSearchData = action.payload;
    },
    setPlayerCardSalesData: (state, action:PayloadAction<PlayerCardSalesResponse[]>) => {
      state.playerCardSalesData = action.payload;
    },
    setPlayerCardValuationsData: (state, action:PayloadAction<PlayerCardValuationsResponse[]>) => {
      state.playerCardValuationsData = action.payload;
    },
    setCombinedSalesValuationData: (state, action:PayloadAction<PlayerCombinedSalesValuation[]>) => {
      state.playerCombinedSalesValuation = action.payload;
    },
    setCardDetailsSummary: (state, action:PayloadAction<CardDetailsSummaryResponse>) => {
      state.cardDetailsSummary = action.payload;
    },
    setPersonalCardHistory: (state, action:PayloadAction<PersonalCardHistoryResponse[]>) => {
      state.personalCardHistory = action.payload;
    },
    setCardRewardSummary: (state, action:PayloadAction<CardPerformanceRewardSummary>) => {
      state.cardRewardSummary = action.payload;
    },
    setCardRewardsWon: (state, action:PayloadAction<CardPerformanceRewardWon[]>) => {
      state.cardRewardsWon = action.payload;
    },
    
    setReportingBaseMonth: (state, action:PayloadAction<string>) => {
      state.reportingBaseMonth = action.payload;
    },
    setReportingPrevMonth: (state, action:PayloadAction<string>) => {
      state.reportingPrevMonth = action.payload;
    },

    setTrackerView: (state, action:PayloadAction<string>) => {
      state.trackerPageView = action.payload;
    },
    setReportsSummaryData: (state, action:PayloadAction<ReportsSummaryData[]>) => {
      state.reportsSummaryData = action.payload;
    },
    setReportsRewardsData: (state, action:PayloadAction<ReportsRewardsData[]>) => {
      state.reportsRewardsData = action.payload;
    },
    setReportsTop4RewardsData: (state, action:PayloadAction<ReportsTop4RewardsData[]>) => {
      state.reportsTop4RewardsData = action.payload;
    },
    setReportsTopFlopsData: (state, action:PayloadAction<ReportsTopFlopsData[]>) => {
      state.reportsTopFlopsData = action.payload;
    },
    setReportTopPlayers: (state, action:PayloadAction<ReportTopPlayers[]>) => {
      state.reportTopPlayersData = action.payload;
    },

    setMDChartRarity: (state, action: PayloadAction<string>) => {
      state.mdChartRarity = action.payload;
    },
    setMarketCompositionData: (state, action:PayloadAction<any[]>) => {
      state.marketCompositionData = action.payload;
    },
    setMarketCompositionDataLoading: (state, action:PayloadAction<boolean>) => {
      state.marketCompositionDataLoading = action.payload;
    },
    setMarketIndexData: (state, action:PayloadAction<any[]>) => {
      state.marketIndexData = action.payload;
    },
    setMarketIndexDataLoading: (state, action:PayloadAction<boolean>) => {
      state.marketIndexDataLoading = action.payload;
    },
    setAllSalesIndexData: (state, action:PayloadAction<any[]>) => {
      state.allSalesIndexData = action.payload;
    },
    setAllSalesDataLoading: (state, action:PayloadAction<boolean>) => {
      state.allSalesDataLoading = action.payload;
    },
    setMarketIndexDataTypeQuery: (state, action:PayloadAction<string>) => {
      state.marketIndexDataTypeQuery = action.payload;
    },
    setDropDownMarketDataValue: (state, action:PayloadAction<string>) => {
      state.dropDownMarketDataValue = action.payload;
    },
    setMarketSwitcherValue: (state, action:PayloadAction<string>) => {
      state.marketSwitcherValue = action.payload;
    },
  }
});

export const setTitle = trackerState.actions.setTitle;
export const setDrawerOpening = trackerState.actions.setDrawerOpening;
export const setIsDrawerOpened = trackerState.actions.setIsDrawerOpened;
export const setShowCashCards = trackerState.actions.setShowCashCards;
export const setRewardsInCurrentSquad = trackerState.actions.setRewardsInCurrentSquad;
export const setGSheetDescOpen = trackerState.actions.setGSheetDescOpen;
export const setUserCreatedAt = trackerState.actions.setUserCreatedAt;
export const setShowTrialMessage = trackerState.actions.setShowTrialMessage;
export const setAvailableCurrencies = trackerState.actions.setAvailableCurrencies;
export const setRewardTypes = trackerState.actions.setRewardTypes;

export const setCurrentCurrency = trackerState.actions.setCurrentCurrency;
export const setCurrentCurrencyLoading = trackerState.actions.setCurrentCurrencyLoading;
export const setCurrentCurrencyError = trackerState.actions.setCurrentCurrencyError;
export const setCurrentCurrencySuccess = trackerState.actions.setCurrentCurrencySuccess;
export const setSelectedStartDate = trackerState.actions.setSelectedStartDate;
export const setSelectedStartDateSales = trackerState.actions.setSelectedStartDateSales;
export const setSelectedEndDate = trackerState.actions.setSelectedEndDate;
export const setSelectedEndDateSales = trackerState.actions.setSelectedEndDateSales;
export const setSelectedCustomTimespan = trackerState.actions.setSelectedCustomTimespan;
export const setSelectedCustomTimeframe = trackerState.actions.setSelectedCustomTimeframe;
export const setSelectedCustomSalesTimes = trackerState.actions.setSelectedCustomSalesTimes;




export const setRefreshInProgress = trackerState.actions.setRefreshInProgress;
export const setRefreshError = trackerState.actions.setRefreshError;
export const setRefreshErrorDisplay = trackerState.actions.setRefreshErrorDisplay;
export const setRefreshStart = trackerState.actions.setRefreshStart;
export const setRefreshSuccess = trackerState.actions.setRefreshSuccess;
export const setRefreshSuccessDisplay = trackerState.actions.setRefreshSuccessDisplay;
export const setRefreshDataJobId = trackerState.actions.setRefreshDataJobId;

export const setCurrentChart = trackerState.actions.setCurrentChart;
export const setCurrentAnalyzer = trackerState.actions.setCurrentAnalyzer;

export const setProfitsByMonthData = trackerState.actions.setProfitsByMonthData;
export const setProfitsByMonthDataLoading = trackerState.actions.setProfitsByMonthDataLoading;
export const setProfitsByMonthDataError = trackerState.actions.setProfitsByMonthDataError;
export const setProfitsByMonthDataSuccess = trackerState.actions.setProfitsByMonthDataSuccess;

export const setROIData = trackerState.actions.setROIData;
export const setROIDataLoading = trackerState.actions.setROIDataLoading;
export const setROIDataError = trackerState.actions.setROIDataError;
export const setROIDataSuccess = trackerState.actions.setROIDataSuccess;

export const setWithdrawalsDepositsData = trackerState.actions.setWithdrawalsDepositsData;
export const setSquadCompositionData = trackerState.actions.setSquadCompositionData;
export const setPortfolioValueRarity = trackerState.actions.setPortfolioValueRarity;
export const setCardsAcquiredData = trackerState.actions.setCardsAcquiredData;
export const setTradingRoiCumulativeData = trackerState.actions.setTradingRoiCumulativeData;
export const setPortfolioRoiData = trackerState.actions.setPortfolioRoiData;
export const setSquadCompositionByLeague = trackerState.actions.setSquadCompositionByLeague;
export const setTradingRoiByCohortChartData = trackerState.actions.setTradingRoiByCohortChartData;

export const setChartsLoadInitialData = trackerState.actions.setChartsLoadInitialData;

export const setTransactionTableData = trackerState.actions.setTransactionTableData;
// export const setTransactionTableDataFiltered = trackerState.actions.setTransactionTableDataFiltered;
export const setTransactionTableDataLoading = trackerState.actions.setTransactionTableDataLoading;
export const setTransactionTableDataError = trackerState.actions.setTransactionTableDataError;
export const setTransactionTableDataSuccess = trackerState.actions.setTransactionTableDataSuccess;
export const setTransactionTableDataLoan = trackerState.actions.setTransactionTableDataLoan;
export const setPersonalCardDataLoan = trackerState.actions.setPersonalCardDataLoan;
export const settransactionLoadInitialData = trackerState.actions.settransactionLoadInitialData;
// export const setTransactionTableDataFilteredLoan = trackerState.actions.setTransactionTableDataFilteredLoan;
export const setTransactionTableAddLoans = trackerState.actions.setTransactionTableAddLoans;

export const setLoansData = trackerState.actions.setLoansData;

export const setCurrentSquadTableData = trackerState.actions.setCurrentSquadTableData;
export const setCurrentSquadTableDataLoading = trackerState.actions.setCurrentSquadTableDataLoading;
export const setCurrentSquadTableDataError = trackerState.actions.setCurrentSquadTableDataError;
export const setCurrentSquadTableDataSuccess = trackerState.actions.setCurrentSquadTableDataSuccess;
export const setCurrentSquadPCTView = trackerState.actions.setCurrentSquadPCTView;
export const setCurrentSquadLoadInitialData = trackerState.actions.setCurrentSquadLoadInitialData;

export const setImpactPlayersTableData = trackerState.actions.setImpactPlayersTableData;
export const setImpactPlayersTableDataLoading = trackerState.actions.setImpactPlayersTableDataLoading;
export const setImpactPlayersTableDataError = trackerState.actions.setImpactPlayersTableDataError;
export const setImpactPlayersTableDataSuccess = trackerState.actions.setImpactPlayersTableDataSuccess;

export const setSelectedExportYear = trackerState.actions.setSelectedExportYear;
export const setSelectedExportFormat = trackerState.actions.setSelectedExportFormat;
export const setSelectedExportFileFormat = trackerState.actions.setSelectedExportFileFormat;
// export const setSubscriptionActiveUntil = trackerState.actions.setSubscriptionActiveUntil;
export const setCardsSoldChartDataLoading = trackerState.actions.setCardsSoldChartDataLoading;
export const setCardsSoldChartData = trackerState.actions.setCardsSoldChartData;
export const setCardsSoldChartDataSuccess = trackerState.actions.setCardsSoldChartDataSuccess;
export const setPortfolioChartData = trackerState.actions.setPortfolioChartData;
export const setRewardAnalyzerData = trackerState.actions.setRewardAnalyzerData;
export const setWinningLineupsData = trackerState.actions.setWinningLineupsData;
export const setSummaryStatsData = trackerState.actions.setSummaryStatsData;
export const setRewardsDetailsChartData = trackerState.actions.setRewardsDetailsChartData;
export const setRewardsMonthlyChartData = trackerState.actions.setRewardsMonthlyChartData;
export const setRewardsCumulativeChartData = trackerState.actions.setRewardsCumulativeChartData;

// export const setPTExportSheetExists = trackerState.actions.setPTExportSheetExists;
// export const setPTExportSheetLink = trackerState.actions.setPTExportSheetLink;
// export const setPTExportError = trackerState.actions.setPTExportError;
// export const setPTExportSuccess = trackerState.actions.setPTExportSuccess;
// export const setPTExportLoading = trackerState.actions.setPTExportLoading;

// export const setGWExportSheetExists = trackerState.actions.setGWExportSheetExists;
// export const setGWExportSheetLink = trackerState.actions.setGWExportSheetLink;
// export const setGWExportError = trackerState.actions.setGWExportError;
// export const setGWExportSuccess = trackerState.actions.setGWExportSuccess;
// export const setGWExportLoading = trackerState.actions.setGWExportLoading;

export const setTaxExportInProgress = trackerState.actions.setTaxExportInProgress;
export const setTaxExportError = trackerState.actions.setTaxExportError;
export const setTaxExportSuccess = trackerState.actions.setTaxExportSuccess;
export const setTaxExportDisplayMessage = trackerState.actions.setTaxExportDisplayMessage;
export const setTaxExportResult = trackerState.actions.setTaxExportResult;

export const setPastTaxExportResult = trackerState.actions.setPastTaxExportResult;

export const setExportSheetExists = trackerState.actions.setExportSheetExists;
export const setExportSheetLink = trackerState.actions.setExportSheetLink;
export const setExportError = trackerState.actions.setExportError;
export const setExportSuccess = trackerState.actions.setExportSuccess;
export const setExportLoading = trackerState.actions.setExportLoading;

export const setRewardPageScrollOffset = trackerState.actions.setRewardPageScrollOffset;
export const setCardSearchData = trackerState.actions.setCardSearchData;
export const setPlayerCardSalesData = trackerState.actions.setPlayerCardSalesData;
export const setPlayerCardValuationsData = trackerState.actions.setPlayerCardValuationsData;
export const setCombinedSalesValuationData = trackerState.actions.setCombinedSalesValuationData;
export const setCardDetailsSummary = trackerState.actions.setCardDetailsSummary;
export const setPersonalCardHistory = trackerState.actions.setPersonalCardHistory;
export const setCardRewardSummary = trackerState.actions.setCardRewardSummary;
export const setCardRewardsWon = trackerState.actions.setCardRewardsWon;
export const setReportingBaseMonth = trackerState.actions.setReportingBaseMonth;
export const setReportingPrevMonth = trackerState.actions.setReportingPrevMonth;

export const setTrackerView = trackerState.actions.setTrackerView;
export const setReportsSummaryData = trackerState.actions.setReportsSummaryData;
export const setReportsRewardsData = trackerState.actions.setReportsRewardsData;
export const setReportsTop4RewardsData = trackerState.actions.setReportsTop4RewardsData;
export const setReportsTopFlopsData = trackerState.actions.setReportsTopFlopsData;
export const setReportTopPlayers = trackerState.actions.setReportTopPlayers;

export const setMarketCompositionData = trackerState.actions.setMarketCompositionData;
export const setMarketCompositionDataLoading = trackerState.actions.setMarketCompositionDataLoading;
export const setMarketIndexData = trackerState.actions.setMarketIndexData;
export const setAllSalesIndexData = trackerState.actions.setAllSalesIndexData;
export const setMarketIndexDataLoading = trackerState.actions.setMarketIndexDataLoading;
export const setAllSalesDataLoading = trackerState.actions.setAllSalesDataLoading;
export const setMarketDataQuery = trackerState.actions.setMarketIndexDataTypeQuery;
export const setDropDownMarketValue = trackerState.actions.setDropDownMarketDataValue;
export const setMarketSwitcherValue = trackerState.actions.setMarketSwitcherValue;
export const setMDChartRarity = trackerState.actions.setMDChartRarity;

export default trackerState.reducer;
