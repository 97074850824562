import React from "react";  // eslint-disable-line no-unused-vars
import { useDispatch, useSelector } from "react-redux";
import { selectAvailableCurrencies, selectCurrency } from "../../store/selectors";
import { setCurrency } from "../../store/slices/login.slice";
import CustomSelect from "../../components/custom-select";
import StyledComboboxOption from "../../components/styled-combobox-option";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { getCurrenciesAsync } from "../../store/reducers/tracker.reducer";
import {useTranslation} from "react-i18next";

interface CurrencySelectProps {
  style?: any;
}

// eslint-disable-next-line import/no-unused-modules
export default function CurrencySelect(props:CurrencySelectProps): JSX.Element {
  const currency = useSelector(selectCurrency);
  const availableCurrencies = useSelector(selectAvailableCurrencies);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = (value: string | null) => {
    if (value) {
      dispatch(setCurrency(value));
    }
  };

  React.useEffect(() => {
    dispatch(getCurrenciesAsync());
  }, [dispatch]);

  const currencyOptions = availableCurrencies?.map((currency) => {
    return <StyledComboboxOption key={currency} value={currency}>{currency}</StyledComboboxOption>;
  });

  return <FormControl>
    <InputLabel id="currency-label">{t("settings_page_currency.currency_title")}</InputLabel>
    <CustomSelect
      value={currency}
      onChange={handleChange}
      buttonProps={{
        style: {
          fontSize: "13px",
          padding: "10px",
          minWidth: "154px",
          zIndex: 5,
          ...props.style
        }
      }}
      listboxProps={{
        style: {
          minWidth: "154px",
          "& li": {
            color: "red",
          },
          ...props.style
        }
      }}
      popperProps={{
        style: {
          zIndex: 4
        }
      }}
      
    >
      {currencyOptions}
    </CustomSelect>
  </FormControl>;
}
