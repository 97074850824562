import grey from "@mui/material/colors/grey";
import styled from "@mui/material/styles/styled";

export default styled("ul")(
  ({ theme }) => `
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 0;
  z-index: 1;
  position: relative;
  min-width: 100px;
  max-height: 200px;
  background: ${theme.palette.background.paper};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  padding-top: 20px;
  margin-top: -15px;
  `,
);