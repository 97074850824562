import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserProfileResponse } from "../../models";
import CurrencySign from "../../pages/login/currency";

export interface LoginState {
  directCode: string;
  token: string;
  loggedIn: boolean;
  acceptedTerms: boolean;
  userCreatedAt: string;
  currency: string;
  currencySign: string;
  currencySelected: boolean;
  emailAddress: string;
  allowSendingEmails: boolean;
  skippedTwitterButton: boolean;
  loading: boolean;
  subscriptionActiveUntil: number;
  subscriptionActive: boolean;
  subscriptionStatus: string;
  nickname: string;
  userProfileLoading: boolean;
  selectedLanguage: string | null;
  isTrialAvailable: boolean;
  showStartTrialModal: boolean;
  startFreeTrialLoading: boolean;
}

const initialState: LoginState = {
  directCode: "",
  token: "",
  loggedIn: false,
  acceptedTerms: false,
  userCreatedAt: "",
  currency: "",
  currencySign: "",
  currencySelected: false,
  emailAddress: "",
  allowSendingEmails: false,
  skippedTwitterButton: false,
  loading: false,
  subscriptionActiveUntil: new Date(0, 0, 0, 0, 0, 0, 0).getTime(),
  subscriptionActive: false,
  subscriptionStatus: "expired",
  nickname: "",
  userProfileLoading: false,
  selectedLanguage: null,
  isTrialAvailable: false,
  showStartTrialModal: false,
  startFreeTrialLoading: false,
};

const signInSlice = createSlice({
  name: "signin",
  initialState,
  reducers: {
    setDirectCode: (state, action: PayloadAction<string>) => {
      state.directCode = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.loggedIn = (action.payload && action.payload.length > 0) || false;
    },
    setAcceptedTerms: (state, action: PayloadAction<boolean>) => {
      state.acceptedTerms = action.payload;
    },
    setCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
      state.currencySign = CurrencySign(action.payload);
    },
    setCurrencySelected: (state, action: PayloadAction<boolean>) => {
      state.currencySelected = action.payload;
    },
    setEmailAddress: (state, action: PayloadAction<string>) => {
      state.emailAddress = action.payload;
    },
    setAllowSendingEmails: (state, action: PayloadAction<boolean>) => {
      state.allowSendingEmails = action.payload;
    },
    setSkippedTwitterButton: (state, action: PayloadAction<boolean>) => {
      state.skippedTwitterButton = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logout: (state, _action: PayloadAction<string>) => {
      state.token = "";
      state.loggedIn = false;
    },
    setUserProfile: (state, action: PayloadAction<UserProfileResponse>) => {
      state.currency = action.payload.selected_currency;
      if (action.payload.selected_currency) {
        state.currencySelected = true;
      } else {
        state.currencySelected = false;
        state.currency = "EUR";
      }
      state.currencySign = CurrencySign(action.payload.selected_currency);
      state.emailAddress = action.payload.email;
      state.selectedLanguage = action.payload.selected_language;
      state.subscriptionActiveUntil = new Date(action.payload.valid_until).getTime();
      state.subscriptionActive = state.subscriptionActiveUntil > new Date().getTime();
      state.subscriptionStatus = action.payload.subscription_status;
      state.nickname = action.payload.nickname;
      state.skippedTwitterButton = action.payload.skipped_twitter;
      state.acceptedTerms = action.payload.agreed_to_terms;
      state.userCreatedAt = action.payload.user_created_at;
      state.isTrialAvailable = action.payload.trial_available;
    },
    setUserProfileLoading: (state, action: PayloadAction<boolean>) => {
      state.userProfileLoading = action.payload;
    },
    setUserProfileLanguage: (state, action: PayloadAction<null | string>) => {
      state.selectedLanguage = action.payload;
    },
    setShowStartTrialModal: (state, action: PayloadAction<boolean>) => {
      state.showStartTrialModal = action.payload;
    },
    setStartTrialLoading: (state, action: PayloadAction<boolean>) => {
      state.startFreeTrialLoading = action.payload;
    },
  },
});

export const setDirectCode = signInSlice.actions.setDirectCode;
export const setToken = signInSlice.actions.setToken;
export const setAcceptedTerms = signInSlice.actions.setAcceptedTerms;
export const setCurrency = signInSlice.actions.setCurrency;
export const setCurrencySelected = signInSlice.actions.setCurrencySelected;
export const setEmailAddress = signInSlice.actions.setEmailAddress;
export const setAllowSendingEmails = signInSlice.actions.setAllowSendingEmails;
// export const setSkippedTwitterButton = signInSlice.actions.setSkippedTwitterButton;
// export const logout = signInSlice.actions.logout;
export const setUserProfile = signInSlice.actions.setUserProfile;
export const setUserProfileLoading = signInSlice.actions.setUserProfileLoading;
export const setUserProfileLanguage = signInSlice.actions.setUserProfileLanguage;
export const setShowStartTrialModal = signInSlice.actions.setShowStartTrialModal;
export const setStartTrialLoading = signInSlice.actions.setStartTrialLoading;

const SignInSlice = signInSlice.reducer;
export default SignInSlice;
