import { Environment } from "./environment.interface";

export class environment implements Environment {
  production = false;
  //apiEndpoint: string = "https://soraretax.herokuapp.com/";
  //apiEndpoint = "https://soraretools.herokuapp.com/";
  //apiEndpoint: string = "http://127.0.0.1:3000/";
  apiEndpoint = window.location.origin;
  //apiEndpoint = "https://app.soraretools.com/";
}
