/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, createSelector, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { 
  TransactionTableRow, 
  ProfitsByMonthData, 
  CurrentSquadTableRow, 
  ROIData, 
  CardsSoldData, 
  PortfolioData, 
  RewardsDetailsData, 
  RewardsMonthlyData, 
  RewardsCumulativeData, 
  SummaryStatsData, 
  RewardAnalyzerData, 
  ImpactPlayersTableRow, 
  WinnigLineup, 
  LastTaxReportResponse, 
  CardSearchResponse,
  PlayerCardSalesResponse, 
  CardDetailsSummaryResponse, 
  PersonalCardHistoryResponse, 
  CardPerformanceRewardSummary, 
  CardPerformanceRewardWon, 
  PlayerCardValuationsResponse, 
  PlayerCombinedSalesValuation, 
  ReportsSummaryData, ReportsTop4RewardsData, ReportsTopFlopsData, ReportsRewardsData, ReportTopPlayers, MacroDataResponse } from "../models";
import { RootState } from "./store";

export const selectDirectCode = (state: RootState): string => state.signin.directCode;
// export const selectLoggedIn = (state: RootState): boolean => state.signin.loggedIn;
export const selectToken = (state: RootState): string => state.signin.token;
export const selectAcceptedTerms = (state: RootState): boolean => state.signin.acceptedTerms;
export const selectUserCreatedAt = (state: RootState): string => state.signin.userCreatedAt;
export const selectCurrency = (state: RootState): string => state.signin.currency;
export const selectCurrencySelected = (state: RootState): boolean => state.signin.currencySelected;
export const selectCurrencySign = (state: RootState): string => state.signin.currencySign;
export const selectEmailAddress = (state: RootState): string => state.signin.emailAddress;
export const selectUserLanguage = (state: RootState): string | null => state.signin.selectedLanguage;
export const selectFirstSetupDone = (state: RootState): boolean => state.signin.acceptedTerms && state.signin.currencySelected && (state.signin.emailAddress ? true: false) && state.signin.skippedTwitterButton;
export const selectSkippedTwitterButton = (state: RootState): boolean => state.signin.skippedTwitterButton;
// export const selectLoading = (state: RootState): boolean => state.signin.loading;
export const selectSubscriptionActiveUntil = (state: RootState): number => state.signin.subscriptionActiveUntil;
export const selectSubscriptionActive = (state: RootState): boolean => state.signin.subscriptionActive;
export const selectSubscriptionStatus = (state: RootState): string => state.signin.subscriptionStatus;
export const selectNickname = (state: RootState): string => state.signin.nickname;
export const selectUserProfileLoading = (state: RootState): boolean => state.signin.userProfileLoading;
export const selectIsTrialAvailable = (state: RootState): boolean => state.signin.isTrialAvailable;
export const selectShowStartTrialModal = (state: RootState): boolean => state.signin.showStartTrialModal;
export const selectStartTrialLoading = (state: RootState): boolean => state.signin.startFreeTrialLoading;

export const selectTitle = (state: RootState): string => state.tracker.title;
export const selectDrawerOpening = (state: RootState): boolean => state.tracker.drawerOpening;
// eslint-disable-next-line import/no-unused-modules
export const selectIsDrawerOpened = (state: RootState): boolean => state.tracker.drawerOpened;
export const selectShowCashCards = (state: RootState): boolean => state.tracker.showCashCards;
export const selectRewardsInCurrentSquad = (state: RootState): boolean => state.tracker.rewardsInCurrentSquad;
export const selectRewardTypes = (state: RootState): string[] => state.tracker.rewardTypes;

export const selectGSheetsDescription = (state: RootState): boolean => state.tracker.gsheetsDescription;
export const selectShowTrialMessage = (state: RootState): boolean => state.tracker.showTrialMessage;
export const selectCurrentCurrency = (state: RootState): string => state.tracker.currentCurrency;
// export const selectCurrentCurrencyLoading = (state: RootState): boolean => state.tracker.currentCurrencyLoading;
// export const selectCurrentCurrencyError = (state: RootState): string => state.tracker.currentCurrencyError;
// export const selectCurrentCurrencySuccess = (state: RootState): boolean => state.tracker.currentCurrencySuccess;
export const selectAvailableCurrencies = (state: RootState): string[] => state.tracker.availableCurrencies;

export const selectWithdrawalsDepositsData = (state: RootState): any[] => state.tracker.withdrawalsDepositsData;
export const selectSquadCompositionData = (state: RootState): any[] => state.tracker.squadCompositionData;
export const selectPortfolioValueByRarity = (state: RootState): any[] => state.tracker.portfolioValueByRarityData;
export const selectCardAcquiredData = (state: RootState): any[] => state.tracker.cardsAcquiredData;
export const selectTradingRoiCumulativeData = (state: RootState): any[] => state.tracker.tradingRoiCumulativeData;
export const selectPortfolioRoiData = (state: RootState): any[] => state.tracker.portfolioRoiData;
export const selectSquadCompositionByLeague = (state: RootState): any[] => state.tracker.squadCompositionByLeague;
export const selectAllSalesIndexData = (state: RootState): any[] => state.tracker.allSalesIndexData;

export const selectSelectedStartDate = (state: RootState): Date => new Date(state.tracker.selectedStartDate);
export const selectSelectedStartDateSales = (state: RootState): Date => new Date(state.tracker.selectedStartDateSales);
export const selectSelectedEndDate = (state: RootState): Date => new Date(state.tracker.selectedEndDate);
export const selectSelectedEndDateSales = (state: RootState): Date => new Date(state.tracker.selectedEndDateSales);
export const selectSelectedCustomTimespan = (state: RootState): string => state.tracker.selectedCustomTimespan;
export const selectSelectedCustomTimeframe = (state: RootState): string => state.tracker.selectedCustomTimeframe;
export const selectSelectedCustomSalesTimes = (state: RootState): string => state.tracker.selectedCustomSalesTimes;

export const selectRefreshInProgress = (state: RootState): boolean => state.tracker.refreshInProgress;
export const selectRefreshDataJobId = (state: RootState): number => state.tracker.refreshDataJobId;
// export const selectRefreshError = (state: RootState): string => state.tracker.refreshError;
export const selectRefreshStart = (state: RootState): string => state.tracker.refreshStart;
export const selectRefreshErrorDisplay = (state: RootState): boolean => state.tracker.refreshErrorDisplay;
export const selectRefreshSuccess = (state: RootState): boolean => state.tracker.refreshSuccess;
export const selectRefreshSuccessDisplay = (state: RootState): boolean => state.tracker.refreshSuccessDisplay;

export const selectCurrentChart = (state: RootState): string => state.tracker.currentChart;

export const selectCurrentAnalyzer = (state: RootState) : string => state.tracker.currentAnalyzer;
// export const selectPremiumActive = (state: RootState): boolean => state.tracker.premiumActive;
export const selectProfitsByMonthData = (state: RootState): ProfitsByMonthData[] => state.tracker.profitsByMonthData;
// export const selectProfitsByMonthDataLoading = (state: RootState): boolean => state.tracker.profitsByMonthDataLoading;
// export const selectProfitsByMonthDataError = (state: RootState): string => state.tracker.profitsByMonthDataError;
// export const selectProfitsByMonthDataSuccess = (state: RootState): boolean => state.tracker.profitsByMonthDataSuccess;
export const selectROIData = (state: RootState): ROIData[] => state.tracker.roiData;
export const selectROIDataLoading = (state: RootState): boolean => state.tracker.roiDataLoading;
export const selectChartsLoadInitialData = (state: RootState): boolean => state.tracker.chartsLoadInitialData;

// export const selectROIDataError = (state: RootState): string => state.tracker.roiDataError;
// export const selectROIDataSuccess = (state: RootState): boolean => state.tracker.roiDataSuccess;
export const selectLoansData = (state: RootState): any => state.tracker.loansData;
export const selectTransactionTableData = (state: RootState): TransactionTableRow[] => state.tracker.transactionTableData;
export const selectTransactionLoadInitialData = (state: RootState): boolean => state.tracker.transactionLoadInitialData;

// export const selectTransactionTableDataFiltered = (state: RootState): TransactionTableRow[] => state.tracker.transactionTableDataFiltered;
export const selectTransactionTableDataLoading = (state: RootState): boolean => state.tracker.transactionTableDataLoading;
// export const selectTransactionTableDataError = (state: RootState): string => state.tracker.transactionTableDataError;
// export const selectTransactionTableDataSuccess = (state: RootState): boolean => state.tracker.transactionTableDataSuccess;
export const selectTransactionTableAddLoans = (state: RootState): boolean => state.tracker.transactionTableAddLoans;
// export const selectTransactionTableDataEdited = (state: RootState): boolean => state.tracker.transactionTableDataEdited;
// export const selectTransactionTableDataFilteredEdited = (state: RootState): boolean => state.tracker.transactionTableDataFilteredEdited;
export const selectCurrentSquadTableData = (state: RootState): CurrentSquadTableRow[] => state.tracker.currentSquadTableData;
export const selectCurrentSquadTableDataLoading = (state: RootState): boolean => state.tracker.currentSquadTableDataLoading;
export const selectCurrentSquadLoadInitialData = (state: RootState): boolean => state.tracker.currentSquadLoadInitialData;
export const selectCurrentSquadPCTView = (state: RootState): boolean => state.tracker.currentSquadPercentageView;
// export const selectCurrentSquadTableDataError = (state: RootState): string => state.tracker.currentSquadTableDataError;
// export const selectCurrentSquadTableDataSuccess = (state: RootState): boolean => state.tracker.currentSquadTableDataSuccess;
export const selectSelectedExportYear = (state: RootState): number => state.tracker.selectedExportYear;
export const selectSelectedExportFormat = (state: RootState): string => state.tracker.selectedExportFormat;
export const selectSelectedExportFileFormat = (state: RootState): string => state.tracker.selectedExportFileFormat;

export const selectImpactPlayersTableData = (state: RootState): ImpactPlayersTableRow[] => state.tracker.impactPlayersTableData;
export const selectImpactPlayersDataLoading = (state: RootState): boolean => state.tracker.impactPlayersTableDataLoading;
export const selectImpactPlayersDataError = (state: RootState): string => state.tracker.impactPlayersTableDataError;
export const selectImpactPlayersDataSuccess = (state: RootState): boolean => state.tracker.impactPlayersTableDataSuccess;

export const selectRewardsCumulativeData = (state: RootState): RewardsCumulativeData[] => state.tracker.rewardsCumulativeChartData;

export const selectTradingRoiByCohortChartData = (state: RootState): RewardsMonthlyData[] => state.tracker.tradingRoiByCohortChartData;
export const selectRewardsMonthlyData = (state: RootState): RewardsMonthlyData[] => state.tracker.rewardsMonthlyChartData;
export const selectRewardsDetailsData = (state: RootState): RewardsDetailsData[] => state.tracker.rewardsDetailsChartData;
export const selectCardsSoldData = (state: RootState): CardsSoldData[] => state.tracker.cardsSoldChartData;
export const selectPortfolioData = (state: RootState): PortfolioData[] => state.tracker.portfolioCardData;
export const selectSummaryStatsData = (state: RootState): SummaryStatsData[] => state.tracker.summaryStatsData;
export const selectRewardAnalyzerData = (state: RootState): RewardAnalyzerData[] => state.tracker.rewardAnalyzerCardData;

export const selectWinnigLineupsData = (state: RootState): WinnigLineup[] => state.tracker.winningLineupsData;

// export const selectPTExportSheetExists = (state: RootState): boolean => state.tracker.ptExportSheetExists;
// export const selectPTExportSheetLink = (state: RootState): string => state.tracker.ptExportSheetLink;
// export const selectPTExportError = (state: RootState): string => state.tracker.ptExportError;
// export const selectPTExportSuccess = (state: RootState): boolean => state.tracker.ptExportSuccess;
// export const selectPTExportLoading = (state: RootState): boolean => state.tracker.ptExportLoading;

export const selectTaxExportInProgress = (state: RootState): boolean => state.tracker.taxExportInProgress;
export const selectTaxExportError = (state: RootState): string => state.tracker.taxExportError;
export const selectTaxExportSuccess = (state: RootState): boolean => state.tracker.taxExportSuccess;
export const selectTaxExportDisplayMessage = (state: RootState): boolean => state.tracker.taxExportDisplayMessage;
export const selectTaxExportDate = (state: RootState): number => state.tracker.taxExportDate;
export const selectTaxExportType = (state: RootState): string => state.tracker.taxExportType;
export const selectTaxExportYear = (state: RootState): number => state.tracker.taxExportYear;
export const selectTaxExportLinks = (state: RootState): {pdf: string, xlsx: string, gsheets: string} => state.tracker.taxExportLinks;
export const selectPastTaxExportData = (state: RootState): LastTaxReportResponse[] => state.tracker.pastTaxReports;

// export const selectGWExportSheetExists = (state: RootState): boolean => state.tracker.gwExportSheetExists;
// export const selectGWExportSheetLink = (state: RootState): string => state.tracker.gwExportSheetLink;
// export const selectGWExportError = (state: RootState): string => state.tracker.gwExportError;
// export const selectGWExportSuccess = (state: RootState): boolean => state.tracker.gwExportSuccess;
// export const selectGWExportLoading = (state: RootState): boolean => state.tracker.gwExportLoading;

export const selectRewardPageScrollOffset = (state: RootState): number => state.tracker.rewardPageScrollOffset;
export const selectCardSearchData = (state: RootState): CardSearchResponse[] => state.tracker.cardSearchData;
export const selectPlayerCardSalesData = (state: RootState) : PlayerCardSalesResponse[] => state.tracker.playerCardSalesData;
export const selectPlayerCardValuationsData = (state: RootState) : PlayerCardValuationsResponse[] => state.tracker.playerCardValuationsData;
export const selectCombinedSaleValuation = (state: RootState) : PlayerCombinedSalesValuation[] => state.tracker.playerCombinedSalesValuation;
export const selectCardDetailsSummary = (state: RootState) : CardDetailsSummaryResponse => state.tracker.cardDetailsSummary;
export const selectPersonalCardHistory = (state: RootState) : PersonalCardHistoryResponse[] => state.tracker.personalCardHistory;
export const selectCardRewardSummary = (state: RootState) : CardPerformanceRewardSummary => state.tracker.cardRewardSummary;
export const selectCardRewardsWon = (state: RootState) : CardPerformanceRewardWon[] => state.tracker.cardRewardsWon;

export const selectReportingBaseMonth = (state: RootState) : string => state.tracker.reportingBaseMonth;
export const selectReportingPrevMonth = (state: RootState) : string => state.tracker.reportingPrevMonth;

export const selectTrackerView = (state:RootState): string => state.tracker.trackerPageView;
export const selectReportsSummaryData = (state: RootState) : ReportsSummaryData[] => state.tracker.reportsSummaryData;
export const selectReportsRewardsData = (state: RootState) : ReportsRewardsData[] => state.tracker.reportsRewardsData;
export const selectReportTop4RewardsData = (state: RootState) : ReportsTop4RewardsData[] => state.tracker.reportsTop4RewardsData;
export const selectReportTopFlopsData = (state: RootState) : ReportsTopFlopsData[] => state.tracker.reportsTopFlopsData;
export const selectReportTopPlayersData = (state: RootState) : ReportTopPlayers[] => state.tracker.reportTopPlayersData;

export const selectMDChartRarity = (state: RootState): string => state.tracker.mdChartRarity;
export const selectMarketCompositionData = (state: RootState) : any[] => state.tracker.marketCompositionData;
export const selectMarketCompositionDataLoading = (state: RootState) : boolean => state.tracker.marketCompositionDataLoading;
export const selectMarketIndexData = (state: RootState) : any[] => state.tracker.marketIndexData;
export const selectMarketIndexDataLoading = (state: RootState) : boolean => state.tracker.marketIndexDataLoading;
export const selectMarketDataTypeQuery = (state: RootState) : string => state.tracker.marketIndexDataTypeQuery;
export const selectDropDownMarketDataValue = (state: RootState) : string => state.tracker.dropDownMarketDataValue;
export const selectMarketSwitcherValue = (state: RootState) : string => state.tracker.marketSwitcherValue;

export const selectExportSheetExists = createSelector(
  [
    (state: RootState) => state.tracker.exportSheetExists,
    (state: RootState, sheetName: string) => sheetName,
  ],
  (exportSheetExists, sheetName: string) => {
    return exportSheetExists[sheetName];
  }
);

export const selectExportSheetLink = createSelector(
  [
    (state: RootState) => state.tracker.exportSheetLink,
    (state: RootState, sheetName: string) => sheetName,
  ],
  (exportSheetLink, sheetName: string) => {
    return exportSheetLink[sheetName];
  }
);

// export const selectExportError = createSelector(
//   [
//     (state: RootState) => state.tracker.exportError,
//     (state: RootState, sheetName: string) => sheetName,
//   ],
//   (exportError, sheetName: string) => {
//     return exportError[sheetName];
//   }
// );

// export const selectExportSuccess = createSelector(
//   [
//     (state: RootState) => state.tracker.exportSuccess,
//     (state: RootState, sheetName: string) => sheetName,
//   ],
//   (exportSuccess, sheetName: string) => {
//     return exportSuccess[sheetName];
//   }
// );

export const selectExportLoading = createSelector(
  [
    (state: RootState) => state.tracker.exportLoading,
    (state: RootState, sheetName: string) => sheetName,
  ],
  (exportLoading, sheetName: string) => {
    return exportLoading[sheetName];
  }
);

type ReduxDispatch = ThunkDispatch<RootState, any, Action>;
export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}
