import React from "react";  // eslint-disable-line no-unused-vars
import PopperUnstyled from "@mui/base/PopperUnstyled";
import styled from "@mui/material/styles/styled";
import StyledComboboxButton from "./styled-combobox-button";
import StyledComboboxListbox from "./styled-combobox-listbox";
import SelectUnstyled, { SelectUnstyledProps, SelectUnstyledComponentsPropsOverrides } from "@mui/base/SelectUnstyled";
import { PopperProps } from "@mui/material";

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomPopper = (props: PopperProps) => {
  return <StyledPopper {...props} disablePortal sx={{
    zIndex: 1,
  }}/>;
};

interface CustomSelectProps<TValue> extends SelectUnstyledProps<TValue> {
  buttonProps?: React.ComponentPropsWithRef<"button"> & SelectUnstyledComponentsPropsOverrides;
  listboxProps?: React.ComponentPropsWithRef<"ul"> & SelectUnstyledComponentsPropsOverrides;
  popperProps?: Partial<React.ComponentPropsWithRef<typeof PopperUnstyled>> & SelectUnstyledComponentsPropsOverrides;
}

export default function CustomSelect<TValue>(props: CustomSelectProps<TValue>): JSX.Element {
  const components: SelectUnstyledProps<TValue>["components"] = {
    Root: StyledComboboxButton,
    Listbox: StyledComboboxListbox,
    Popper: CustomPopper,
    ...props.components,
  };

  const propsCopy = {
    ...props,
  };
  delete propsCopy.buttonProps;
  delete propsCopy.listboxProps;
  delete propsCopy.popperProps;

  return <SelectUnstyled {...propsCopy as SelectUnstyledProps<TValue>} components={components} componentsProps={{
    ...props.componentsProps,
    root: props.buttonProps,
    listbox: props.listboxProps,
    popper: props.popperProps,
  }}/>;
}
