import React from "react";  // eslint-disable-line no-unused-vars
import Twitter from "@mui/icons-material/Twitter";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import useTheme from "@mui/system/useTheme";

interface LogoProps {
  opened: boolean;
}

export default function Logo(props: LogoProps): JSX.Element {
  const { opened } = props;
  const theme = useTheme();
  return (<Grid container direction="row" sx={{marginLeft: opened ? "23px" : "15px", marginTop: opened ? "22px" : "20px"}} justifyContent="flex-start" justifyItems="center" alignContent="center" alignItems="center">
    <Grid item xl={opened ? 8 : 8} lg={opened ? 7 : 8} md={opened ? 8 : 8} sm={opened ? 8 : 8} xs={opened ? 8 : 8} sx={{
      padding: 0,
    }}>
      <Link to="/">
        <img src={opened ? "/logo-sorare.svg" : "/logo2.png"} alt="Logo" width={opened ? "180" : "46"}/>
      </Link>
    </Grid>
    <Grid item xl={opened ? 1 : 8} lg={opened ? 3 : 8} md={opened ? 1 : 8} sm={opened ? 1 : 8} xs={opened ? 1 : 8}>
      <IconButton color="primary" href="https://twitter.com/i/user/1384753419626369029" target="_blank" rel="noopener noreferrer" sx={{
        marginLeft: opened ? "30px" : "3px",
        marginTop: opened ? "0px": "12px",
        height: "40px",
        paddingTop: 0,
      }}>
        <Twitter sx={{
          color: theme.palette.text.secondary,
          fontSize: "24px",
          height: "40px",
        }}/>
      </IconButton>
    </Grid>
  </Grid>);
}
