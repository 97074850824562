import React from "react";  // eslint-disable-line no-unused-vars
import Grid from "@mui/material/Grid";
import useTheme from "@mui/material/styles/useTheme";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ReactComponent as LogoWithText } from "../login/icons/logo-with-text.svg";

interface TemplateProps {
  children: React.ReactNode;
  hideLogo?: boolean;
  fullHeight?: boolean;
}

export default function Template(props: TemplateProps): JSX.Element {
  const theme = useTheme();

  return <Grid
    container
    direction="row"
    justifyContent="center"
    justifyItems="center"
    sx={{
      backgroundColor: "#ffffff",
      minHeight: "100vh",
      position: "absolute",
    }}
  >
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Paper
        sx={{
          height: "calc(100vh - 24%)",
          width: "calc(100vw)",
          top: "calc(calc(100vh) - 88%)",
          left: "0",
          position: "absolute",
          flexGrow: "1",
          flexDirection: "column",
          backgroundColor: theme.palette.primary.main,
          borderRadius: "8px",
        }}
        elevation={4}
      >
        {props.fullHeight ? props.children : <Grid container direction="row" flexDirection="row" alignItems="center" alignContent="center" justifyItems="center" justifyContent="center" sx={{
          height: "100%",
        }} rowSpacing={5}>
          {!props.hideLogo ? <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container direction="row" alignItems="center" alignContent="center" justifyItems="center" justifyContent="center">
              <LogoWithText />
            </Grid>
          </Grid> : <></>}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container direction="row" justifyContent="center" alignItems="center" alignContent="center" justifyItems="center" rowSpacing={5} id="template">
              <Grid item xl={2.3} lg={3} md={4} sm={6} xs={11}>
                {props.children}
              </Grid>
            </Grid>
          </Grid>
        </Grid> }
      </Paper>
    </Grid>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{
      position: "absolute",
      bottom: "60px",
    }}>
      <Typography component="a" href="https://soraretools.com" target="_blank" rel="noopener noreferrer" sx={{
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "21px",
        color: "#5173F0",
        textDecoration: "none",
        textAlign: "center",
      }}>
        www.soraretools.com
      </Typography>
    </Grid>
  </Grid>;
}
