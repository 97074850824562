import React from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import ReduxEthFiatSwitcher from "../home/redux-eth-fiat-switcher";
import CustomTimesframePicker from "../home/custom-timeframe-picker";
import RefreshButton from "../home/refresh-button";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { selectIsDrawerOpened } from "../../store/selectors";
import SportSwitcher from "../macro-data/sport-switcher";

function usePreviousValue(value:any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function StickyHeader(): JSX.Element{
  const [y, setY] = useState(document?.scrollingElement?.scrollHeight);
  const [isSticky, setIsSticky] = useState(false);
  const previousValue = usePreviousValue(false);
  const open = useSelector(selectIsDrawerOpened);

  const handleNavigation = useCallback(() => {
    if (window.scrollY > 250) {
      if (!previousValue) {
        setIsSticky(true);
      }
    } else if (window.scrollY < 250) {
      if (!previousValue) {
        setIsSticky(false);
      }
    }
    setY(window.scrollY);
  }, [y]);

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);
    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  return (
    <Box className={`top-navbar ${isSticky ? "sticky" : ""}`}
      sx={{ width: {
        xs: "100%",
        md: open ? "calc(100% - 302px)" : "calc(100% - 78px)"
      }}}>
      <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between", padding: "15px 24px", flexWrap: "wrap"}}>
        <Box><ReduxEthFiatSwitcher /></Box>
        <Box>
          <SportSwitcher/>
        </Box>
        <Box>
          <CustomTimesframePicker/>
        </Box>
        <Box>
          <RefreshButton />
        </Box>
      </Box>
    </Box>
  );
}
