import React from "react";  // eslint-disable-line no-unused-vars
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useReduxDispatch } from "../../store/selectors";
import { setAcceptedTerms, setAllowSendingEmails, setEmailAddress } from "../../store/slices/login.slice";
import { updateAllowSendingEmailsAsync, updateEmailAddress } from "../../store/reducers/tracker.reducer";
import { ReactComponent as EmailIcon } from "./icons/email-icon.svg";
import useTheme from "@mui/material/styles/useTheme";
import Approval from "./approval";
import CustomInput from "../../components/custom-input";
import { updateAgreeToTerms } from "../../store/reducers/login.reducer";
import {useTranslation} from "react-i18next";

export default function Email(): JSX.Element {
  const { t } = useTranslation();
  const [email, setEmail] = React.useState("");
  const theme = useTheme();
  const [acceptedSendEmailsCheckbox, setAcceptedSendEmailsCheckbox] = React.useState(false);
  const [acceptedTermsCheckbox, setAcceptedTermsCheckbox] = React.useState(false);
  const [openErrorMessage, setOpenErrorMessage] = React.useState(false);
  const [openTermsErrorMessage, setOpenTermsErrorMessage] = React.useState(false);
  const dispatch = useReduxDispatch();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleCloseErrorMessage = () => { setOpenErrorMessage(false); };
  const handleCloseTermsErrorMessage = () => { setOpenTermsErrorMessage(false); };
  const handleEnterEmail = () => {
    const emailValidation = /.*@.*/.test(email);
    if (email && emailValidation) {
      dispatch(setEmailAddress(email));
      dispatch(setAllowSendingEmails(acceptedSendEmailsCheckbox));
      dispatch(updateEmailAddress(email));
      if (acceptedSendEmailsCheckbox) {
        dispatch(updateAllowSendingEmailsAsync());
      }
    } else {
      setOpenErrorMessage(true);
    }
    if (acceptedTermsCheckbox) {
      dispatch(setAcceptedTerms(true));
      dispatch(updateAgreeToTerms());
    } else {
      setOpenTermsErrorMessage(true);
    }
  };

  const action = (onClose: () => void) => {
    const actionRet = () => (<React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>);
    return actionRet;
  };

  return (<Grid container direction="row" justifyContent="center" justifyItems="center" alignItems="center" alignContent="center" rowSpacing="20px" sx={{
    marginTop: "0px",
    paddingBottom: "40px",
  }}>
    <Grid item xl={1} lg={1.3335} md={1} sm={1} xs={1} style={{
      height: "60px",
    }}>
      <EmailIcon width="40px" height="40px" />
    </Grid>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{
      paddingTop: "5px",
    }}>
      <Typography variant="body1" sx={{
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "21px",
        textAlign: "center",
        color: theme.palette.info.main,
      }}>{t("email_header")}</Typography>
    </Grid>
    <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
      <CustomInput id="email-label" value={email} onChange={handleEmailChange} placeholder="email@email.com"/>
    </Grid>
    <Grid item xl={9} lg={9} md={9} sm={8} xs={8} style={{
      paddingTop: "12px",
    }}>
      <Approval accepted={acceptedSendEmailsCheckbox} onSetAccepted={setAcceptedSendEmailsCheckbox} text={t("email_allow_text")}/>
    </Grid>
    <Grid item xl={9} lg={9} md={9} sm={8} xs={8} style={{
      paddingTop: "8px",
    }}>
      <Approval accepted={acceptedTermsCheckbox} onSetAccepted={setAcceptedTermsCheckbox} text={<span>{t("email_accept_text")} <a href='https://www.soraretools.com/terms-conditions' target="_blank" rel="noopener noreferrer">{t("terms_of_service")}</a> {t("and")} <a href='https://www.soraretools.com/privacy-policy' target="_blank" rel="noopener noreferrer">{t("privacy_policy")}</a> </span>}/>
    </Grid>
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{
      paddingTop: "0px",
    }}/>
    <Grid item xl={10} lg={10} md={10} sm={10} xs={10} sx={{textAlign: "center"}}>
      <Button variant="contained" color="primary" onClick={handleEnterEmail} disabled={!acceptedTermsCheckbox} sx={{
        borderRadius: "8px",
        padding: "5px 30px",
        "&.Mui-disabled": {
          backgroundColor: "#dce3fc69",
          color: "#C4C4C4"
        }
      }}>{t("submit")}</Button>
    </Grid>
    <Snackbar
      open={openErrorMessage}
      autoHideDuration={6000}
      onClose={handleCloseErrorMessage}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      message={t("enter_valid_email")}
      action={action(handleCloseErrorMessage)}
    />
    <Snackbar
      open={openTermsErrorMessage}
      autoHideDuration={6000}
      onClose={handleCloseTermsErrorMessage}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      message={t("accept_the_terms_and_conditions")}
      action={action(handleCloseTermsErrorMessage)}
    />
  </Grid>);
}
