import {lazyWithRetry} from "../helpers/lazyWithRetry";
import  {lazy} from "react";

export const Tracker = lazyWithRetry(() => import(/* webpackChunkName: 'Tracker' */ "../pages/tracker"));
export const HomePage = lazy(() => import(/* webpackChunkName: 'HomePage' */"../pages/home"));
export const CurrentSquadPage = lazy(() => import( /* webpackChunkName: 'CurrentSquadPage' */ "../pages/current-squad"));
export const TransactionsPage = lazy(() => import( /* webpackChunkName: 'TransactionsPage' */ "../pages/transactions"));
export const RewardAnalyzerPage = lazy(() => import( /* webpackChunkName: 'RewardAnalyzerPage' */ "../pages/reward-analyzer"));
export const WinnigLineupPage = lazy(() => import( /* webpackChunkName: 'WinnigLineupPage' */ "../pages/winning-lineups"));
export const DataExportPage = lazy(() => import( /* webpackChunkName: 'DataExportPage' */ "../pages/data-export"));
export const TaxReportsPage = lazy(() => import( /* webpackChunkName: 'TaxReportsPage' */ "../pages/tax-reports"));
export const GoogleSheetsPage = lazy(() => import( /* webpackChunkName: 'GoogleSheetsPage' */ "../pages/google-sheets"));
export const SettingsPage = lazy(() => import( /* webpackChunkName: 'SettingsPage' */ "../pages/settings"));
export const SearchPage = lazy(() => import( /* webpackChunkName: 'SearchPage' */ "../pages/search"));
export const CardDetailPage = lazy(() => import( /* webpackChunkName: 'CardDetailPage' */ "../pages/card-detail"));
export const LoginPage = lazy(() => import( /* webpackChunkName: 'LoginPage' */ "../pages/login"));
export const EmailPage = lazy(() => import( /* webpackChunkName: 'EmailPage' */ "../pages/email"));
export const CurrencyPage = lazy(() => import( /* webpackChunkName: 'CurrencyPage' */ "../pages/currency"));
export const Start = lazy(() => import( /* webpackChunkName: 'Start' */ "../pages/start"));
export const TwitterPage = lazy(() => import( /* webpackChunkName: 'TwitterPage' */ "../pages/twitter"));
export const AdminPage = lazy(() => import( /* webpackChunkName: 'AdminPage' */  "../pages/login/admin"));
export const MarketData = lazy(() => import( /* webpackChunkName: 'AdminPage' */  "../pages/macro-data"));

