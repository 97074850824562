import styled from "@mui/material/styles/styled";
import selectUnstyledClasses from "@mui/base/SelectUnstyled/selectUnstyledClasses";
import grey from "@mui/material/colors/grey";

export default styled("button")(
  ({ theme }) => `
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  box-sizing: border-box;
  min-height: 30px;
  min-width: 100px;
  max-width: 105px;
  background: ${theme.palette.action.selected};
  border: none;
  border-radius: 8px;
  margin: 0px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  z-index: 3;
  color: ${theme.palette.text.secondary};

  &:hover {
    background: ${theme.palette.action.selected};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &.${selectUnstyledClasses.root} {
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }

  &::after {
    content: '▾';
    float: right;
  }
  `,
);