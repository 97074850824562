/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useCallback} from "react";  // eslint-disable-line no-unused-vars
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import DrawerContent from "./drawer-content";
import useWindowDimensions from "./useWindowDimensions";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as DrawerOpener } from "./icons/drawer-opener-icon.svg";
import { ReactComponent as DrawerCloser } from "./icons/drawer-closer-icon.svg";

const drawerWidth = 280;

function MobileDrawer(): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const [btnPosition, setBtnPosition] = React.useState("271px");
  const theme = useTheme();
  const dimensions = useWindowDimensions();
  const height = dimensions.height;

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  },[open]);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  },[open]);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  React.useEffect(() => {
    if(!open){
      setBtnPosition("70px");
    }else{
      setBtnPosition("271px");
    }
  },[open]);

  return (<>
    <AppBar position="fixed">
      <Toolbar variant="regular">
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpen(!open)}>
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
    <SwipeableDrawer
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      open={open}
      anchor={open ? "left" : undefined}
      disableBackdropTransition
      disableDiscovery
      disableSwipeToOpen
      sx={{
        height: height + "px",
        width: open ? drawerWidth : "0px",
        backgroundColor: "transparent",
        boxShadow: "none",
        "& .MuiPaper-root": {
          height: height + "px",
          width: open ? drawerWidth : "0px",
          backgroundColor: "transparent",
          borderRadius: "0 12px 12px 0",
        },
      }}
    >
      <Grid container direction="row" alignItems="stretch" alignContent="stretch" justifyItems="stretch" justifyContent="stretch" columnSpacing={0} sx={{
        height: height + "px",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "0 12px 12px 0",
        width: drawerWidth,
      }}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <DrawerContent open={open}/>
        </Grid>
        <Grid item xl={open ? 1 : 12} lg={open ? 1 : 12} md={open ? 1 : 12} sm={open ? 1 : 12} xs={open ? 1 : 12}>
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen} sx={{
            position: "fixed",
            top: "50%",
            left: btnPosition,
          }}>
            {open ? <DrawerCloser /> : <DrawerOpener />}
          </IconButton>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  </>);
}

export default React.memo(MobileDrawer);
