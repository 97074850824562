import React from "react";  // eslint-disable-line no-unused-vars
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import useTheme from "@mui/material/styles/useTheme";

interface ApprovalProps {
  accepted: boolean;
  onSetAccepted: (acceptedSendEmailsCheckbox: boolean) => void;
  text: any;
}

export default function Approval(props: ApprovalProps): JSX.Element {
  const theme = useTheme();
  const { accepted, onSetAccepted, text } = props;
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onSetAccepted(checked);
  };

  return <>
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={accepted}
            onChange={handleCheckboxChange}
            sx={{
              color: theme.palette.primary.main,
              ".MuiSvgIcon-root": {
                width: "16px",
                height: "16px",
              },
              height: "auto",
              padding: "0",
              margin: "0 10px"
            }}
          />
        }
        label={text}
        sx={{
          color: "#B5C1D2",
          fontSize: "10px",
          alignItems: "start",
          ".MuiFormControlLabel-label": {
            fontSize: "10px",
          },
        }}
      />
    </FormGroup>
  </>;
}
