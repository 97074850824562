import React from "react";  // eslint-disable-line no-unused-vars
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import useTheme from "@mui/material/styles/useTheme";

interface EthFiatSwitcherProps {
  value: string;
  onChange: (
    event: React.MouseEvent<HTMLElement>,
    newEthFiat: string | null,
  ) => void;
  currencySymbol: string;
}

export default function EthFiatSwitcher(props: EthFiatSwitcherProps): JSX.Element {
  const theme = useTheme();
  return <ToggleButtonGroup value={props.value} exclusive onChange={props.onChange} color="secondary" sx={{
    alignItems: "center",
    ".MuiToggleButtonGroup-grouped": {
      border: "1px solid",
      borderRadius: "8px",
      height: 26.8,
      width: 50,
      backgroundColor: "#C4C4C4",
      color: theme.palette.text.secondary,
      "&:hover": {
        backgroundColor: "#C4C4C4",
      },
      "&.Mui-selected": {
        borderLeft: "none",
        zIndex:10,
        height:30,
        width: 60,
        borderRadius: "8px",
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.action.selected,
        "&:hover": {
          backgroundColor: theme.palette.action.selected,
          color: theme.palette.text.secondary,
        },
      },
    },
  }}>
    <ToggleButton value="ETH">
      <svg width="14" height="14" viewBox="1 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path x="0" y="10" fillRule="evenodd" clipRule="evenodd" d="M8.73815 7.23145L8.73738 7.2319L8.74262 7.23429L4.63851 9.61657L4.63851 9.61693L4.6382 9.61675L4.63675 9.61759V9.61784L4.63654 9.61771L4.63434 9.61899L4.63434 9.61736L4.63352 9.61784V9.61596L3.54661 8.98404L0.527832 7.23429L0.532975 7.23195L0.532125 7.23145L4.63352 0.300384V0.294922L4.63514 0.297653L4.63675 0.294922V0.300384L8.73815 7.23145ZM4.63773 10.4561L0.545542 8.06462L4.63706 13.8184V13.8203L4.63773 13.8193L4.63841 13.8203V13.8184L8.72992 8.06462L4.63773 10.4561Z" fill="#fff" />
      </svg>
    </ToggleButton>
    <ToggleButton sx={{left: -5}} value="FIAT">{props.currencySymbol}</ToggleButton>
  </ToggleButtonGroup>;
}