import React from "react";  // eslint-disable-line no-unused-vars
import Grid from "@mui/material/Grid";
import ContainedButton from "../../components/ContainedButton";
import {useTranslation} from "react-i18next";

export default function LoginButton(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="row"
      justifyItems="center"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Grid item xl={0.95} lg={1.34} md={1.87} sm={2.5} xs={4.55}>
        <ContainedButton
          size="large"
          href="https://sorare.com/oauth/authorize?client_id=98P7_B_YU6ueYZiLa8-cgu4PygwS_TM-fvpBjm8Gl_4&redirect_uri=https://app.soraretools.com/login-callback&response_type=code"
          fullWidth
          sx={{
            backgroundColor: "#F9FAFC",
            color: "#5173F0",
            fontSize: "12px",
            lineHeight: "21px",
            fontWeight: 400,
            width: "160px",
            height: "35px",
            textTransform: "none",
            padding: "12px",
            "&:hover": {
              backgroundColor: "#F9FAFC",
              opacity: 0.8,
              color: "#5173F0",
            },
          }}
        >
          {t("login_through_sorare")}
        </ContainedButton>
      </Grid>
    </Grid>
  );
}
