import React from "react";  // eslint-disable-line no-unused-vars
import Button, { ButtonProps } from "@mui/material/Button";
import useTheme from "@mui/material/styles/useTheme";

export default function ContainedButton(props: ButtonProps): JSX.Element {
  const theme = useTheme();
  return <Button variant="contained" sx={{
    fontSize: "16px",
    background: theme.palette.primary.light,
    "&:hover": {
      background: theme.palette.primary.light,
    },
  }} {...props}/>;
}