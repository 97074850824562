import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { selectRefreshErrorDisplay, selectRefreshStart, selectRefreshDataJobId, selectRefreshInProgress, selectRefreshSuccessDisplay } from "../../store/selectors";
import { setRefreshErrorDisplay, setRefreshInProgress, setRefreshSuccessDisplay} from "../../store/slices/tracker.slice";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

interface RefreshComponentProps {
  setStartRefreshJob: (start: string) => void;
  startRefreshJob: string;
}

export default function RefreshComponent(props: RefreshComponentProps): JSX.Element {
  const {startRefreshJob } = props;
  const dispatch = useDispatch();
  const refreshErrorDisplay = useSelector(selectRefreshErrorDisplay);
  const refreshStart = useSelector(selectRefreshStart);
  const refreshSuccessDisplay = useSelector(selectRefreshSuccessDisplay);
  // const refreshJobId = useSelector(selectRefreshDataJobId);
  // const refreshInProgress = useSelector(selectRefreshInProgress);

  useEffect(() => {
    window.onbeforeunload = () => {
      console.log("---> Unloaded");
      dispatch(setRefreshInProgress(false));
    };

  }, [dispatch, startRefreshJob, refreshStart]);

  const handleCloseErrorMessage = () => {
    dispatch(setRefreshErrorDisplay(false));
  };

  const handleCloseSuccessMessage = () => {
    dispatch(setRefreshSuccessDisplay(false));
  };

  const actionError = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseErrorMessage}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const actionSuccess = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleCloseErrorMessage}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return <>
    <Snackbar
      open={refreshErrorDisplay}
      autoHideDuration={6000}
      onClose={handleCloseErrorMessage}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      message="The refresh job failed. Please try again later."
      action={actionError}
    />

    <Snackbar
      open={refreshSuccessDisplay}
      autoHideDuration={5000}
      onClose={handleCloseSuccessMessage}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      message="Data Refreshed Successfully"
      action={actionSuccess}
    >
      <Alert onClose={handleCloseSuccessMessage} severity="success" 
        sx={{ width: "100%", 
          backgroundColor: "#71be98", 
          color: "#fff",
          "& .MuiAlert-icon": {
            color: "#fff"
          }
        }}>
        Data Refreshed Successfully
      </Alert>
    </Snackbar>
  </>;
}
