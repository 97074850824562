import React from "react";  // eslint-disable-line no-unused-vars
import { useSelector } from "react-redux";
import { selectAcceptedTerms, selectCurrencySelected, selectEmailAddress, selectSkippedTwitterButton } from "../../store/selectors";
import Currency from "../currency";
import Email from "../email";
import Twitter from "../twitter";

export default function Setup(): JSX.Element {
  const currencySelected = useSelector(selectCurrencySelected);
  const acceptedTerms = useSelector(selectAcceptedTerms);
  const emailAddress = useSelector(selectEmailAddress);
  const skippedTwitterButton = useSelector(selectSkippedTwitterButton);
  // console.log("Setup:", currencySelected, acceptedTerms, emailAddress, skippedTwitterButton);
  if (!currencySelected) {
    return <Currency />;
  }
  if (!emailAddress || !acceptedTerms) {
    return <Email />;
  }
  if (!skippedTwitterButton) {
    return <Twitter />;
  }
  return <></>;
}
