import React,{useMemo} from "react";  // eslint-disable-line no-unused-vars
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import theme from "../../assets/css/theme";
import { ListItem, ListItemButton, ListItemIcon } from "@mui/material";
import {ReactComponent as PTIcon} from "./icons/pt-icon.svg";
import {ReactComponent as CSIcon} from "./icons/cs-icon.svg";
import {ReactComponent as TAIcon} from "./icons/ta-icon.svg";
import {ReactComponent as TEIcon} from "./icons/te-icon.svg";
import { ReactComponent as REIcon } from "./icons/re-icon.svg";
import { ReactComponent as SheetsIcon } from "./icons/sheets-icon.svg";
import { ReactComponent as TaxIcon } from "./icons/tax-icon.svg";
import { ReactComponent as CDIcon } from "./icons/cd-icon.svg";
import { ReactComponent as MDIcon } from "./icons/md-icon.svg";
import { ReactComponent as ScoutIcon } from "./icons/scout-icon.svg";
import { useSelector } from "react-redux";
import { selectSubscriptionActive } from "../../store/selectors";
import {LightTooltip} from "./styled-tooltip";
import {useTranslation} from "react-i18next";


interface LinksProps {
  opened: boolean;
}

function Links(props: LinksProps): JSX.Element {
  const { opened } = props;
  const location = useLocation();
  const { t } = useTranslation();
  const subscriptionStatus = useSelector(selectSubscriptionActive);
  const links = useMemo(() => [
    {"text": t("portfolio_tracker_page_title"), "link": "/tracker/home", "icon": <PTIcon fill="inherit"/>, "enabled": subscriptionStatus},
    {"text": t("current_squad_page_title"), "link": "/tracker/current-squad", "icon": <CSIcon fill="inherit"/>, "enabled": subscriptionStatus},
    {"text": t("transactions_page_title"), "link": "/tracker/transactions", "icon": <TAIcon fill="inherit"/>, "enabled": subscriptionStatus},
    { "text": t("reward_analyzer_page_title"), "link": "/tracker/reward-analyzer", "icon": <REIcon fill="inherit" />, "enabled": subscriptionStatus },
    { "text": t("card_details_page_title"), "link": "/tracker/search", "icon": <CDIcon fill="inherit" />, "enabled": subscriptionStatus },
    // { "text": "Scout", "link": "/tracker/scout", "icon": <ScoutIcon fill="inherit" />, "enabled": subscriptionStatus },
    // {"text": "Data Export", "link": "/tracker/data-export", "icon": <TEIcon fill="inherit"/>, "enabled": subscriptionStatus},
    { "text": t("tax_reports_page_title"), "link": "/tracker/tax-reports", "icon": <TaxIcon fill="inherit" />, "enabled": subscriptionStatus },
    { "text": "Market Data", "link": "/tracker/market-data", "icon": <MDIcon fill="inherit" />, "enabled": subscriptionStatus },
    {"text": t("g_sheets_page_title"), "link": "/tracker/google-sheets", "icon": <SheetsIcon fill="inherit" />, "enabled": subscriptionStatus },
  ], [subscriptionStatus]);

  return <>{links.map((text): JSX.Element => (
    <ListItem key={text.text} sx={{
      textDecoration: "none",
      color: theme.palette.text.secondary,
      padding: 0,
      height: "51px",
      margin: 0,
      justifyItems: opened ? "center" : "flex-start",
      justifyContent: opened ? "center" : "flex-start",
      textAlign: "center",
    }}>
      <ListItemButton
        // disabled={!text.enabled}
        to={text.link}
        component={Link}
        selected={location.pathname === text.link}
        sx={{
          margin: 0,
          padding: 0,
          height: "51px",
          "& .MuiListItemIcon-root": {
            fill: "rgba(126, 134, 158, 0.25)",
            fillOpacity: 1,
            color: "#fff",
          },
          "&.Mui-selected": {
            backgroundColor: "#5173F0",
            "& .MuiListItemIcon-root": {
              color: theme.palette.primary.main,
              fill: "#fff",
              fillOpacity: 0.7,
            },
            "& .MuiListItemText-primary": {
              color: theme.palette.text.secondary,
              textShadow: "0px 1px 4px rgba(252, 125, 105, 0.45)",
              fontWeight: 600,
            },
          },
          "&.MuiListItemButton-root:hover": {
            background: "#323E8B",
            "& .MuiListItemIcon-root": {
              color: theme.palette.text.secondary,
              fill: "rgba(249,250,252, 0.3)",
              fillOpacity: 1,
            },
            "& .MuiListItemText-primary": {
              color: theme.palette.text.secondary,
              textShadow: "0px 1px 4px rgba(252, 125, 105, 0.45)",
              fontWeight: 600,
            },
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#5173F0",
            "& .MuiListItemIcon-root": {
              color: theme.palette.primary.main,
              fill: "#fff",
              fillOpacity: 0.7,
            },
            "& .MuiListItemText-primary": {
              color: theme.palette.text.secondary,
              textShadow: "0px 1px 4px rgba(252, 125, 105, 0.45)",
              fontWeight: 600,
            },
          },
        }}
      >
        <LightTooltip title={text.text} placement="right" arrow >
          <ListItemIcon sx={{
            minWidth: "44px",
            marginLeft: "24px",
          }}>
            {text.icon}
          </ListItemIcon>
        </LightTooltip>
        {opened && <ListItemText primary={text.text} sx={{
          fontSize: "16px",
          fontWeight: 300,
          lineHeight: "24px",
        }}/>}
      </ListItemButton>
    </ListItem>
  ))}
  </>;
}

export default React.memo(Links);
