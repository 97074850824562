/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore, ThunkAction, Action, combineReducers, CombinedState } from "@reduxjs/toolkit";
import SignInSlice, { LoginState } from "./slices/login.slice";
import TrackerSlice, { TrackerState } from "./slices/tracker.slice";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    // console.log("1-->", JSON.parse(serializedState).tracker.refreshInProgress);
    // console.log("2-->", JSON.parse(serializedState).tracker.refreshStart);
    // console.log("3-->", JSON.parse(serializedState).tracker.refreshDataJobId);
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

interface ReducerType {
  signin: LoginState;
  tracker: TrackerState;
}

const peristedState = loadState();

const combinedReducer = combineReducers({
  signin: SignInSlice,
  tracker: TrackerSlice,
});

const rootReducer = (state: any, action: any): CombinedState<ReducerType> => {
  if (action.type === "signin/logout") {
    localStorage.removeItem("state");
    window.location.reload();
    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  preloadedState: peristedState,
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
});

const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    // Ignore write errors;
  }
};

store.subscribe(() => {
  saveState(store.getState());
});

export type RootState = ReturnType<typeof store.getState>;
// eslint-disable-next-line import/no-unused-modules
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export default store;
