import React from "react";  // eslint-disable-line no-unused-vars
import { Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import HelpIcon from "@mui/icons-material/Help";
import {useTranslation} from "react-i18next";

export function AccountAccessHint(): JSX.Element {
  const { t } = useTranslation();

  return <Grid
    container
    direction="row"
    justifyContent="center"
    justifyItems="center"
    alignItems="flex-end"
    alignContent="flex-end"
    sx={{
      marginBottom: "40px",
    }}
  >
    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Tooltip title={t("login_tooltip_text")} enterTouchDelay={0}>
        <Grid container direction="row" justifyContent="center" alignItems="center" alignContent="center" justifyItems="center" rowSpacing={0}>
          <Grid item xl={0.1} lg={0.2} md={0.3} sm={0.4} xs={0.7}>
            <HelpIcon style={{
              color: "#ffffff",
              cursor: "pointer",
            }}/>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div style={{
              color: "#ffffff",
              fontSize: "10px",
              lineHeight: "15px",
              fontWeight: 300,
              cursor: "pointer",
              textAlign: "center",
            }}>
              {t("login_hint_question")}
            </div>
          </Grid>
        </Grid>
      </Tooltip>
    </Grid>
  </Grid>;
}
